import React, { Fragment, useContext, useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import numeral from 'numeral';
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { convertToTime, forbiddenCharacters, formatDateTime } from './util';
import { useAPI } from './hooks/api';
import { useCopy } from './hooks';
import { useLoader } from './hooks/loader';
import { useSaveExit } from './hooks/saveExit';
import CampaignFooter from './CampaignFooter';
import Title from './Title';
import { useSnackbar } from 'notistack';
import AdvertiserContext from './AdvertiserContext';
import freqCapIcon from '../images/icon-config-freqcaps.png';
import { Themes } from '../constants';

const useStyles = makeStyles(theme => ({
  bid: {
    ...theme.typography.body2,
    fontSize: '0.725rem',
    lineHeight: '1.025rem',
    margin: 0,
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  tooltip: {
    backgroundColor: `#f2f3f5`,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    maxWidth: 180,
  },
  cap: {
    color: '#181c20',
    fontSize: '.925rem',
    fontWeight: 400,
    lineHeight: '1.4rem',
  },
  freqCap: {
    color: '#5c6b73',
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1),
  },
  freqCapsBtn: {
    width: '90%',
    textAlign: 'left',
  },
  freqCapWrap: {
    border: '1px solid rgb(224, 224, 224)',
    padding: theme.spacing(3),
    borderRadius: 5,
    textAlign: 'left',
    width: '100%',
  },
  inputWrap: {
    margin: '0 auto',
    minHeight: 240,
  },
  tooltipWidth: {
    maxWidth: 180,
  },
}));
const useDebouncedValue = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedValue(value);
    }, delay);

    handler();

    return () => {
      handler.cancel();
    };
  }, [value, delay]);

  return debouncedValue;
};

const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const Copies = {
  [Themes.DEFAULT]: {
    INTRO: null,
    DELIVERY_TIMEZONE_LABEL: 'Delivery Timezone',
    CONFIG_FREQUENCY_CAP_TOOLTIP: null,
    FREQUENCY_CAP_LABEL: 'Configure frequency cap',
  },
  [Themes.NBCU]: {
    INTRO: 'Enter a name for this ad group. An ad group is a subset of a campaign. You should create a different ad group for each audience you plan to target.',
    DELIVERY_TIMEZONE_LABEL: 'Delivery Time Zone',
    CONFIG_FREQUENCY_CAP_TOOLTIP: 'If selecting a frequency cap for this ad group, it must be less than or equal to the one selected for the overall campaign.',
    FREQUENCY_CAP_LABEL: 'Configure Frequency Cap',
  },
};

///////////////////////////////
// NAME AD GROUP COMPONENT
///////////////////////////////
const NameAdGroup = props => {
  const classes = useStyles();
  const history = useHistory();
  const adContext = useContext(AdvertiserContext);
  const { isLoading, setIsLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const { usePatch } = useAPI();
  const { saveProgress } = useSaveExit();
  const Copy = useCopy(Copies);

  const {
    currentAdGroup,
    combinedTargetingData,
    selectedStartDate,
    selectedStartTime,
    selectedEndDate,
    selectedEndTime,
    cpmRange,
    showMaxCpm,
    freqCaps,
    dayParts,
    deliveryTime,
    currentDayPartsCopy = 'Configure day-parting (optional)',
    setIsFreqCaps,
    setIsDayParting,
    save,
    triggerSave,
    setDeliveryTime,
    setStep,
    rangeText,
  } = props;

  const name = () =>
    currentAdGroup != null && currentAdGroup.name != null
      ? currentAdGroup.name
      : '';
  const cpmBid = () =>
    currentAdGroup != null && currentAdGroup.cpm != null
      ? currentAdGroup.cpm
      : '';

  const [adGroup, setAdGroup] = useState(name());
  const [bid, setBid] = useState(cpmBid());
  const [adjustedCPM, setAdjustedCPM] = useState(0)
  const debouncedMaxBid = useDebouncedValue(bid, 500);

  useEffect(() => {
    if (adContext.theme === Themes.DEFAULT) {
      getMaxRecBid(debouncedMaxBid)
    }
  }, [debouncedMaxBid])

  useEffect(() => {
    props.setInventoryOverlay(false);
  }, []);

  useEffect(() => {
    if (currentAdGroup != null && currentAdGroup.name != null) {
      setAdGroup(currentAdGroup.name);
    }

    if (currentAdGroup != null && currentAdGroup.cpm != null) {
      setBid(currentAdGroup.cpm);
    }
  }, [currentAdGroup]);

  useEffect(() => {
    saveProgress(save, 'NameAdGroup', handleSave, triggerSave);
  }, [save]);

  useEffect(() => {
    if (
      currentAdGroup &&
      currentAdGroup.creatives &&
      currentAdGroup.creatives.length > 0
    ) {
      props.updateBreadcrumbs('targeting', 75, 'creative', 50);
    } else {
      props.updateBreadcrumbs('targeting', 75, 'creative', -1);
    }
  }, []);

  const handleBack = () => {
    // Reverse breadcrumbs status
    props.setStep('Inventory');
    props.updateBreadcrumbs('targeting', 50);
  };

  const getMaxRecBid = async (bid) => {
    if (!bid) {
      return
    }
    try {
      const response = await usePatch(`/lineitems/${props.currentAdGroup.id}/`, { cpm: bid })
      const { adjusted_cpm } = response.data
      setAdjustedCPM(adjusted_cpm)
    } catch (error) {
      console.error(error)
      enqueueSnackbar("Error getting the Max CPM Bid", {
        variant: 'error',
      });
    }
  }

  const handleSave = async () => {
    setIsLoading(true);

    const start = formatDateTime(selectedStartDate, selectedStartTime);
    const end = props.noEndDate
      ? null
      : formatDateTime(selectedEndDate, selectedEndTime);

    const dataObj = {
      cpm: bid,
      name: adGroup,
      start_date: start,
      end_date: end,
      freq_caps: freqCaps,
      dayparting: dayParts,
      targeting: JSON.stringify({
        ...combinedTargetingData,
        timezone: deliveryTime,
      })
    };

    try {
      const response = await usePatch(
        `/lineitems/${props.currentAdGroup.id}/`,
        dataObj
      );

      setIsLoading(false);

      if (response) {
        props.handleCurrentAdGroup({
          ...response.data,
          ...dataObj,
          targeting: JSON.parse(dataObj.targeting),
        });

        // Handle Save & Exit button click
        if (save && save.step && save.step === 'NameAdGroup') {
          if (save.exit) {
            history.push('/home');
          }
        }

        // Handle next button click
        if (!save.exit) {
          // props.setIsAdGroup(false);
          props.updateBreadcrumbs('targeting', 100, 'creative', 0);
          setStep('CreativeUpload');
        }
      }

      return response;
    } catch (error) {
      console.error('Error saving NameAdGroup', error);
      setIsLoading(false);
      return error;
    }
  };

  const handleBid = event => {
    const { value } = event.target;
    setBid(value);
    getMaxRecBid()
  };

  // Render
  return (
    <Fragment>
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Box>
          <Grid container item>
            <Grid item xs={12}>
              <Title>Name Ad Group</Title>

              {Copy.INTRO && <p>{Copy.INTRO}</p>}

              <Divider className={classes.divider} />
            </Grid>
          </Grid>

          <Grid
            container
            className={classes.inputWrap}
            justify="space-between"
            item
            spacing={5}
          >
            <Grid container item spacing={2} xs={6}>
              <Grid item xs={12}>
                <TextField
                  color="secondary"
                  fullWidth
                  id="target-profile-name"
                  label="Name"
                  placeholder="Enter a name for your ad group"
                  variant="outlined"
                  value={adGroup}
                  onChange={event => setAdGroup(event.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Box mt={1}>
                  <TextField
                    select
                    color="secondary"
                    fullWidth
                    label={Copy.DELIVERY_TIMEZONE_LABEL}
                    variant="outlined"
                    value={deliveryTime}
                    onChange={evt => setDeliveryTime(evt.target.value)}
                  >
                    <MenuItem value="est">
                      Eastern Time (GMT-05:00)
                    </MenuItem>

                    <MenuItem value="user">
                      Viewers&apos; Local Time
                    </MenuItem>
                  </TextField>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        disableToolbar
                        disabled
                        format="MM/dd/yyyy"
                        margin="normal"
                        inputVariant="outlined"
                        id="date-picker-inline"
                        label="Start Date"
                        value={selectedStartDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        emptyLabel="No End Date"
                        disabled
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        inputVariant="outlined"
                        id="date-picker-inline-end"
                        label="End Date"
                        value={props.noEndDate ? null : selectedEndDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid container item xs={12}>
                <Grid container item spacing={3}>
                  <Grid item xs={6}>
                    <FormControl
                      color="secondary"
                      fullWidth
                      variant="outlined"
                    >
                      <InputLabel htmlFor="max-bid">Max CPM Bid</InputLabel>

                      <OutlinedInput
                        id="max-bid"
                        value={bid}
                        onKeyDown={event => {
                          return (
                            _.includes(forbiddenCharacters, event.key) &&
                            event.preventDefault()
                          );
                        }}
                        onChange={event => handleBid(event)}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        labelWidth={100}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container justify="flex-start">
                      {cpmRange &&
                        cpmRange.length === 2 &&
                        cpmRange[0] > 0 &&
                        cpmRange[1] > 0 && (
                          <>
                            <Grid item xs={2}>
                              <Tooltip
                                title="Max bids can be up to 10% higher due to auction mechanics."
                                arrow
                                classes={{ tooltip: classes.tooltipWidth }}
                              >
                                <InfoOutlinedIcon fontSize="small" />
                              </Tooltip>
                            </Grid>

                            <Grid item xs={10}>
                              <p className={classes.bid}>
                                The bid range for your inventory selection is{' '}
                                <br />
                                {rangeText}
                              </p>

                              {showMaxCpm && (
                                <p className={classes.bid}>
                                  Max CPM bid sent to BW <br />
                                  {numeral(adjustedCPM).format('$0,0')}
                                </p>)}
                            </Grid>
                          </>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Box mb={2}>
                <Typography variant="h5">
                  Advanced Optimization Options
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                mb={2}
              >
                <Grid container item xs={12} alignItems="center">
                  <Button
                    className="freq-cap-btn"
                    onClick={() => setIsFreqCaps(true)}
                    startIcon={<img src={freqCapIcon} width={17} />}
                  >
                    <Typography className={classes.freqCap}>
                      {Copy.FREQUENCY_CAP_LABEL}
                    </Typography>
                  </Button>

                  {Copy.CONFIG_FREQUENCY_CAP_TOOLTIP && (
                    <Tooltip
                      classes={{ tooltip: classes.tooltip }}
                      title={Copy.CONFIG_FREQUENCY_CAP_TOOLTIP}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  )}
                </Grid>

                <ButtonBase
                  disableRipple
                  onClick={() => setIsFreqCaps(true)}
                  className={classes.freqCapsBtn}
                >
                  {freqCaps && freqCaps.length > 0 && (
                    <Box my={1} className={classes.freqCapWrap}>
                      {freqCaps.map(f => (
                        <Typography key={f.fctype} className={classes.cap}>
                          {`${f.impressions} ads every ${f.duration
                            } ${f.fctype.toLowerCase()}(s)`}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </ButtonBase>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Button
                  className="freq-cap-btn"
                  onClick={() => setIsDayParting(true)}
                  startIcon={<img src={freqCapIcon} width={17} />}
                >
                  <Typography className={classes.freqCap}>
                    {currentDayPartsCopy}
                  </Typography>
                </Button>

                {dayParts.length > 0 &&
                  <ButtonBase
                    className={classes.freqCapsBtn}
                    p={0}
                    onClick={() => setIsDayParting(true)}
                  >
                    <Box my={1} className={classes.freqCapWrap}>
                      {dayParts.map((part, index) =>
                        <Typography key={index} className={classes.cap}>
                          {days.filter(d => part.days[d.toLowerCase()].selected).join(', ')}

                          {` | `}

                          {`${convertToTime(part.time[0], -12, 12)}-
                          ${convertToTime(part.time[1], -12, 12)}`}
                        </Typography>)}
                    </Box>
                  </ButtonBase>}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <CampaignFooter
          isDisabled={adGroup.length < 3 || bid.length < 1}
          isLoading={isLoading}
          back={'Inventory'}
          next={'Upload Creative'}
          onBack={handleBack}
          onNext={() => {
            setIsLoading(true);
            triggerSave('NameAdGroup', false, 'CreativeUpload');
          }}
          page={4}
        />
      </Box>
    </Fragment>
  );
};

NameAdGroup.propTypes = {
  handleCampaignPanel: PropTypes.func,
  handleCurrentAdGroup: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
  noEndDate: PropTypes.bool,
  selectedEndDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  selectedEndTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  selectedStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  selectedStartTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  setIsAdGroup: PropTypes.func,
  setStep: PropTypes.func,
  setIsDayParting: PropTypes.func,
  advertiserId: PropTypes.number,
  currentAdGroup: PropTypes.object,
  cpmRange: PropTypes.array,
  showMaxCpm: PropTypes.bool,
  save: PropTypes.object,
  triggerSave: PropTypes.func,
  adjustRecommendedBid: PropTypes.bool,
  setInventoryOverlay: PropTypes.func,
  freqCaps: PropTypes.array,
  dayParts: PropTypes.array,
  currentDayPartsCopy: PropTypes.string,
  setFreqCaps: PropTypes.func,
  setIsFreqCaps: PropTypes.func,
  rangeText: PropTypes.string,
  deliveryTime: PropTypes.string,
  setDeliveryTime: PropTypes.func,
  combinedTargetingData: PropTypes.object,
};

export default NameAdGroup;
