import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Title from './Title';



const useStyles = makeStyles((theme) => ({
  backBtn: {
    color: `rgba(0, 0, 0, 0.1)`,
  },
  circle: {
    backgroundColor: `#f7f7f7`,
    borderRadius: `50%`,
    color: `#1dafff`,
    height: `1.5rem`,
    textAlign: `center`,
    width: `1.5rem`,
    marginRight: theme.spacing(2),
  },
  divider: {
    opacity: 0,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  ecommerce: {
    //textAlign: `left`,
    //margin: theme.spacing(1),
    width: 460,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  menus: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  next: {
    marginTop: theme.spacing(3),
    textAlign: `right`,
    position: 'relative',
    bottom: '10%'
  },
  paper: {
    padding: theme.spacing(2),
  },
  textField: {
    width: '100%',
  },
}));

const forbiddenCharacters = [
  'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r',
  's','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J',
  'K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','`','~',
  '!','@','#','$','%','^','&','*','(',')','_','=','+','[','{',']','}','\\',
  '|',';',':','\'','"',',','<','>','/','?'];


//////////////////////////////////////////
// CONFIGURE TRACKING EVENTS COMPONENT
//////////////////////////////////////////
const ConfigureTrackingEvents = (props) => {
  const classes = useStyles();

  const initialEcommerce = () => props.ecommerce ? props.ecommerce : '';
  const [ecommerceValue, setEcommerceValue] = useState(initialEcommerce());

  const handleEcommerceChange = (event) => {
    setEcommerceValue(event.target.value);

    if (props.setEcommerce) {
      props.setEcommerce(event.target.value);
    }
  }

  const handleEventSlug = (eventSlug, name) => {
    props.handleEventSlug(eventSlug, name);
  }

  const handleEventValue = (eventValue, name) => {
    props.handleEventValue(eventValue, name);
  }

  // const handleConversionWindow = (conversionWindow, name) => {
  //   props.updateTrackingEventConversionWindow(conversionWindow, name);
  // }

  const trackingEvents = props.selectedEvents.map((trackingEvent, index) => {
    return (
      <Grid key={index} className={classes.menus} container item alignItems="center">
      <Grid item>
        <div className={classes.circle}>
          {index + 1}
        </div>
      </Grid>

      <Grid
        alignItems="center"
        container
        item
        justify="flex-start"
        spacing={2}
        xs={11}
      >
        <Grid item xs={3}>
          <TextField
            className={classes.textField}
            label="Tracking Event"
            value={trackingEvent.name}
            variant="outlined"
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <LockOutlinedIcon />
                </InputAdornment>,
            }}
          >
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <TextField
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            label="Event Slug"
            defaultValue={trackingEvent.slug}
            onChange={event =>
              handleEventSlug(event.target.value, trackingEvent.name)
            }
            value={trackingEvent.slug}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment:
                <InputAdornment position="start">
                  <AttachMoneyOutlinedIcon />
                </InputAdornment>,
            }}
            onKeyDown={(event) => {
              if (_.includes(forbiddenCharacters, event.key)) {
                event.preventDefault();
              }
            }}
            onChange={event =>
              handleEventValue(event.target.value, trackingEvent.name)
            }
            label="Enter Value"
            value={trackingEvent.value}
            placeholder="Enter Value"
            variant="outlined"
          >
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <TextField
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            id="e-commerce-dropdown"
            label="Conversion Window"
            value={`${trackingEvent.window} days`}
            variant="outlined"
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <LockOutlinedIcon />
                </InputAdornment>,
            }}
          >
            {/* <MenuItem value={1}>
              1 day
            </MenuItem> */}

            {/* <MenuItem value={15}>
              15 days
            </MenuItem> */}

            <MenuItem value={30}>
              30 days
            </MenuItem>

            {/* <MenuItem value={90}>
              90 days
            </MenuItem> */}

          </TextField>
        </Grid>
      </Grid>
    </Grid>
    )
  });

  return (
    <Fragment>
      <Title>Configure Tracking Events</Title>

      <Divider className={classes.divider} />

      <Paper
        className={classes.paper}
        variant="outlined"
      >
        <Grid container>
          <Grid className={classes.menus} container item alignItems="center">
            <Grid item>
              <div className={classes.circle}>
                1
              </div>
            </Grid>

            <Grid item>
              <TextField
                id="e-commerce-dropdown"
                className={classes.ecommerce}
                select
                label="Select E-commerce Provider"
                placeholder="Select E-commerce Provider"
                value={ecommerceValue}
                onChange={handleEcommerceChange}
                variant="outlined"
              >
                <MenuItem value="none">
                  <em>None</em>
                </MenuItem>

                <MenuItem value="big-commerce">
                  Big Commerce
                </MenuItem>

                <MenuItem value="magento">
                  Magento
                </MenuItem>

                <MenuItem value="salesforce">
                  Salesforce
                </MenuItem>

                <MenuItem value="sap">
                  SAP
                </MenuItem>

                <MenuItem value="shopify">
                  Shopify
                </MenuItem>

                <MenuItem value="squarespace">
                  Squarespace
                </MenuItem>

                <MenuItem value="woo-commerce">
                  Woo Commerce
                </MenuItem>

                <MenuItem value="wordpress">
                  Wordpress
                </MenuItem>

                <MenuItem value="custom">
                  Custom
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>

          {trackingEvents}
        </Grid>

        <Grid className={classes.next} container alignItems="center" justify="flex-end">
          <Grid container item xs={6} justify="flex-start">
            <Button
              color="secondary"
              className="Button-campaign-nav"
              onClick={() => {
                props.setStep('TrackingEvents');
                props.updateBreadcrumbs('tracking', 25);
              }}
              startIcon={<ArrowBackIcon />}
              variant="outlined"
              disableElevation
            >
              Select Tracking Events
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              className="Button--medium"
              color="secondary"
              disableElevation
              onClick={() => {
                props.setStep('InstallTrackingCode');
                props.updateBreadcrumbs('tracking', 75);
              }}
              size="small"
              variant="contained"
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};

ConfigureTrackingEvents.propTypes = {
  selectedEvents: PropTypes.array,
  handleEventSlug: PropTypes.func,
  handleEventValue: PropTypes.func,
  // updateTrackingEventConversionWindow: PropTypes.func,
  setStep: PropTypes.func,
  ecommerce: PropTypes.string,
  setEcommerce: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
};

export default ConfigureTrackingEvents;
