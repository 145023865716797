import moment from 'moment-timezone';
import _ from 'lodash';

export const useUtil = () => {
  const compareArrays = (data1, data2) => {
    if (data1.length !== data2.length) {
      return true;
    }

    let hasEdited = false;

    data1.forEach((d) => {
      if (!data2.includes(d)) {
        hasEdited = true;
      }
    });

    return hasEdited;
  };

  const compareTo30days = (value, unit) => {
    const day = 30;
    const week = 4.28571;
    const min = 43200;
    const hour = 720;

    switch (unit) {
      case 'DAY':
        return value <= day;
      case 'WEEK':
        return value <= week;
      case 'MIN':
        return value <= min;
      case 'HOUR':
        return value <= hour;
    }
  };

  const isValidBudget = (value) => {
    // Check budget is at least one cent
    const budget = parseFloat(value);

    if (!isNaN(budget)) {
      return budget >= .01;
    }

    return false;
  }

  const formatDateTime = (date, time) => {
    const hour = new Date(time).getHours();
    const minute = new Date(time).getMinutes();
    const dateTime = moment(date).hour(hour).minute(minute);

    return moment(dateTime).utc().format();
  };

  const deepCompare = (array1, array2) => {
    return _.isEqual(array1, array2);
  };

  return {
    compareArrays,
    compareTo30days,
    deepCompare,
    formatDateTime,
    isValidBudget
  };
};
