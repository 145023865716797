import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Fade,
  Grid,
  Paper,
  Divider,
  Tabs,
  Tab,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import _ from 'lodash';
import { useFieldArray, useFormContext } from 'react-hook-form';

import AdvertiserContext from './AdvertiserContext';
import CreativeLibrary from './CreativeLibrary';
import DisplayLibrary from './DisplayLibrary';
import FileDropzone from './FileDropzone';
import FileProgress from './FileProgress';
import AlertBox from './AlertBox';
import Title from './Title';
import ImageSpecifications from './ImageSpecifications';
import { VastTagAdding, VastTagList } from './VastTag';
import { useAPI } from './hooks/api';
import { useCopy } from './hooks';
import { useUpload } from './hooks/upload';
import { Themes } from '../constants';
import uploadIcon from '../images/nbcu/padman-icon-upload.svg';

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    height: '100%',
    marginBottom: spacing(0),
  },
  divider: {
    marginBottom: spacing(4),
  },
  tooltipWidth: {
    backgroundColor: `#f2f3f5`,
    color: palette.text.primary,
    padding: spacing(2),
    maxWidth: 180,
  },
  files: {
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    marginTop: spacing(4),
    maxHeight: 422,
    overflow: 'auto',
    width: '100%',
  },
  main: {
    height: '100%',
  },

  tabs: {
    borderBottom: `1px solid #e0e0e0`,
    width: `100%`,
    position: `relative`,
  },
  info: {
    fontSize: '0.8125rem',
  },
}));

const Copies = {
  [Themes.DEFAULT]: {
    INTRO: null,
    FileDropzone: {
      image: {
        DESCRIPTION: 'Drag Your Display Files here, or',
        specifications: <ImageSpecifications />,
      },
      video: {
        DESCRIPTION: 'Drag Your Creative Files here, or',
        specifications: (
          <div className="Dropzone-specs">
            <p>Recommended Aspect Ratio: 16:9</p>
            <p>Recommended ad lengths: :15, :30 and :60</p>
            <p>Supported file formats: MP4 and MOV</p>
            <p>Max File Size: 250MB</p>
          </div>
        ),
      }
    },
  },
  [Themes.NBCU]: {
    INTRO: 'Upload your creative video files. If you’ve previously uploaded creative flies, they can be found in the Media Library tab.',
    FileDropzone: {
      image: {
        DESCRIPTION: 'Drag Your Display Files here or',
        specifications: <ImageSpecifications />,
      },
      video: {
        DESCRIPTION: 'Drag Your Creative Files here or',
        specifications: (
          <div className="Dropzone-specs">
            <p>Required aspect ratio: 16:9</p>
            <p>Supported ad lengths: :6, :15, :30, :45, :60 and :90</p>
            <p>Supported file formats: MP4 and MOV</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://together.nbcuni.com/nbcu-creative-guidelines/programmatic/"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              Full NBCU creative specifications
            </a>
          </div>
        ),
      }
    },
  }
};

const FileUploader = props => {
  const {
    accept,
    adGroupId,
    handleSaveCreative,
    setCurrentPreview,
    setIsCreativePreview,
    type,
    showVastTag,
    uploadView: view,
  } = props;

  const classes = useStyles();

  const { usePost } = useAPI();
  const adContext = useContext(AdvertiserContext);
  const { control, watch, formState } = useFormContext();
  const Copy = useCopy(Copies);
  const { errors } = formState;

  const { fields, append, replace } = useFieldArray({
    name: "creatives",
    keyName: 'internalId',
    control,
  });

  // TODO: get rid of this hook by taking all the logic out
  const {
    formatFileName,
    isUploading,
    setIsUploading,
    progressBars,
    setProgressBars,
    setIsUploadSuccess,
    uploadError,
    setUploadError,
    uploadCreative,
    uploadDisplay,
  } = useUpload({
    uploadMetadataState: [
      fields,
      replace,
    ],
  });

  const initialView = () => (view ? view : 'start');
  const [uploadView, setUploadView] = useState(initialView());
  const [tab, setTab] = useState(0);

  const vastTags = useMemo(
    () => fields.filter(i => i.vast_tag_url),
    [fields]
  );

  useEffect(() => {
    const nextView = fields.length > 0 ? 'progress' : 'start';

    setUploadView(nextView);
  }, [fields]);

  useEffect(() => {
    fields.forEach((file) => {
      if (file && file.name && progressBars[file.name] === null) {
        setProgressBars(prev => ({
          ...prev,
          [file.name]: 100,
        }));
      }
    });
  }, [fields])

  const handleSetTab = (event, newTab) => {
    setTab(newTab);
  };

  const handleBatchAssign = files => {
    if (files.length <= 0) {
      return;
    }

    if (props.handleAssign) {
      props.handleAssign(files);

      return;
    }

    append(files);
  };

  const handleUnassign = file => {
    if (props.handleUnassign) {
      props.handleUnassign(file);

      return;
    }

    replace(fields.filter(creative => creative.id !== file.id));
  };

  const handleSubmitVastTags = useCallback(async ({ tags }) => {
    try {
      const requests = tags.map(tag => usePost('/creatives', {
        ...tag,
        advertiser: adContext.url
      }));

      const responses = await Promise.all(requests);
      const data = responses.map(response => response.data);

      append(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleUpload = (files) => {
    if (type === 'image') {
      return uploadDisplay(files);
    }

    return uploadCreative(files);
  };

  const handleError = error => {
    setUploadError(error);
  };

  const handleDrop = (acceptedFiles) => {
    setUploadError(null);
    setIsUploadSuccess(false);
    setUploadView('progress');

    const url = type === 'image'
      ? '/static_display_creatives'
      : '/creatives';

    if (props.setIsLoading) {
      props.setIsLoading(true);
    }

    if (props.setIsUploading) {
      props.setIsUploading(true);
    }

    return handleUpload(acceptedFiles)
      .then(responses => {
        // Handle upload errors
        if (responses && responses.response) {
          return Promise.reject(responses.response);
        }

        const filePromises = responses.map((r) => {
          const fileData = {
            advertiser: r.data.advertiser,
            name: r.data.name,
          };

          if (type === 'video') {
            fileData.video_asset = r.data.url;
            // replaces the extension string after the first period detected with an empty string
            fileData.name = fileData.name.replace(/\.[^/.]+$/, "")
          }

          if (type === 'image') {
            fileData.image_asset = r.data.url;
            fileData.active = true;
          }

          return usePost(url, fileData);
        });

        return filePromises;
      })
      .then(responses => {
        // handle success from file upload
        console.log('Upload success!');

        return Promise.all(responses)
          .then((fileRes) => {
            const filesArray = fileRes.map(file => file.data);

            if (props.handleAssign) {
              props.handleAssign(filesArray);
            } else {
              const creatives = watch('creatives');

              const [prevCreatives, uploadableCreatives] = creatives.reduce((accumulation, creative) => {
                const index = creative.uploadable ? 1 : 0;

                accumulation[index].push(creative);

                return accumulation;
              }, [[], []]);

              const nextCreatives = filesArray.map((creative, index) => {
                const { click_url } = uploadableCreatives[index] || creative;

                return {
                  ...creative,
                  click_url
                };
              });

              replace([...prevCreatives, ...nextCreatives]);
            }

            if (props.setIsLoading) {
              props.setIsLoading(false);
            }

            if (props.setIsUploading) {
              props.setIsUploading(false);
            }

            return fileRes;
          })
          .catch(error => console.error(error));
      })
      .catch((error) => {
        // handle error
        console.log('Upload failed!');
        console.log(error.request);
        console.log('Error in file uploader', error);
        setIsUploading(false);
        setIsUploadSuccess(false);
        handleError(error.data.error);

        if (props.setIsUploading) {
          props.setIsUploading(false);
        }

        if (props.setIsLoading) {
          props.setIsLoading(false);
        }
      });
  };

  // Removes file from metadata array and HOC files prop
  const handleRemoveFile = (key) => {
    const creative = fields.find(data => data.id === key);
    const lineItems = _.filter(creative, function(v, k) {
      return _.includes(k, 'lineitem_set');
    });

    replace(fields.filter((f) => f.id !== key));

    if (props.handleDeleteFile &&
      lineItems[0].length === 0 ||
      lineItems[0].length === 1 && lineItems[0][0].id === adGroupId) {
      props.handleDeleteFile(key);
    }
  };

  // Shows status message of upload
  const renderUploadMessage = () => (
    <Grid container>
      <Box pt={10} pb={4}>
        {/* TODO: Edit copy */}
        {/* <Typography
          component="h4"
          gutterBottom
          style={{ color: '#0fbf84' }}
          variant="h4"
        >
          This might take a while - Feel free to proceed to the next step
        </Typography>

        <Typography variant="body2" style={{ color: '#9aa0a6' }}>
          Your upload will continue in the background – just don’t close this
          tab or put your computer to sleep
        </Typography> */}
      </Box>
    </Grid>
  );

  const renderUploadView = () => (
    <Box width="100%">
      {uploadView === 'start' && (
        <Fragment>
          {Copy.INTRO && <p>{Copy.INTRO}</p>}

          <FileDropzone
            accept={accept}
            handleDrop={handleDrop}
            uploadIcon={adContext.theme === Themes.NBCU && uploadIcon}
            description={Copy.FileDropzone[type].DESCRIPTION}
            specifications={Copy.FileDropzone[type].specifications}
          />

          {fields.length > 0 && (
            <Box mt={4}>
              <Button
                color="secondary"
                onClick={() => {
                  setUploadView('progress');
                }}
                size="small"
              >
                View Progress
              </Button>
            </Box>
          )}
        </Fragment>
      )}

      {uploadView === 'progress' && (
        <Grid container>
          {errors.creatives && errors.creatives.length > 0 && (
            <Grid item xs={12}>
              <Box mb={3}>
                <AlertBox
                  {...alert.activeAlert}
                  isAlertOpen
                  type="MAJOR"
                  message={errors.creatives[0].weighting.message}
                />
              </Box>
            </Grid>
          )}

          {type === 'video' && <Title>Verify Creative Title, Language, and Weighting</Title>}
          {type === 'display' && <Title>Verify Display Title and Weighting</Title>}

          <Grid container>
            {uploadError && (
              <Box mt={1}>
                <Alert icon={<WarningIcon />} severity="error">
                  {uploadError}
                </Alert>
              </Box>
            )}

            <Paper className={classes.files} variant="outlined">
              {fields.length > 0 &&
                fields.map((meta, index) => {
                  const name = meta && meta.fileName
                    ? meta.fileName
                    : meta && meta.name
                      ? meta.name
                      : 'meta-err';

                  // TODO: Need data coming from BE for fileSize and resolution
                  return (
                    <Fragment key={`${name}-${index}`}>
                      <FileProgress
                        index={index}
                        duration={meta.duration}
                        fileSize={meta.file_size_bytes}
                        fileId={meta.id}
                        formatFileName={formatFileName}
                        handleRemoveFile={handleRemoveFile}
                        id={`${name}-${index}`}
                        name={name}
                        progressBars={progressBars}
                        resolution={meta.resolution}
                        control={control}
                        field="creatives"
                      />

                      {fields.length !== index + 1 && <Divider />}
                    </Fragment>
                  );
                })}
            </Paper>
          </Grid>

          <Grid container>
            <Box mt={4}>
              <Button
                color="secondary"
                onClick={() => {
                  setUploadView('start');
                }}
                disabled={isUploading}
                size="small"
                startIcon={<CloudUploadOutlinedIcon />}
              >
                {type === 'video' && 'Upload More Creatives'}
                {type === 'image' && 'Upload More Displays'}
              </Button>
              {isUploading && (
                <Tooltip
                  classes={{ tooltip: classes.tooltipWidth }}
                  title={"Please wait while media is uploading"}
                >
                  <InfoOutlinedIcon
                    className={classes.info}
                    fontSize="small"
                    color="secondary"
                  />
                </Tooltip>
              )}
            </Box>
          </Grid>

          <Fade in={isUploading}>{renderUploadMessage()}</Fade>
        </Grid>
      )}
    </Box>
  );

  return (
    <Grid className={classes.container} container>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
        >
          <Box
            className={classes.main}
            flexGrow={1}
            width="100%"
            height="100%"
          >
            {adContext.theme === Themes.NBCU && <Title>Upload Creative</Title>}

            <Box mb={4} width="100%">
              <Tabs
                className={classes.tabs}
                onChange={handleSetTab}
                value={tab}
              >
                {type === 'video' && <Tab label="Upload Creative" />}
                {showVastTag && type === 'video' && (
                  <Tab label="By VAST Tag" />
                )}
                {type === 'video' && <Tab label="Media Library" />}

                {type === 'image' && <Tab label="Upload Display" />}
                {type === 'image' && <Tab label="Display Media Library" />}
              </Tabs>
            </Box>

            {tab === 0 && renderUploadView()}

            {tab === 1 &&
              type === 'video' &&
              !vastTags.length &&
              showVastTag && (
                <VastTagAdding onSubmit={handleSubmitVastTags} />
              )}

            {tab === 1 &&
              type === 'video' &&
              vastTags.length > 0 &&
              showVastTag && (
                <VastTagList
                  data={vastTags}
                  onSubmit={handleSaveCreative}
                  onRemove={handleRemoveFile}
                />
              )}

            {tab === 1 &&
              type === 'video' &&
              !showVastTag && (
                <CreativeLibrary
                  assigned={fields}
                  handleBatchAssign={handleBatchAssign}
                  handleUnassign={handleUnassign}
                  setCurrentPreview={setCurrentPreview}
                  setIsPreview={setIsCreativePreview}
                />
              )}

            {tab === 2 &&
              type === 'video' &&
              showVastTag && (
                <CreativeLibrary
                  assigned={fields}
                  handleBatchAssign={handleBatchAssign}
                  handleUnassign={handleUnassign}
                  setCurrentPreview={setCurrentPreview}
                  setIsPreview={setIsCreativePreview}
                />
              )}

            {tab === 1 && type === 'image' && (
              <DisplayLibrary
                assigned={fields}
                handleBatchAssign={handleBatchAssign}
                handleUnassign={handleUnassign}
                setCurrentPreview={setCurrentPreview}
                setIsPreview={setIsCreativePreview}
              />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

FileUploader.propTypes = {
  accept: PropTypes.string,
  adGroupId: PropTypes.number,
  handleAssign: PropTypes.func,
  handleUnassign: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  handleSaveCreative: PropTypes.func,
  setIsLoading: PropTypes.func,
  setCurrentPreview: PropTypes.func,
  setIsCreativePreview: PropTypes.func,
  setIsUploading: PropTypes.func,
  type: PropTypes.string,
  showVastTag: PropTypes.bool,
  uploadView: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

export default FileUploader;
