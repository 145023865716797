import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
  Box,
} from '@material-ui/core';

import AppHeader from '../AppHeader';
import AdvertiserContext from '../AdvertiserContext';
import { useAPI } from '../hooks/api';

const useStyles = makeStyles(() => ({
  previewThumb: {
    width: '100%',
  },
  title: {
    wordBreak: 'break-all',
  },
  link: {
    textDecoration: 'none',
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    color: 'rgba(114, 115, 131, 0.8)',
    fontSize: 12,
    lineHeight: 'normal',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

const headCells = [
  { id: 'campaignName', label: 'Campaign', disablePadding: false },
  { id: 'adGroupName', label: 'Ad Group', disablePadding: false },
  { id: 'startDate', label: 'Start date', disablePadding: false },
  { id: 'endDate', label: 'End date', disablePadding: false },
];

function CreativeDetailTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell></StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

export default function CreativeDetailPage(props) {
  const classes = useStyles();
  const { useGet } = useAPI();

  const [details, setDetails] = useState([]);
  const [creative, setCreative] = useState([]);

  const adContext = useContext(AdvertiserContext);
  const creativeId = window.location.pathname.split('/creative/')[1];
  const now = moment();

  const [,,campaignId,,adGroupId] = window.location.pathname.split('/');
  const adGroupsLink = campaignId === 'na' ? '/campaigns' : `/campaigns/${campaignId}/adgroups/${adGroupId}`;
  const creativeLink = adGroupId === 'na' ? '/creatives' : `/campaigns/${campaignId}/adgroups/${adGroupId}/creative`;

  useEffect(() => {
    if (
      creative.lineitem_set &&
      creative.lineitem_set[0] &&
      !creative.lineitem_set[0].campaignName
    ) {
      getCampaignName(creative.lineitem_set[0].campaign);
    }
  }, [creative]);

  useEffect(() => {
    if (adContext.id !== null) {
      getCreativeDetails(creativeId);
    }
  }, [adContext]);

  async function getCampaignName(url) {
    const campaignId = url.split('/campaigns/')[1];

    try {
      const response = await useGet(`/campaigns/${campaignId}`);
      creative.lineitem_set[0].campaignName = response.name;
      setDetails(creative.lineitem_set);
    } catch (error) {
      console.log(error);
    }
  }

  async function getCreativeDetails(creativeId) {
    try {
      const response = await useGet(`/creatives/${creativeId}`);
      setCreative(response);
    } catch (error) {
      console.log(error);
    }
  }

  const formatEndDate = endDate => {
    if (Date.parse(endDate)) {
      return moment(endDate).format('l');
    }
    return 'No End Date';
  };

  return (
    <AppHeader history={props.history}>
      <Box m={4} mb={2}>
        <Typography>
          <strong>
            <Link className={classes.link} to={`/campaigns/`}>
              Campaigns
            </Link>
          </strong>
          &nbsp;&gt;&nbsp;
          <strong>
            <Link className={classes.link} to={adGroupsLink}>
              Ad Groups
            </Link>
          </strong>
          &nbsp;&gt;&nbsp;
          <strong>
            <Link className={classes.link} to={creativeLink}>
              Creative
            </Link>
          </strong>
          &nbsp;&gt;&nbsp;
          {creative.name ? creative.name : ''}
        </Typography>
      </Box>
      <Box border={1} borderColor="grey.300" p={6} m={4} borderRadius={20}>
        <Grid container spacing={6}>
          <Grid item container xs={5}>
            <Box>
              {creative.preview_url ? (
                <video
                  className={classes.previewThumb}
                  src={creative.preview_url}
                  controls
                />
              ) : (
                ''
              )}
              <Box mt={2} />
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Typography className={classes.title} variant="h4">
                    {creative.name ? creative.name : ''}
                  </Typography>
                  <Box mt={2} />
                  {/* Need to ad back in size and quality */}
                  <Typography variant="body2">
                    {creative.duration ? `${creative.duration} seconds` : ''}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {creative.lineitem_set && creative.lineitem_set.length
                      ? `Launched ${now.diff(
                          creative.lineitem_set[0].start_date,
                          'days'
                        )} days ago | `
                      : ''
                    }

                    {creative.lineitem_set && creative.lineitem_set.length
                      ? moment(creative.lineitem_set[0].start_date).format(
                          'l'
                        )
                      : ''
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item container xs={7}>
            <TableContainer>
              <Typography variant="h3" component="div">
                Ad Group Assignments
              </Typography>
              <Table>
                <CreativeDetailTableHead></CreativeDetailTableHead>
                <TableBody>
                  {details.map(detail => {
                    return (
                      <TableRow tabIndex={-1} key={detail.id}>
                        <TableCell align="left">
                          {detail.campaignName}
                        </TableCell>
                        <TableCell align="left">{detail.name}</TableCell>
                        <TableCell align="left">
                          {moment(detail.start_date).format('l')}
                        </TableCell>
                        <TableCell align="left">
                          {formatEndDate(detail.end_date)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </AppHeader>
  );
}

CreativeDetailPage.propTypes = {
  history: PropTypes.object,
};
