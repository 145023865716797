import React, { useMemo, useState, useEffect } from 'react'
import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core'
import numeral from 'numeral'
import multiDownload from 'multi-download';
import { useSnackbar } from 'notistack';

import EnhancedBillingTable from './EnhancedBillingTable'
import { useAPI } from './hooks/api'

const useStyles = makeStyles(() => ({
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 375,
    alignItems: 'center',
  },
}));

const BillingTable = () => {
  const { useGet, useGetBlob } = useAPI();
  const [data, setData] = useState([])
  const [skipPageReset, setSkipPageReset] = useState(false)

  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const getInvoice = async (id) => {

    try {
      enqueueSnackbar('Invoice downloading', {
        autoHideDuration: 3000,
        preventDuplicate: true,
        variant: 'success',
      })

      setIsLoading(true)
      const response = await useGetBlob(`/billing_events/${id}/create_invoice/`)
      multiDownload([window.URL.createObjectURL(new Blob([response], { type: 'text/plain' }))], {
        rename: () => `Invoice ${id}.pdf`
      });
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const DownloadButton = (props) => {
    // eslint-disable-next-line react/prop-types
    return <Button onClick={() => { getInvoice(props.cell.row.original.id) }}>DOWNLOAD</Button>
  }

  const Amount = (props) => {
    // eslint-disable-next-line react/prop-types
    return <div>{numeral(props.value).format('$0.00')}</div>
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'billing_end_date',
      },
      {
        Header: 'Invoice Number',
        accessor: 'id',
      },
      {
        Header: 'Invoice Amount',
        accessor: 'amount',
        Cell: Amount
      },
      {
        Header: 'Action',
        accessor: 'url',
        Cell: DownloadButton,
      },
    ],
    []
  )

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true)
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }


  const getData = async () => {
    const invoices = await useGet('/billing_events/')

    const safeInvoices = []

    invoices.results.forEach(i => {
      if (i.authorize_transaction_id) {
        safeInvoices.push(i)
      }
    })
    setData(safeInvoices)
  }

  useEffect(() => {
    getData()
  }, [])


  if (isLoading) {
    return <Box className={classes.spinner}>
      <CircularProgress color="secondary" />
    </Box>
  }

  return (
    <EnhancedBillingTable
      setIsLoading={setIsLoading}
      columns={columns}
      data={data}
      setData={setData}
      updateMyData={updateMyData}
      skipPageReset={skipPageReset}
    />
  )
}

export default BillingTable;
