import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from '@material-ui/lab';
import { Collapse, IconButton } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close"
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';

const severities = {
  PARTIAL: "warning",
  RELEASE: "info",
  MAJOR: "error"
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    }
  },
  title: {
    fontWeight: 'bold',
    lineHeight: 1.5
  },
  description: {
    fontWeight: 'normal'
  }
}));

const AlertBox = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Collapse in={props.isAlertOpen}>
        <Alert
          icon={
            props.type === 'RELEASE' ? (
              <InfoOutlinedIcon/>
            ) : (
              <WarningIcon/>
            )
          }
          variant="filled"
          color={severities[props.type]}
          action={
            props.closeable && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  props.closeAlert(false);
                }}
              >
                <CloseIcon fontSize={"inherit"}/>
              </IconButton>
            )}
        >
          <span className={classes.description}>
            <span className={classes.title}>{props.title}</span>
            {` — ${props.message}`}
          </span>
        </Alert>
      </Collapse>
    </div>
  )
};

AlertBox.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.oneOf(['PARTIAL', 'RELEASE', 'MAJOR']),
  closeable: PropTypes.bool,
  isAlertOpen: PropTypes.bool,
  closeAlert: PropTypes.func
}

export default AlertBox;
