import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Backdrop,
  Box,
  Button,
  Container,
  Fade,
  Grid,
  Modal,
  Paper,
  makeStyles
} from '@material-ui/core';

import AdvertiserContext from './AdvertiserContext';
import AsyncButton from './AsyncButton';

const useStyles = makeStyles(({ palette, spacing }) => ({
  bar: {
    inset: 0,
    zIndex: 1,
    pointerEvents: 'none',
    position: 'absolute',
  },
  cancel: {
    color: palette.grey.main,
  },
  container: {
    marginTop: 64,
    paddingBottom: spacing(2),
    minHeight: '80%',
    height: '100%',
  },
  footer: {
    backgroundColor: '#e5e7eb',
    position: 'absolute',
    bottom: 18,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  inner: {
    overflow: 'auto',
    maxHeight: '100%',
    zIndex: 2,
    position: 'relative',
  },
  gradient: {
    bottom: 90,
    pointerEvents: 'none',
  },
  modal: {
    maxHeight: '80%',
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 18,
    right: 0,
    zIndex: 12,
  },
  paper: {
    overflow: 'auto',
    width: '100%',
    paddingTop: spacing(8),
    paddingLeft: spacing(6),
    paddingRight: spacing(6),
    paddingBottom: spacing(10),
    position: 'relative',
  },
  bottomBar: {
    bottom: 16,
  },
  pad: {
    paddingBottom: 117,
  },
}));

const ModalWrapper = props => {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);

  const {
    isDisabled,
    isLoading,
    isUploading = false,
    isOpen,
    isOverlay,
    overlay,
    onCloseOverlay,
    onClose,
    onSaveDraft,
    onSubmit = () => {},
    hasSubmit,
    hasSaveDraft,
    submit,
    children
  } = props;

  const handleClose = () => {
    if (isOverlay) {
      return onCloseOverlay();
    }

    onClose();
  };

  const isSubmitLong = submit && submit.length > 22;

  const renderFooter = () => (
    <Box p={3} px={8} className={classes.footer}>
      <Grid container justify="flex-end">
        <Grid
          container
          item
          alignItems="center"
          justify="flex-end"
          xs={6}
        >
          <Grid container item xs={isSubmitLong ? 2 : 3} justify="flex-end">
            <Button className={classes.cancel} onClick={() => onClose()}>
              Cancel
            </Button>
          </Grid>

          {hasSaveDraft &&
            <Grid item xs={4}>
              <AsyncButton
                disableElevation
                isDisabled={isDisabled}
                isLoading={isLoading || isUploading}
                onClick={(e)=> onSaveDraft(e)}
                textButton="Save as Draft"
                loadingButton={
                  isUploading
                    ? '...'
                    : 'Saving...'
                }
                style={{
                  minWidth: 175,
                  height: 54,
                  borderWidth: 2,
                }}
                variant="outlined"
                type="regular"
              />
            </Grid>}

          <Grid item xs={isSubmitLong ? 6 : 5}>
            <AsyncButton
              nativeType="submit"
              isDisabled={isDisabled}
              isLoading={isLoading || isUploading}
              textButton={submit ? submit : 'Save'}
              loadingButton={
                isUploading
                  ? 'Uploading...'
                  : isLoading
                    ? 'Saving...'
                    : 'Saving...'
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Modal
      className={clsx(classes.modal, `${adContext.theme}-theme`)}
      disableScrollLock
      open={isOpen}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          top: 64,
        },
        timeout: 200,
      }}
    >
      <Fade in={isOpen} timeout={350}>
        <Container
          component="form"
          className={classes.container}
          disableGutters
          maxWidth="lg"
          onSubmit={!isOverlay && onSubmit}
        >
          <Box
            display="flex"
            className={clsx(classes.inner, hasSubmit ? classes.pad : {})}
            width="100%"
          >
            <Paper
              className={clsx(
                classes.paper,
                !hasSubmit && classes.bottomBar,
              )}
              elevation={12}
              square
            >
              {children}
            </Paper>

            {hasSubmit && (
              <Box
                className={clsx(classes.gradient, 'Box-gradient--white')}
                borderRadius={9}
              />
            )}

            {/* TODO: Move nested overlay from modal */}
            {isOverlay && <Box className={classes.overlay}>{overlay}</Box>}

            {hasSubmit && renderFooter()}

            <div
              className={clsx(
                classes.bar,
                '--background-colorbar'
              )}
            />
          </Box>
        </Container>
      </Fade>
    </Modal>
  );
};

ModalWrapper.propTypes = {
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUploading: PropTypes.bool,
  isOverlay: PropTypes.bool,
  onCloseOverlay: PropTypes.func,
  onClose: PropTypes.func,
  onSaveDraft: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  overlay: PropTypes.element,
  hasSubmit: PropTypes.bool,
  hasSaveDraft: PropTypes.bool,
  submit: PropTypes.string,
  children: PropTypes.node,
};

export default ModalWrapper;
