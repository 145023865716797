import LogRocket from 'logrocket';
import { useOktaAuth } from '@okta/okta-react';

//////////////////////////
// useLOGROCKET HOOK
//////////////////////////
export const useLogRocket = () => {
    const { oktaAuth } = useOktaAuth();
    const tagUser = async () => {
        const authUser = await oktaAuth.getUser();
        LogRocket.identify(authUser.sub, {
            name: authUser.names,
            email: authUser.email,
        });
    }
    
    return {
        tagUser,
    };
};
