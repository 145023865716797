import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Grid,
  makeStyles,
} from '@material-ui/core';

import AsyncButton from './AsyncButton';

const useStyles = makeStyles(({ palette }) => ({
  cancel: {
    color: palette.grey.main,
  },
  cancelWrap: {
    textAlign: 'center',
    marginRight: 12,
    maxWidth: 60,
  },
  footer: {
    backgroundColor: '#e5e7eb',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
}));

const ModalFooter = ({
  isDisabled,
  isLoading,
  onCancel,
  onSubmit,
  text,
}) => {
  const classes = useStyles();

  return (
    <Box p={3} px={4} className={classes.footer}>
      <Grid container justify="flex-end">
        <Grid container item alignItems="center" justify="flex-end" xs={12}>
          <Box className={classes.cancelWrap}>
            <Button className={classes.cancel} onClick={onCancel}>
              Cancel
            </Button>
          </Box>

          <Box>
            <AsyncButton
              isDisabled={isDisabled}
              isLoading={isLoading}
              onClick={onSubmit}
              textButton={text}
              loadingButton={
                isLoading
                  ? 'Saving...'
                  : 'Save'
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

ModalFooter.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  text: PropTypes.string,
};

export default ModalFooter;
