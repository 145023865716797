/* eslint-disable import/no-named-as-default */
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import AdvertiserContext from './AdvertiserContext';
import UserContext from '../providers/UserContext';
import Dashboard from './Dashboard';
// Will use this later
// import HomePage from './containers/HomePage';
import AdGroupsIndexPage from './containers/AdGroupsIndexPage/AdGroupsIndexPage';
import CreateAccountPage from './containers/CreateAccountPage';
import CampaignsPage from './containers/CampaignsPage';
import CreativeDetailPage from './containers/CreativeDetailPage';
import CreativeIndexPage from './containers/CreativeIndexPage';
import CreativeIndexPerAdvertiserPage from './containers/CreativeIndexPerAdvertiserPage';
import DisplayDetailPage from './containers/DisplayDetailPage';
import DisplayIndexPage from './containers/DisplayIndexPage';
import ReportsPage from './containers/ReportsPage';
import PathToPurchasePage from './containers/PathToPurchasePage';
import RequestAccountPage from './containers/RequestAccountPage';
import SettingsPage from './containers/SettingsPage';
import SetupWizardPage from './containers/SetupWizardPage';
import SetupAdvertiserPage from './containers/SetupAdvertiserPage';
import SupportPage from './containers/SupportPage';
import TrackingPage from './containers/TrackingPage';
import NotFoundPage from './NotFoundPage';
import PropTypes from 'prop-types';
import React from 'react';
import { hot } from 'react-hot-loader';
import { OktaAuth } from '@okta/okta-auth-js';
import { SecureRoute, LoginCallback, Security } from '@okta/okta-react';
import { createStore, StateMachineProvider } from 'little-state-machine';
import { SnackbarProvider } from 'notistack';
import LogRocket from 'logrocket';
import { compose } from 'ramda';
import "fontsource-noto-sans-jp";
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { withStyles, ThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import User from './User';
import { Themes } from '../constants';
import { defaultTheme, nbcuTheme } from '../themes';

const generateClassName = createGenerateClassName({
  seed: 'tvs', // Use a seed to make class names more deterministic.
  productionPrefix: 'tvs',
});

// Convert ENV var to boolean
// TODO: leave console logs to validate on production. Remove once we're good.
const useLogRocket = (process.env.USE_LOG_ROCKET === 'true');
console.log('use logrocket', useLogRocket)

if (useLogRocket) {
  LogRocket.init('zorsti/prod', {
    network: {
      requestSanitizer: request => {
        if (request && request.body && request.body.toLowerCase().indexOf('password') !== -1) {
          // scrub out the body
          request.body = null;
        }

        // scrub header value from request
        if (request && request.headers && request.headers['Authorization']) {
          request.headers['Authorization'] = '';
        }

        // make sure you return the modified request
        return request;
      },
    },
  });

  console.log('log rocket active');
} else {
  console.log('log rocket inactive');
}

// Create new Okta instance
const oktaAuth = new OktaAuth({
  pkce: false,
  issuer: `${process.env.REACT_APP_OKTA_ORG_URL}/oauth2/default`,
  redirectUri: `${window.location.origin}/login/callback`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
});

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.
const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  // dismiss: {
  //   color: theme.palette.grey.main,
  // },
  warning: {
    backgroundColor: '#fff4e5',
    color: '#663c00',
    fontSize: '0.875rem',
    fontWeight: 400,
    letterSpacing: '.35px',
    maxWidth: 400,

    '& > .notistack-action': {
      color: theme.palette.grey.main,
    },
  },
  error: {
    backgroundColor: '#f76a6a',
    color: '#fff',
    fontSize: '0.875rem',
    fontWeight: 400,
    letterSpacing: '.35px',
    maxWidth: 420,
  },
  success: {
    backgroundColor: '#0fbf84',
    color: '#fff',
    fontSize: '0.875rem',
    fontWeight: 400,
    letterSpacing: '.35px',
    maxWidth: 400,
  },
  warningIcon: {
    marginRight: theme.spacing(1),
    color: '#ff9800',
  },
});

createStore({
  data: {
    inventoryStep: {
      deviceOverlayModal: {
        deviceTypes: [],
        operatingSystems: [],
      },
    }
  }
});

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      name: '',
      token: null,
      theme: Themes.NBCU,
      updateAdvertiser: this.updateAdvertiser,
    };

    this.notistackRef = React.createRef();
  }

  updateAdvertiser = data => {
    this.setState(prevState => ({
      ...prevState,
      ...data,
    }));
  }

  onClickDismiss = key => () => {
    this.notistackRef.current.closeSnackbar(key);
  }

  render() {
    console.log("AdvertiserContext state:", this.state);
    const { classes } = this.props;
    const theme = this.state.theme === Themes.DEFAULT
      ? defaultTheme
      : nbcuTheme;

    return (
      <div className={classes.root}>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              ref={this.notistackRef}
              action={(key) => (
                <IconButton
                  className="notistack-action"
                  onClick={this.onClickDismiss(key)}
                  size="small"
                >
                  <CloseIcon className="snackbar-close" fontSize="small" />
                </IconButton>
              )}
              classes={{
                variantWarning: classes.warning,
                variantError: classes.error,
                variantSuccess: classes.success,
              }}
              iconVariant={{
                warning: (
                  <ReportProblemOutlinedIcon
                    className={classes.warningIcon}
                    fontSize="small"
                  />
                )
              }}
            >
              <Router>
                <Security oktaAuth={oktaAuth}>
                  <StateMachineProvider>
                    <AdvertiserContext.Provider value={this.state}>
                      <User>
                        {user => (
                          <UserContext.Provider value={user}>
                            <Switch>
                              <Route exact path="/" component={RequestAccountPage} />
                              <Route path="/create-account" component={CreateAccountPage} />
                              <SecureRoute path="/home" component={CampaignsPage} />
                              <SecureRoute path="/advertiser-setup" component={SetupAdvertiserPage} />
                              <SecureRoute path="/campaign-setup" component={SetupWizardPage} />
                              <SecureRoute path="/campaign-edit/:id/" component={SetupWizardPage} />
                              <SecureRoute path="/reports" component={ReportsPage} />
                              <SecureRoute path="/path-to-purchase" component={PathToPurchasePage} />
                              <SecureRoute path="/tracking" component={TrackingPage} />
                              <SecureRoute path="/dashboard" component={Dashboard} />
                              <SecureRoute exact path="/campaigns" component={CampaignsPage} />
                              <SecureRoute path="/campaigns/:campaignId/adgroups/:adGroupId/creative/:creativeId" component={CreativeDetailPage} />
                              <SecureRoute path="/campaigns/:campaignId/adgroups/:adGroupId/creative" component={CreativeIndexPage} />
                              <SecureRoute path="/campaigns/:campaignId/adgroups/:adGroupId/displays/:displayId" component={DisplayDetailPage} />
                              <SecureRoute path="/campaigns/:campaignId/adgroups/:adGroupId/displays" component={DisplayIndexPage} />
                              <SecureRoute path="/campaigns/:campaignId/adgroups" component={AdGroupsIndexPage} />
                              <SecureRoute path="/creatives" component={CreativeIndexPerAdvertiserPage} />
                              <SecureRoute path="/displays" component={CreativeIndexPerAdvertiserPage} />
                              <SecureRoute path="/settings" component={SettingsPage} />
                              <SecureRoute path="/support" component={SupportPage} />
                              <Route path="/login/callback" component={LoginCallback} />
                              <Route component={NotFoundPage} />
                            </Switch>
                          </UserContext.Provider>
                        )}
                      </User>
                    </AdvertiserContext.Provider>
                  </StateMachineProvider>
                </Security>
              </Router>
            </SnackbarProvider>
          </ThemeProvider>
        </StylesProvider>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
  classes: PropTypes.object
};

export default compose(
  withStyles(styles, { withTheme: true }),
  hot(module),
)(App);
