import React, { Fragment, useEffect, useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';

import AdvertiserContext from './AdvertiserContext';
import { useLoader } from './hooks/loader';
import { useSaveExit } from './hooks/saveExit';
import AsyncButton from './AsyncButton';
import AuthorizeNetIFrame from './AuthorizeNetIFrame';
import ReviewCampaign from './ReviewCampaign';
import Title from './Title';
import { Themes } from '../constants';

const useStyles = makeStyles(theme => ({
  authorize: {
    fontSize: '0.7rem',
  },
  checkbox: {
    color: `#47505d`,
    textAlign: 'left',
  },
  circle: {
    backgroundColor: `#f7f7f7`,
    borderRadius: `50%`,
    color: `#1dafff`,
    height: `1.5rem`,
    textAlign: `center`,
    width: `1.5rem`,
    marginRight: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  errors: {
    marginTop: theme.spacing(1),
  },
  legalese: {
    fontSize: `0.75rem`,
    marginTop: theme.spacing(3),
  },
  next: {
    marginTop: theme.spacing(3),
    textAlign: `right`,
  },
  paper: {
    padding: theme.spacing(2),
    width: '100%',
    minHeight: 377,
  },
  radioGroup: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  radios: {
    fontSize: `1rem`,
  },
  review: {
    maxWidth: `26.688rem`,
    width: '100%',
  },
  reviewWrap: {
    width: '100%',
  },
  subheader: {
    ...theme.typography.h4,
    margins: 0,
  },
  terms: {
    color: '#069de0',
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  tooltip: {
    fontSize: '0.625rem',
    color: '#47505d',
    backgroundColor: '#f2f3f5',
    padding: theme.spacing(1),
    maxWidth: 180,
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  fullWidth: {
    width: '100%',
  },
}));

// TODO: Error handling for creating campaign
// const errorMessages = {
//   authorize: 'You must authorize payment.',
// };

////////////////////////////////
// LAUNCH CAMPAIGN COMPONENT
////////////////////////////////
const LaunchCampaign = props => {
  const classes = useStyles();

  const adContext = useContext(AdvertiserContext);
  const { isLoading, setIsLoading } = useLoader();
  const { saveAndExit } = useSaveExit();
  const { isPurchaseOrder } = props;

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isEnterPO, setIsEnterPO] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [poNumber, setPONumber] = useState('');
  const [errors, setErrors] = useState([]);

  const { trackingMethod, selectedEvents } = props.tracking;
  const hasTracking = trackingMethod && selectedEvents.length > 0;

  useEffect(() => {
    props.updateBreadcrumbs('launch', 0);
  }, []);

  useEffect(() => {
    saveAndExit(props.save, 'LaunchCampaign');
  }, [props.save]);

  const isLaunchDisabled = useMemo(() =>
    (isPurchaseOrder && !isAuthorized) ||
    (isInvoice && !isAuthorized) ||
    (!isInvoice && !isPurchaseOrder &&
      (!isAuthorized ||
        selectedPayment === null ||
        selectedPayment === '')
      ),
    [
      isPurchaseOrder,
      isInvoice,
      selectedPayment,
      isAuthorized,
      paymentType,
    ]
  );

  const isAuthorizedText = useMemo(() => {
    if (isPurchaseOrder) {
      return (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={
            <Typography className={classes.tooltip}>
              <b>Payment Terms</b><br />
              tvScientific will invoice for the total usage of the platform at
              the end of each calendar month, or when your billing threshold
              is reached, whichever comes first. Learn more about invoicing
            </Typography>
          }
        >
          <Typography className={classes.authorize}>
            I authorize tvScientific to invoice me according to the&nbsp;
            <span className={classes.terms}>payment terms.</span>
          </Typography>
        </Tooltip>
      )
    }

    return (
      <Typography className={classes.authorize}>
        I authorize tvScientific to charge my card for the full amount of this campaign.
      </Typography>
    )
  }, [isPurchaseOrder]);

  const handleIsAuthorized = () => {
    setIsAuthorized(prev => !prev);
  };

  const handleDeletePO = () => {
    setPONumber('');
    setIsEnterPO(false);
  };

  // TODO: Error handling for creating campaign
  // const handleErrors = error => {
  //   setErrors(prev => [
  //     ...prev,
  //     {
  //       type: error,
  //       message: errorMessages[error],
  //     },
  //   ]);
  // };

  const handleData = async () => {
    setIsLoading(true);
    setErrors([]);

    let campaignUrl = null;

    return props.handleCampaignData({
        active: isAuthorized,
        payment_profile: selectedPayment,
        nbcu_purchase_order: poNumber
      })
      .then(response => {
        console.log('respone from create campaign', response);
        if (response && response.data && response.data.url) {
          campaignUrl = response.data.url;

          return props.handleAdGroupsData({
            campaign: response.data.url,
          });
        }

        return response;
      })
      .then(response => {
        console.log('finale response from ad groups', response);

        if (response && props.isDisplay && campaignUrl !== null) {
          return props.handleDisplaysData({
            campaign: campaignUrl,
          });
        }

        return response;
      })
      .then(response => {
        setIsLoading(false);

        if (response) {
          props.setStep('PostLaunch');
        }

        return response;
      })
      .catch(error => {
        setIsLoading(false);

        console.error('Error in Launch Campaign:', error);
      });
  };

  const renderPOForm = () => (
    <Box height={260} width="100%" mt={1}>
      <Typography variant="body2">
        For P.O. number to appear on your invoice, enter it here.
      </Typography>

      {isEnterPO ? (
        <Box mt={3} display="flex">
          <TextField
            autoFocus
            fullWidth
            onChange={event => setPONumber(event.target.value)}
            label="Enter PO #"
            value={poNumber}
            variant="outlined"
          />

          <Box
            display="flex"
            width={55}
            height={55}
            alignItems="center"
            justifyContent="center"
          >
            <IconButton onClick={handleDeletePO} size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box mt={3}>
          <Button
            color="secondary"
            onClick={() => setIsEnterPO(true)}
            startIcon={<AddIcon />}
            size="small"
          >
            Add Purchase Order Number (PO #)
          </Button>
        </Box>
      )}
    </Box>
  );

  const renderLegalese = () => (
    <p className={classes.legalese}>
      Read more about when your card is charged <span />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.tvscientific.com/platform-master-agreement-demand/"
      >
        here
      </a>
      . We will charge the above card at the end of each
      calendar month for the total usage of the platform or when
      your billing threshold is reached whichever comes first.
    </p>
  );

  const renderPOLegalese = () => (
    <p className={classes.legalese}>
      <b>Please note:</b> Your campaign is scheduled to go live on{' '}
      {props.startDate}, but you can edit all aspects of Campaigns and Ad
      Groups prior to, and after, the campaign is live.
    </p>
  );

  return (
    <Fragment>
      <Title>Launch Your Campaign</Title>

      {adContext.theme === Themes.NBCU && (
        <p>
          Review your campaign specs and select the authorization checkbox to launch your campaign.
        </p>
      )}

      <Divider className={classes.divider} />

      <Grid container justify="space-between" spacing={1}>
        <Grid
          className={classes.review}
          container
          direction="column"
          item
          xs={7}
        >
          <Grid item>
            <h3 className={classes.subheader}>
              Review the Campaign Specs
            </h3>
          </Grid>

          <Grid className={clsx(classes.fullWidth, classes.reviewWrap)} item>
            <ReviewCampaign
              adGroups={props.adGroups}
              creatives={props.creatives}
              displays={props.displays}
              displayPercent={props.displayPercent}
            />
          </Grid>
        </Grid>

        <Grid container item xs={5}>
          <Grid item>
            <h3 className={classes.subheader}>
              {isPurchaseOrder
                ? 'Billing Information'
                : 'Enter Your Credit Card Information'}
            </h3>
          </Grid>

          <Grid className={classes.fullWidth} item>
            <Paper className={classes.paper} variant="outlined">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
              >
                <Box height="100%">
                  {isPurchaseOrder ? renderPOForm() : (
                    <AuthorizeNetIFrame
                      onSelectPayment={setSelectedPayment}
                      onPaymentType={setPaymentType}
                      isInvoice={props.billingMethod === 'INVOICE'}
                      setIsInvoice={setIsInvoice}
                    />
                  )}
                </Box>

                <Box>
                  {isPurchaseOrder
                    ? renderPOLegalese()
                    : renderLegalese()}
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        className={classes.errors}
        container
        justify="flex-end"
      >
        <Grid container item spacing={3} xs={5}>
          {errors.length > 0 &&
            errors.map((e, i) => (
              <Grid key={`${e.type}-${i}`} item xs={12}>
                <Typography variant="subtitle2" color="error">
                  {e.message}
                </Typography>
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Box mb={1}>
        <Grid className={classes.next} container justify="flex-end">
          <Grid container item xs={6} justify="flex-start" alignItems="center">
            <Button
              color="secondary"
              className="Button-campaign-nav"
              onClick={() => {
                if (hasTracking) {
                  props.setStep('InstallTrackingCode');
                  props.updateBreadcrumbs('tracking', 75, 'launch', 'default');
                } else {
                  props.setStep('TrackingSetup');
                  props.updateBreadcrumbs('tracking', 0, 'launch', 'default');
                }
              }}
              startIcon={<ArrowBackIcon />}
              variant="outlined"
              disableElevation
            >
              Install Tracking Code
            </Button>
          </Grid>

          <Grid container item justify="flex-end" xs={6}>
            <Grid item xs={4}>
              <div>
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      checked={isAuthorized}
                      onChange={handleIsAuthorized}
                    />
                  }
                  label={isAuthorizedText}
                />
              </div>
            </Grid>

            <Grid item xs={5}>
              <AsyncButton
                color="secondary"
                isDisabled={isLaunchDisabled}
                isLoading={isLoading}
                onClick={() => handleData()}
                classes="Launch-btn"
              >
                Launch
              </AsyncButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

LaunchCampaign.propTypes = {
  adGroups: PropTypes.array,
  billingMethod: PropTypes.string,
  creatives: PropTypes.array,
  displays: PropTypes.array,
  displayPercent: PropTypes.string,
  handleCampaignData: PropTypes.func,
  handleAdGroupsData: PropTypes.func,
  handleDisplaysData: PropTypes.func,
  isDisplay: PropTypes.bool,
  isPurchaseOrder: PropTypes.bool,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  setStep: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
  save: PropTypes.object,
  tracking: PropTypes.object,
};

export default LaunchCampaign;
