import { Themes } from '../constants';

export const getTheme = (isAuthenticated, isNbcuFlag, isNbcuSubdomain, cachedTheme) => {
  if (!isAuthenticated && isNbcuSubdomain) {
    return Themes.NBCU;
  }

  if (isAuthenticated && isNbcuFlag) {
    return Themes.NBCU;
  }

  if (isAuthenticated && isNbcuFlag === null) {
    return Themes.DEFAULT;
  }

  if (isAuthenticated && cachedTheme) {
    return cachedTheme;
  }

  return Themes.DEFAULT;
};
