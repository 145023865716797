import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  small: {
    minWidth: 100,
    fontSize: '.875rem',
  },
  submit: {
    ...typography.h4,
    color: '#fff',
    fontSize: '1.075rem',
    marginLeft: spacing(2),
    padding: spacing(2),
    minWidth: 200,
  },
  regular: {
    color: palette.secondary.main,
    fontSize: '0.9375rem',
    minWidth: 130,
    paddingLeft: spacing(2),
    paddingTop: 7,
    paddingBottom: 7,
    paddingRight: spacing(2),
  },
  success: {
    backgroundColor: palette.primary.light,
  },
  successMsg: {
    color: palette.primary.light,
  },
}));

/////////////////////////////
// ASYNC BUTTON COMPONENT
/////////////////////////////
// TODO: rewrite to use only `disabled` property instead of `isDisabled`
const AsyncButton = props => {
  const classes = useStyles();

  const {
    children,
    textButton,
    isDisabled,
    isLoading,
    isSuccess,
    loadingButton,
    successButton,
    successMsg,
    onClick,
    type,
    nativeType,
    variant,
    ...other
  } = props;

  const standBy = textButton ? textButton : children ? children : 'Submit';
  const success = successButton ? successButton : 'Submitted';
  const loading = loadingButton ? loadingButton : 'Submitting...';

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      style={{ textAlign: 'right' }}
      width="100%"
    >
      {isSuccess && successMsg && (
        <Typography component={'span'} className={classes.successMsg}>
          {successMsg}
        </Typography>
      )}

      <Button
        className={clsx(type === 'small'
          ? classes.small
          : type === 'regular'
          ? classes.regular
          : classes.submit,
          props.classes ? props.classes : '')
        }
        color="secondary"
        disabled={isDisabled || isLoading}
        disableElevation={isSuccess}
        disableRipple
        endIcon={props.endIcon ? props.endIcon : false}
        onClick={onClick}
        variant={variant ? variant : 'contained'}
        type={nativeType || type}
        {...other}
      >
        {isLoading
          ? loading
          : isSuccess
          ? success
          : standBy}
      </Button>
    </Box>
  );
};

AsyncButton.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool,
  children: PropTypes.node,
  textButton: PropTypes.string,
  endIcon: PropTypes.node,
  errorButton: PropTypes.string,
  loadingButton: PropTypes.string,
  successButton: PropTypes.string,
  successMsg: PropTypes.string,
  onClick: PropTypes.func,
  classes: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  nativeType: PropTypes.string,
  variant: PropTypes.string,
};

export default AsyncButton;
