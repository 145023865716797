import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  TableSortLabel,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(({ spacing, typography }) => ({
  tableHeader: {
    ...typography.h5,
    color: 'rgba(114, 115, 131, 0.8)',
    minWidth: 100,
  },
  dense: {
    color: 'rgba(114, 115, 131, 0.8)',
    fontSize: '.8rem',
    lineHeight: 'normal',
  },
  blankCell: {
    maxWidth: 40,
    width: 40,
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
}));

const SortableTableHead = ({
  blankColumn,
  dense,
  headCells,
  isSelectAll,
  order,
  orderBy,
  onRequestSort,
  onSelectAll,
}) => {
  const classes = useStyles();
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {blankColumn === 'start' &&
          <TableCell className={classes.blankCell} />}

        <TableCell padding={dense ? 'none' : 'checkbox'}>
          <Checkbox
            checked={isSelectAll}
            size="small"
            onChange={onSelectAll}
            inputProps={{ 'aria-label': 'select all campaigns' }}
          />
        </TableCell>

        {headCells.map(cell => (
          <TableCell
            key={cell.id}
            className={dense ? classes.dense : classes.tableHeader}
            align={cell.align ? cell.align : 'center'}
            padding={cell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === cell.id ? order : false}
          >
            {!cell.sortable ? cell.label : (
              <TableSortLabel
                active={orderBy === cell.id}
                direction={orderBy === cell.id ? order : 'asc'}
                onClick={createSortHandler(cell.id)}
              >
                {cell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}

        {blankColumn === 'end' &&
          <TableCell className={classes.blankCell} />}
      </TableRow>
    </TableHead>
  );
};

SortableTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  isSelectAll: PropTypes.bool,
  blankColumn: PropTypes.string,
  dense: PropTypes.bool,
};

export default SortableTableHead;
