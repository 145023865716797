import { useState } from 'react';

import { useAPI } from '../hooks/api';

export const useReports = () => {
  const { useGet } = useAPI();

  const [embedUrl, setEmbedUrl] = useState(null);

  async function getLookerEmbedUrl() {
    try {
      const res = await useGet('/looker-reporting-embed-url');

      if (res) {
        console.log('res from embed url', res);
        setEmbedUrl(res.embedUrl);
      }

      return res;
    } catch (error) {
      console.log('error in getting embed url', error);
      return error;
    }
  }

  async function getP2PEmbedUrl() {
    try {
      const res = await useGet('/looker-reporting-embed-url/P2P');

      if (res) {
        console.log('res from embed url', res);
        setEmbedUrl(res.embedUrl);
      }

      return res;
    } catch (error) {
      console.log('error in getting embed url', error);
      return error;
    }
  }

  async function getNBCUP2PEmbedUrl() {
    try {
      const res = await useGet('/looker-reporting-embed-url/NBCU_P2P');

      if (res) {
        console.log('res from embed url', res);
        setEmbedUrl(res.embedUrl);
      }

      return res;
    } catch (error) {
      console.log('error in getting embed url', error);
      return error;
    }
  }

  async function getQsEmbedUrl() {
    try {
      const response = await useGet(`${process.env.API_URL}/v1/reporting-embed-url/`, true);
      setEmbedUrl(response.embedUrl);
      return response;
    } catch (error) {
      console.error('Error getting reporting', error);
      return error;
    }
  }

  async function getIncrementalityEmbedUrl() {
    try {
      const response = await useGet(
        `${process.env.API_URL}/v1/looker-reporting-embed-url/INCREMENTALITY/`,
        true
      );

      setEmbedUrl(response.embedUrl);
      return response;
    } catch (error) {
      console.error('Error getting reporting', error);
      return error;
    }
  }

  async function getNBCUIncrementalityEmbedUrl() {
    try {
      const response = await useGet(
        `${process.env.API_URL}/v1/looker-reporting-embed-url/NBCU_INCREMENTALITY/`,
        true
      );

      setEmbedUrl(response.embedUrl);
      return response;
    } catch (error) {
      console.error('Error getting reporting', error);
      return error;
    }
  }

  const handleLookerMessage = event => {
    console.log('looker message', event);

    if (event.source === document.getElementById('looker').contentWindow) {
      if (event.origin === 'https://tvscientific.looker.com') {
        console.log(JSON.parse(event.data));
      }
    }
  };

  return {
    embedUrl,
    getIncrementalityEmbedUrl,
    getNBCUIncrementalityEmbedUrl,
    getLookerEmbedUrl,
    getP2PEmbedUrl,
    getNBCUP2PEmbedUrl,
    getQsEmbedUrl,
    handleLookerMessage,
  };
};
