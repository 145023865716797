import { useContext, useEffect, useReducer, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';

import AdvertiserContext from '../AdvertiserContext';
import { useAPI } from './api';
import { useDimensions } from './dimensions';

const imageUrl = `${process.env.API_URL}/v1/image_assets/`;
const videoUrl = `${process.env.API_URL}/v1/video_assets/`;
const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
// Max file size limit is 100 mb
const fileMax = 250000000;

const formatFileName = (file) =>
  file && file.trim().replace(/[^a-z0-9.]/gi, '_');

const formatUploadSize = (num) => {
  const exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1024)),
    units.length - 1
  );
  const size = (num / Math.pow(1024, exponent)).toFixed(2) * 1;
  const unit = units[exponent];

  return `${size} ${unit}`;
};

export const useUpload = (props) => {
  const {
    uploadMetadataState = useState([]),
  } = props || {};
  const [uploadMetadata, setUploadMetadata] = uploadMetadataState;

  const adContext = useContext(AdvertiserContext);
  const { oktaAuth } = useOktaAuth();
  const { useDelete } = useAPI();
  const { dimensions } = useDimensions();

  const [isUploading, setIsUploading] = useState(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [progressBars, setProgressBars] = useState({});
  const [uploadError, setUploadError] = useState(null);
  const [invalidFiles, setInvalidFiles] = useState([]);

  const uploadsReducer = (state, action) => {
    switch (action.type) {
      case 'start':
        return [...state, action.payload];
      case 'end':
        return state.filter(s => s !== action.payload);
      case 'error':
      default:
        return state;
    }
  };

  const [uploads, dispatch] = useReducer(uploadsReducer, []);

  useEffect(() => {
    if (uploads.length > 0) {
      setIsUploading(true);
    }

    if (uploads.length === 0) {
      setIsUploading(false);
    }
  }, [uploads]);

  useEffect(() => {
    if (invalidFiles.length > 0) {
      invalidFiles.forEach(i => {
        setUploadError(
          `${i.name || i.fileName || i.path || null} has invalid width x height combination: ${i.width}x${i.height}. If you are uploading other files, do not leave this page.`
        );

        setUploadMetadata(uploadMetadata.filter(p => {
          const name = p.name ? p.name : p.fileName;
          return name !== i.path;
        }));

        delete progressBars[i.path];
      });
    }
  }, [invalidFiles])

  const handleCheckDimensions = (width, height) =>
    dimensions.some(
      d => d.creative_width === width && d.creative_height === height
    );

  const fetchDimensions = image => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = reject;

      const reader = new FileReader();

      reader.onloadend = function(ended) {
        img.src = ended.target.result;
      }

      reader.readAsDataURL(image);
    });
  };

  // Decorate progress function and set progress values
  const handleProgressUpload = (fileName) => (progressEvent) => {
    const total = progressEvent.lengthComputable
      ? progressEvent.total
      : progressEvent.target
      ? progressEvent.target.getResponseHeader('content-length') ||
        progressEvent.target.getResponseHeader('x-decompressed-content-length')
      : null;

    if (total !== null) {
      setProgressBars((prev) => ({
        ...prev,
        [fileName]: Math.round((progressEvent.loaded * 100) / total),
      }));
    }
  };

  const deleteFile = (url) =>
    useDelete(url)
      .then((response) => {
        console.log('response from delete file', response);
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });

  // TODO: Abstract upload logic into single function
  // that dynamically reads file type of upload endpoint
  const uploadDisplay = files => {
    setIsUploadSuccess(false);

    const nextUploadMetadata = [...uploadMetadata];

    const uploadPromises = files.map(async file => {
      // Stop upload if file size exceed 100mb
      if (file.size && file.size > fileMax) {
        setUploadError(`File size cannot exceed 100 MB`);
        return;
      }

      const { width, height } = await fetchDimensions(file);
      if (!(handleCheckDimensions(width, height))) {
        setInvalidFiles((prev) => [...prev, { ...file, width, height }]);
        return;
      }

      const uploadOptions = {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${oktaAuth.getAccessToken()}`,
          'Content-Type': 'multipart/form-data',
          'X-TVS-AdvertiserContext': adContext.id,
        },
        onUploadProgress: handleProgressUpload(formatFileName(file.name)),
      };

      const currentTime = Date.now();
      const fileName = formatFileName(file.name);
      const newKey = `metadata-${uploadMetadata.length}${currentTime}`;
      const newDisplay = {
        key: newKey,
        title: file.name,
        fileName: file.name,
        fileSize: formatUploadSize(file.size),
        uploadable: true,
      };

      dispatch({ type: 'start', payload: fileName });

      setProgressBars(prev => ({
        ...prev,
        [fileName]: 100,
      }));

      nextUploadMetadata.push(newDisplay);

      const formData = new FormData();

      formData.append('file', file);
      formData.append('name', fileName);
      formData.append('advertiser', adContext.url);
      formData.append('media_type', file.type);
      formData.append('active', true);

      return axios.post(imageUrl, formData, uploadOptions);
    });

    setUploadMetadata(nextUploadMetadata);

    return Promise.all(uploadPromises)
      .then(responses => {
        const nextResponses = responses.filter(r => r !== null);
        console.log('responses', nextResponses);
        setIsUploadSuccess(true);
        setIsUploading(false);

        nextResponses.forEach(r => {
          if (r && r.data && r.data.name) {
            dispatch({ type: 'end', payload: r.data.name });
          }
        });

        return nextResponses;
      })
      .catch(error => {
        console.log('Error in uploadDisplay', error);
        setIsUploading(false);

        // TODO: dispatch a dynamic payload
        dispatch({ type: 'error' });

        return error;
      });
  };

  const uploadCreative = (files) => {
    setIsUploadSuccess(false);

    const nextUploadMetadata = [...uploadMetadata];

    const uploadPromises = files.map((file) => {
      // Stop upload if file size exceed 100mb
      if (file.size && file.size > fileMax) {
        setUploadError(`File size cannot exceed 100 MB`);
        return;
      }

      const uploadOptions = {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${oktaAuth.getAccessToken()}`,
          'Content-Type': 'multipart/form-data',
          'X-TVS-AdvertiserContext': adContext.id,
        },
        onUploadProgress: handleProgressUpload(formatFileName(file.name)),
      };

      // TODO use FileReader API to calculate duration

      const currentTime = Date.now();
      const fileName = formatFileName(file.name);
      const newKey = `metadata-${uploadMetadata.length}${currentTime}`;
      const newCreative = {
        key: newKey,
        title: file.name,
        fileName: file.name,
        fileSize: formatUploadSize(file.size),
        duration: null,
        resolution: 'Calculating...',
        uploadable: true,
      };

      dispatch({ type: 'start', payload: fileName });

      setProgressBars((prev) => ({
        ...prev,
        [fileName]: 100,
      }));

      nextUploadMetadata.push(newCreative);

      const formData = new FormData();

      formData.append('file', file);
      formData.append('name', fileName);
      formData.append('advertiser', adContext.url);
      formData.append('media_type', file.type);

      return axios.post(videoUrl, formData, uploadOptions);
    });

    setUploadMetadata(nextUploadMetadata);

    return Promise.all(uploadPromises)
      .then((responses) => {
        setIsUploadSuccess(true);

        responses.forEach((r) => {
          if (r && r.data && r.data.name) {
            dispatch({ type: 'end', payload: r.data.name });
          }
        });

        return responses;
      })
      .catch((error) => {
        console.error(error);

        // TODO: dispatch a dynamic payload
        dispatch({ type: 'error' });
      });
  };

  return {
    formatFileName,
    isUploading,
    setIsUploading,
    isUploadSuccess,
    setIsUploadSuccess,
    progressBars,
    setProgressBars,
    deleteFile,
    uploadMetadata,
    uploadError,
    setUploadError,
    setUploadMetadata,
    uploadCreative,
    uploadDisplay,
  };
};
