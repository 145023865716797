import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import ModalFooter from './ModalFooter';
import LoadingSpinner from './ui/LoadingSpinner';
import ToggleSwitch from './ui/ToggleSwitch';
import { useAPI } from './hooks/api';
import { useLoader } from './hooks/loader';
import { useCampaigns } from './hooks/campaigns';
import { Statuses } from './containers/AdGroupsIndexPage/statuses';
import { getStatus } from './containers/AdGroupsIndexPage/getStatus';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  active: {
    color: '#0fbf84',
    background: 'rgba(122, 220, 187, 0.55)',
    minWidth: 67,
  },
  paused: {
    color: '#ff0000',
    background: 'rgba(255, 0, 0, 0.31)',
    minWidth: 67,
  },
  adGroups: {
    marginBottom: spacing(2),
    position: 'relative',
  },
  budget: {
    textAlign: 'right',
  },
  campaign: {
    marginBottom: spacing(8),
  },
  holdOut: {
    order: -3,
  },
  toggle: {
    order: -2,
  },
  current: {
    order: -1,
  },
  dialog: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  editing: {
    position: 'absolute',
    top: 16,
    bottom: 0,
    left: -32,
    right: 0,
    margin: 'auto 0',
    pointerEvents: 'none',
  },
  info: {
    color: palette.primary.light,
    // marginRight: spacing(1),
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: spacing(2),
  },
  totalCopy: {
    ...typography.paragraph,
    fontSize: '.75rem',
  },
  draft: {
    color: '#EB9D00',
    background: 'rgba(255, 171, 5, 0.25)',
  },
}));

const ManageBudget = props => {
  const classes = useStyles();
  const { useGetAll, usePatch } = useAPI();
  const { isLoading, setIsLoading } = useLoader();
  const {
    isLoading: isFetching,
    setIsLoading: setIsFetching,
  } = useLoader(true);

  const { campaign } = useCampaigns();

  const {
    adGroup,
    currentCampaign,
    isModal,
    isNew,
    isNewDisplay,
    isOpen,
    onClose,
    setCampaignBudget,
    setCampaignHoldOut,
    setHasSaved,
    setIsBudgetError,
  } = props;

  const { incremental } = campaign;

  const initialBudget = () =>
    parseFloat(currentCampaign.daily_budget, 10).toFixed(2);

  const initialEstimate = () =>
    parseFloat(currentCampaign.daily_budget, 10) * 25;

  const initialAllocations = () => isNew
    ? { '00-new': 0 }
    : {};

  const [adGroups, setAdGroups] = useState([]);
  const [allocations, setAllocations] = useState(initialAllocations());
  const [budget, setBudget] = useState(initialBudget());
  const [isDollar, setIsDollar] = useState(false);
  const [displays, setDisplays] = useState([]);
  const [dollars, setDollars] = useState({});
  const [estimate, setEstimate] = useState(initialEstimate());
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchLineitems = async () => {
      await getAdGroups();
      await getDisplays();

      setIsFetching(false);
    };

    fetchLineitems();
  }, []);

  useEffect(() => {
    Object.keys(dollars).forEach(k => {
      setDollars(prev => ({
        ...prev,
        [k]: (allocations[k] * budget / 100).toFixed(2),
      }));
    });

    // Observe budget to calc estimate
    setEstimate(handleEstimate(budget));

    // Set external campaign budgets
    if (setCampaignBudget) {
      setCampaignBudget(budget);
    }
  }, [budget]);

  useEffect(() => {
    if (
      currentCampaign &&
      currentCampaign.experiment_type &&
      setCampaignHoldOut
    ) {
      incremental.setHoldOut(currentCampaign.experiment_type);
    }
  }, []);

  useEffect(() => {
    if (setCampaignHoldOut) {
      setCampaignHoldOut(incremental.holdOut);
    }
  }, [incremental.holdOut]);

  useEffect(() => {
    if (!isDollar) {
      Object.keys(allocations).forEach(k => {
        setDollars(prev => ({
          ...prev,
          [k]: (allocations[k] * budget / 100).toFixed(2),
        }));
      });
    }
  }, [allocations]);

  useEffect(() => {
    if (isDollar) {
      Object.keys(dollars).forEach(k => {
        setAllocations(prev => ({
          ...prev,
          [k]: (dollars[k] / budget * 100).toFixed(4),
        }));
      });
    }

    handleSyncBudgets();

    // Sets daily budget for new Ad Group
    if (props.setDailyBudget && !isNewDisplay) {
      props.setDailyBudget(dollars['00-new']);
    }

    if (props.setDailyBudget && isNewDisplay) {
      props.setDailyBudget(dollars['display-00-new']);
    }
  }, [dollars]);

  useEffect(() => {
    const spend = Object.values(dollars);
    const total = spend.reduce((a, b) =>
      parseFloat(a, 10) + parseFloat(b, 10),
      0
    );

    if (total > budget) {
      setIsError(true);

      if (setIsBudgetError) {
        setIsBudgetError(true);
      }
    } else {
      setIsError(false);

      if (setIsBudgetError) {
        setIsBudgetError(false);
      }
    }
  }, [dollars]);

  function getAdGroups() {
    return useGetAll('/lineitems', [], total => {
      const filtered = total
      .filter(t => t.campaign === currentCampaign.url)
      .map(li => ({
        ...li,
        status: getStatus(li.active, li.pending_active, li.draft)
      }));

      filtered.forEach((f) => {
        handleUpdateBudgets(f);
      });

      setAdGroups(prev => [...prev, ...filtered]);

      if (props.setAdGroups) {
        props.setAdGroups(prev => [...prev, ...filtered]);
      }
    });
  }

  function getDisplays() {
    return useGetAll('/static_display_lineitems', [], total => {
      const filtered = total
      .filter(t => t.campaign === currentCampaign.url)
      .map(li => ({
        ...li,
        status: getStatus(li.active, li.pending_active, li.draft)
      }));

      filtered.forEach((f) => {
        handleUpdateBudgets(f, true);
      });

      setDisplays(filtered);
    });
  }

  const handleEstimate = amount => {
    const calc = parseFloat(amount, 10) * 25;
    return isNaN(calc) ? 0 : calc;
  };

  const handleIsActive = (status, oldBudget, allocation) => {
    const budget = parseFloat(oldBudget);
    const isAllocated = parseFloat(allocation) > 0;

    let isActive = status;

    if (budget === 0 && isAllocated) {
      isActive = true;
    }

    if (!status && budget > 0) {
      isActive = false;
    }

    if (budget > 0 && !isAllocated) {
      isActive = false;
    }

    return isActive;
  };

  const handleUpdateBudgets = (adGroup, isDisplay) => {
    const groupId = adGroup && adGroup.id ? adGroup.id : '00-new';
    const id = isDisplay ? `display-${groupId}` : groupId;

    setAllocations(prev => ({
      ...prev,
      [id]: (adGroup.daily_budget / budget * 100).toFixed(4),
    }));

    setDollars(prev => ({
      ...prev,
      [id]: adGroup.daily_budget,
    }));
  };

  const handleAllocation = (value, id, isDisplay) => {
    const allocationId = isDisplay ? `display-${id}` : id;

    setAllocations(prev => ({
      ...prev,
      [allocationId]: parseFloat(value),
    }));
  };

  const handleDollar = (value, id, isDisplay) => {
    const dollarId = isDisplay ? `display-${id}` : id;

    setDollars(prev => ({
      ...prev,
      [dollarId]: parseFloat(value, 10),
    }));
  };

  const handleIsDollar = event => {
    setIsDollar(event.target.checked);
  };

  const handleEditBudget = event => {
    setBudget(event.target.value);
  };

  const handleHoldOut = event => {
    const holdOuts = {
      '1PCT_HOLDOUT': [0.99, 0.01],
      '5PCT_HOLDOUT': [0.95, 0.05],
      '10PCT_HOLDOUT': [0.9, 0.1],
      '20PCT_HOLDOUT': [0.8, 0.2],
      '25PCT_HOLDOUT': [0.75, 0.25],
      '50PCT_HOLDOUT': [0.5, 0.5],
    }

    Object.keys(dollars).forEach((k, i) => {
      setDollars(prev => ({
        ...prev,
        [k]: (budget * holdOuts[event.target.value][i]).toFixed(2),
      }));

      setAllocations(prev => ({
        ...prev,
        [k]: (holdOuts[event.target.value][i] * 100).toFixed(4),
      }));
    });
  };

  const handleSyncBudgets = () => {
    setAdGroups(prev => {
      const budgeted = prev.map(a => {
        const allocation = dollars[a.id];
        const isActive = handleIsActive(a.active, a.daily_budget, allocation);

        return ({
          ...a,
          active: isActive,
          daily_budget: allocation,
        })
      });

      if (props.setAdGroups) {
        props.setAdGroups(budgeted.map(a => ({
          active: a.active,
          id: a.id,
          daily_budget: a.daily_budget,
          is_control_group: a.is_control_group,
        })));
      }

      return prev;
    });

    setDisplays(prev => {
      const budgeted = prev.map(a => {
        const allocation = dollars[`display-${a.id}`];
        const isActive = handleIsActive(a.active, a.daily_budget, allocation);

        return ({
          ...a,
          active: isActive,
          daily_budget: dollars[`display-${a.id}`],
        })
      });

      if (props.setDisplays) {
        props.setDisplays(budgeted.map(a => ({
          active: a.active,
          id: a.id,
          daily_budget: a.daily_budget,
          is_control_group: a.is_control_group,
        })));
      }

      return prev;
    });
  };

  const handleAdGroupBudgets = () => {
    const adGroupRequests = adGroups.map(a => {
      console.log('adGroup from other func', a)
      const isActive = handleIsActive(
        a.active,
        a.daily_budget,
        dollars[a.id]
      );

      return usePatch(`lineitems/${a.id}`, {
        active: isActive,
        daily_budget: dollars[a.id],
      })
    });

    const displayRequests = displays.map(a => {
      const isActive = handleIsActive(
        a.active,
        a.daily_budget,
        dollars[`display-${a.id}`]
      );

      return usePatch(`static_display_lineitems/${a.id}`, {
        active: isActive,
        daily_budget: dollars[`display-${a.id}`]
      })
    });

    return Promise.all([...adGroupRequests, ...displayRequests])
      .then(responses => {
        console.log('Res from adGroups budgets', responses);
        return responses;
      })
      .catch(error => {
        console.log('Error saving budgets in AdGroups', error);
      });
  };

  const handleSaveBudget = () => {
    // Check for changes to adGroups/displays
    let hasEdited = false;
    const ids = Object.keys(dollars);

    ids.forEach(i => {
      let adGroup = adGroups.find(a => a.id == i);

      if (!adGroup) {
        adGroup = displays.find(d => `display-${d.id}` == i);
      }

      if (adGroup.daily_budget !== dollars[i]) {
        hasEdited = true;
      }
    });

    // If no changes are made, block request
    if (
      !hasEdited &&
      budget === parseFloat(currentCampaign.daily_budget, 10).toFixed(2)
    ) {
      return;
    }

    setIsLoading(true);

    return usePatch(`/campaigns/${currentCampaign.id}`, { daily_budget: budget })
      .then(res => {
        if (res && [200, 201].includes(res.status)) {
          if (hasEdited) {
            return handleAdGroupBudgets();
          }
        }

        return res;
      })
      .then(budgets => {
        setIsLoading(false);
        setHasSaved(true);
        onClose();

        return budgets;
      })
      .catch(error => console.error('Error in saving budget', error));
  };

  const renderAllocation = (group, isDisplay) => {
    return (
      <TextField
        disabled={isDollar || !!currentCampaign.experiment_type}
        data-testid="adgroup-allocation"
        className={classes.budget}
        color="secondary"
        fullWidth
        label="Allocation"
        name="Allocation"
        onChange={event =>
          handleAllocation(event.target.value, group.id, isDisplay)
        }
        type="number"
        value={isDisplay
          ? allocations[`display-${group.id}`]
          : allocations[group.id]
        }
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">%</InputAdornment>
          ),
          inputProps: { min: 0, step: .0001 }
        }}
      />
    );
  };

  const renderDollar = (group, isDisplay) => {
    return (
      <TextField
        disabled={!isDollar || !!currentCampaign.experiment_type}
        data-testid="adgroup-dollars"
        className={classes.budget}
        color="secondary"
        fullWidth
        label="Dollars"
        name="Dollars"
        onChange={event =>
          handleDollar(event.target.value, group.id, isDisplay)
        }
        type="number"
        value={isDisplay
          ? dollars[`display-${group.id}`]
          : dollars[group.id]
        }
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">$</InputAdornment>
          ),
          inputProps: { min: 0, step: .01 },
        }}
      />
    );
  };

  const renderAdGroup = (group, isDisplay, isNewAdGroup) => (
    <Grid
      item
      container
      className={clsx(
        classes.adGroups,
        adGroup && adGroup.id && adGroup.id === group.id
          ? classes.current
          : {}
      )}
      key={group.id}
      alignItems="center"
      justify="space-between"
      xs={12}
    >
      <Grid item container spacing={2} xs={4}>
        <Grid item xs={6}>
          {renderAllocation(group, isDisplay)}
        </Grid>

        <Grid item xs={6}>
          {renderDollar(group, isDisplay)}
        </Grid>
      </Grid>

      <Grid item container xs={7}>
        <Typography className={classes.name} variant="h5">
          {adGroup && adGroup.id && adGroup.id === group.id ? adGroup.name : (group.name || '')}
        </Typography>
      </Grid>

      <Grid item container justify="center" xs={1}>
        {!isNewAdGroup &&
          <>
            {group.status === Statuses.ACTIVE && <Chip label="Active" className={classes.active} />}
            {group.status === Statuses.PENDING && <Chip label="Pending" className={classes.pending} />}
            {group.status === Statuses.PAUSED && <Chip label="Paused" className={classes.paused} />}
            {group.status === Statuses.DRAFT && (<Chip label="Draft" className={classes.draft} />)}
          </>
        }
      </Grid>

      {adGroup && adGroup.id && adGroup.id === group.id &&
        <div className={classes.editing}>
          <ArrowRightIcon color="secondary" />
        </div>}
    </Grid>
  );

  const renderIncremental = () => {
    return (
      <Box
        display="flex"
        width="100%"
        mb={3}
        alignItems="center"
        className={classes.holdOut}
      >
        <Grid
          container
          item
          xs={12}
        >
          <Grid item xs={12}>
            <Select
              color="secondary"
              defaultValue={'1PCT_HOLDOUT'}
              variant="outlined"
              labelId="incremental-dropdown"
              id="incremental-dropdown"
              value={incremental.holdOut}
              onChange={(event) => {
                incremental.setHoldOut(event.target.value);
                handleHoldOut(event);
              }}
            >
              <MenuItem value={'1PCT_HOLDOUT'}>1% hold out</MenuItem>
              <MenuItem value={'5PCT_HOLDOUT'}>5% hold out</MenuItem>
              <MenuItem value={'10PCT_HOLDOUT'}>10% hold out</MenuItem>
              <MenuItem value={'20PCT_HOLDOUT'}>20% hold out</MenuItem>
              <MenuItem value={'25PCT_HOLDOUT'}>25% hold out</MenuItem>
              <MenuItem value={'50PCT_HOLDOUT'}>50% hold out</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderContent = () => {
    return (
      <>
        {isError &&
          <Box mb={4} mt={-3}>
            <Alert severity="warning">
              Heads up! Your total Ad Group budget allocation exceeds 100% of
              your campaign budget. If you continue, your total spend will not
              exceed the campaign budget.
            </Alert>
          </Box>}

        <Grid
          container
          justify="space-between"
          alignItems="baseline"
        >
          <Grid
            item
            container
            className={classes.campaign}
            alignItems="center"
            justify="space-between"
            spacing={1}
            xs={12}
          >
            <Grid item xs={4}>
              <TextField
                data-testid="campaign-budget"
                className={classes.budget}
                color="secondary"
                fullWidth
                label="Total Daily Campaign Budget"
                onChange={handleEditBudget}
                type="number"
                value={budget}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid container item alignItems="flex-start" xs={8}>
              <Grid container alignItems="center">
                <Grid container item justify="center" xs={1}>
                  <InfoOutlinedIcon
                    className={classes.info}
                    fontSize="small"
                  />
                </Grid>

                <Grid item xs={11}>
                  <Box display="flex" flexWrap="wrap" width="100%">
                    <Typography
                      className={classes.totalCopy}
                      variant="body2"
                    >
                      Your total monthly budget is estimated to ${estimate}.
                      <br />
                      Specify distribution between the ad groups below.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            {isFetching ? (
              <Box
                width="100%"
                height={100}
                display="flex"
                style={{ position: 'relative' }}
              >
                <LoadingSpinner size={35} />
              </Box>
            ) : (
              <>
                {!!currentCampaign.experiment_type &&
                  renderIncremental()}

                <Box
                  display="flex"
                  width="100%"
                  mb={3}
                  alignItems="center"
                  className={classes.toggle}
                >
                  <Typography
                    style={{
                      fontSize: '0.775rem',
                      fontWeight: 500,
                      width: 130,
                    }}
                  >
                    Allocate budget by:
                  </Typography>

                  <ToggleSwitch
                    checked={isDollar}
                    size="small"
                    leftLabel="%"
                    rightLabel="$"
                    onChange={handleIsDollar}
                  />
                </Box>

                {isNew && !isNewDisplay && adGroup &&
                  renderAdGroup(adGroup, false, true)}

                {isNewDisplay && adGroup &&
                  renderAdGroup(adGroup, true, true)}

                {adGroups.length > 0 &&
                  adGroups.map(a => renderAdGroup(a, false))}

                {displays.length > 0 &&
                  displays.map(a => renderAdGroup(a, true))}
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  const renderWithModal = () => (
    <Dialog
      className={classes.dialog}
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="edit-budget-dialog"
    >
      <DialogTitle id="edit-budget-dialog">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pt={1}
        >
          <Typography variant="h4">
            Distribute budgets between Ad Groups
          </Typography>

          <IconButton
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <Box px={3}>
        <Divider />
      </Box>

      <DialogContent className={classes.dialog}>
        <Box p={3}>
          {renderContent()}
        </Box>
      </DialogContent>

      <DialogActions>
        <Box mt={11}>
          <ModalFooter
            isLoading={isLoading}
            onCancel={onClose}
            onSubmit={handleSaveBudget}
            text="Update Budget"
          />
        </Box>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {isModal ? renderWithModal() : renderContent()}
    </>
  );
};

ManageBudget.propTypes = {
  adGroup: PropTypes.object,
  currentCampaign: PropTypes.object,
  isModal: PropTypes.bool,
  isNew: PropTypes.bool,
  isNewDisplay: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  setAdGroups: PropTypes.func,
  setCampaignBudget: PropTypes.func,
  setCampaignHoldOut: PropTypes.func,
  setDailyBudget: PropTypes.func,
  setDisplays: PropTypes.func,
  setHasSaved: PropTypes.func,
  setIsBudgetError: PropTypes.func,
};

export default ManageBudget;
