import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useDimensions } from './hooks/dimensions';

const useStyles = makeStyles(({ spacing }) => ({
  dimensions: {
    margin: '0 auto',
    maxWidth: '60%',
    fontSize: '0.825rem',
  },
  specs: {
    marginTop: spacing(1),
  },
  supported: {
    marginBottom: 6,
  },
}));

const ImageSpecifications = () => {
  const classes = useStyles();
  const { dimensions } = useDimensions();

  return (
    <div className={clsx("Dropzone-specs", classes.specs)}>
      <p>Supported file formats: PNG and JPEG</p>

      <p className={classes.supported}>Supported dimensions:</p>

      <p className={classes.dimensions}>
        {dimensions && dimensions.length > 0 &&
          dimensions.map((d, i) =>
            i === dimensions.length - 1
              ? d.creative_size_name
              : `${d.creative_size_name}, `
          )}
      </p>

      <p>Max File Size: 100MB</p>
    </div>
  );
};

export default ImageSpecifications;
