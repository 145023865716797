import React, { Fragment, useEffect, useState, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment-timezone';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import SearchIcon from '@material-ui/icons/Search';
import MiddleEllipsis from 'react-middle-ellipsis';

import AdvertiserContext from './AdvertiserContext';
import SortableTableHead from './ui/SortableTableHead';
import { useAPI } from './hooks/api';
import { useLoader } from './hooks/loader';
import { useSort } from './hooks/sort';
import checkIcon from '../images/icon-check--blue.png';
import { Themes } from '../constants';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  action: {
    width: 115,
  },
  adGroup: {
    color: '#727383',
    fontWeight: 600,
    marginTop: 0,
  },
  adGroups: {
    border: 'none',
    padding: spacing(2),
    paddingTop: 0,
    width: 160,
  },
  adGroupCell: {
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 160,
  },
  adGroupHeader: {
    minWidth: 130,
  },
  assigned: {
    color: palette.secondary.main,
  },
  assignedRow: {
    background: '#0000000a',
    opacity: .9,
  },
  check: {
    width: '100%',
    marginLeft: -10,
  },
  expanded: {
    border: 'none',
    padding: 0,
  },
  fileSize: {
    minWidth: 100,
  },
  iconCell: {
    maxWidth: 40,
    width: 40,
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
  libraryHeader: {
    marginBottom: spacing(4),
  },
  name: {
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 220,
  },
  play: {
    marginTop: 4,
  },
  na: {
    fontWeight: 500,
  },
  none: {
    color: '#727383',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 375,
    alignItems: 'center',
  },
  tableCell: {
    minWidth: 120,
    minHeight: 81,
    height: 81,
  },
  table: {
    maxHeight: 375,
    minHeight: 375,
  },
  unassigned: {
    color: '#e57373',
  },
}));

const columns = [
  { id: 'id', label: 'ID', align: 'left', sortable: true, class: '' },
  { id: 'name', label: 'Creative', align: 'left', sortable: true, class: '' },
  { id: 'adGroups', label: 'Ad groups', align: 'left', sortable: false, class: 'adGroupHeader' },
  { id: 'resolution', label: 'Resolution', align: 'center', sortable: false, class: '' },
  { id: 'fileSize', label: 'File size', align: 'center', sortable: false, class: 'fileSize' },
  { id: 'media_upload_date', align: 'center', label: 'Date created', sortable: true, class: '' },
  { id: 'action', label: 'Action', align: 'center', sortable: false, class: '', disablePadding: true },
];

const CreativeLibrary = ({
  assigned,
  handleBatchAssign,
  handleUnassign,
  setCurrentPreview,
  setIsPreview,
}) => {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);
  const { useGetAll } = useAPI();
  const { isLoading, setIsLoading } = useLoader();
  const {
    order,
    orderBy,
    handleRequestSort,
    getComparator,
    stableSort
  } = useSort();

  const [creatives, setCreatives] = useState([]);
  const [cachedCreatives, setCachedCreatives] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [search, setSearch] = useState('');

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    getCreatives();
  }, []);

  function getCreatives() {
    setIsLoading(true);

    return useGetAll('/creatives', [], total => {
      setCachedCreatives(prev => [...prev, ...total]);
      setCreatives(prev => [...prev, ...total]);
      setIsLoading(false);
    });
  }

  const handleAssignSelected = () => {
    if (selected.length > 0) {
      handleBatchAssign(
        selected.map(s => cachedCreatives.find(c => c.id === s))
      );

      setSelected([]);
      setIsSelectAll(false);
    }
  };

  const handleToggleAssign = video => {
    const hasCreative = assigned.some(a => a.url === video.url);

    if (hasCreative) {
      handleUnassign(video);
    } else {
      handleBatchAssign([video]);
      setSelected(prev => prev.filter(p => p.url === video.url));
    }
  };

  const handleExpand = id => {
    if (expanded.includes(id)) {
      setExpanded(prev => prev.filter(p => p !== id));
    } else {
      setExpanded(prev => [...prev, id]);
    }
  };

  const handleSearchFilter = value => {
    setSearch(value);

    if (value !== '') {
      setCreatives(() =>
        cachedCreatives.filter(
          p => p.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        )
      );
    } else {
      setCreatives(cachedCreatives);
    }
  };

  const handleSelected = id => {
    if (selected.includes(id)) {
      setSelected(prev => prev.filter(p => p !== id));
    } else {
      setSelected(prev => [...prev, id]);
    }
  };

  const handleSelectAll = event => {
    const { checked } = event.target;

    setIsSelectAll(checked);

    if (checked) {
      setSelected(() =>
        creatives
          .filter(c => !assigned.some(a => a.url === c.url))
          .map(c => c.id)
      );
    } else {
      setSelected([]);
    }
  };

  const handlePreview = creative => {
    setCurrentPreview(creative);
    setIsPreview(true);
  };

  const renderMegaBytes = bytes => {
    return `${(parseInt(bytes, 10) / 1000000).toFixed(2)} MB`;
  };

  const renderLibraryHeader = () => (
    <Grid
      className={classes.libraryHeader}
      container
      alignItems="center"
      justify="space-between"
    >
      <Grid item xs={6}>
        <Typography variant="h2">Creative</Typography>

        {adContext.theme === Themes.NBCU && (
          <p>
            Select the checkbox next to a previously saved creative to assign it to your campaign.
          </p>
        )}
      </Grid>

      <Grid
        container
        item
        alignItems="center"
        justify="space-between"
        spacing={2}
        xs={6}
      >
        <Grid item xs={9}>
          <TextField
            color="secondary"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={event => handleSearchFilter(event.target.value)}
            margin="dense"
            placeholder="Search creative"
            value={search}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <Button
            disabled={selected.length === 0}
            color="secondary"
            onClick={handleAssignSelected}
            startIcon={<PlayCircleFilledIcon />}
            variant="contained"
          >
            Assign
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderRow = creative => {
    const isAssigned = assigned.findIndex(a => a.url === creative.url) > -1;
    const isExpanded = expanded.includes(creative.id);
    const isSelected = selected.includes(creative.id);
    const labelId = `creative-${creative.id}`;

    const { lineitem_set: adGroups } = creative;

    return (
      <Fragment key={creative.id}>
        <TableRow
          className={clsx(
            isExpanded ? classes.root : '',
            isAssigned ? classes.assignedRow : ''
          )}
        >
          <TableCell className={classes.iconCell}>
            {adGroups && adGroups.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => handleExpand(creative.id)}
              >
                {isExpanded ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            )}
          </TableCell>

          <TableCell className={classes.height} padding="none">
            {!isAssigned ? (
              <Checkbox
                checked={isSelected}
                disabled={isAssigned}
                inputProps={{ 'aria-labelledby': labelId }}
                onChange={() => handleSelected(creative.id)}
                size="small"
              />
            ) : (
              <Box
                display="flex"
                width={18}
                alignItems="center"
                justifyContent="center"
                my={0}
                mx="auto"
              >
                <img
                  src={checkIcon}
                  className={classes.check}
                />
              </Box>
            )}
          </TableCell>

          <TableCell>{creative.id}</TableCell>

          <TableCell component="th" id={labelId} padding="none" scope="row">
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <IconButton
                  onClick={() => handlePreview(creative)}
                  size="small"
                >
                  <PlayCircleFilledIcon color="secondary" />
                </IconButton>
              </Grid>

              <Grid className={classes.name} item xs>
                <Tooltip title={creative.name}>
                  <div>
                    <MiddleEllipsis>
                      <span>
                        {creative.name}
                      </span>
                    </MiddleEllipsis>
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </TableCell>

          <TableCell
            className={classes.adGroupCell}
            component="th"
            scope="row"
            align="left"
          >
            {creative.lineitem_set.length > 0 ? (
              `${creative.lineitem_set.length} Ad group${creative.lineitem_set.length > 1 ? 's' : ''
              }`
            ) : (
              <span className={classes.na}>Not Assigned</span>
            )}
          </TableCell>

          <TableCell align="center">
            {creative.resolution ? creative.resolution : 'n/a'}
          </TableCell>

          <TableCell className={classes.fileSize} align="center">
            {creative.file_size_bytes
              ? renderMegaBytes(creative.file_size_bytes)
              : 'n/a'}
          </TableCell>

          <TableCell align="center">
            {creative.media_upload_date ? moment(creative.media_upload_date).format('l') : 'n/a'}
          </TableCell>

          <TableCell align="center" className={classes.action}>
            <Button
              className={isAssigned ? classes.unassigned : classes.assigned}
              disableRipple
              onClick={() => handleToggleAssign(creative)}
            >
              {isAssigned ? 'UNASSIGN' : 'ASSIGN'}
            </Button>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes.expanded} />
          <TableCell className={classes.expanded} />
          <TableCell className={classes.expanded} />

          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <TableCell className={classes.adGroups}>
              {adGroups &&
                adGroups.length > 0 &&
                adGroups.map(a => (
                  <p key={a.id} className={classes.adGroup}>
                    {a.name}
                  </p>
                ))}
            </TableCell>
          </Collapse>
        </TableRow>
      </Fragment >
    );
  };

  return (
    <Box className={classes.container}>
      {/* Creatives Library Header */}
      {renderLibraryHeader()}

      {/* Creatives Library Table */}
      {isLoading ? (
        <Box className={classes.spinner}>
          <CircularProgress color="secondary" />
        </Box>
      ) : creatives.length === 0 ? (
        <Box className={classes.spinner}>
          <Typography className={classes.none}>No results</Typography>
        </Box>
      ) : (
        <Fade in={!isLoading}>
          <TableContainer className={classes.table}>
            <Table stickyHeader>
              <SortableTableHead
                dense
                blankColumn="start"
                headCells={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                onSelectAll={handleSelectAll}
                isSelectAll={isSelectAll}
              />

              <TableBody>
                {stableSort(
                  creatives,
                  getComparator(order, orderBy)
                ).map((c, index) => renderRow(c, index))}
              </TableBody>
            </Table>
          </TableContainer>
        </Fade>
      )}
    </Box>
  );
};

CreativeLibrary.propTypes = {
  assigned: PropTypes.array,
  handleBatchAssign: PropTypes.func,
  handleUnassign: PropTypes.func,
  setCurrentPreview: PropTypes.func,
  setIsPreview: PropTypes.func,
};

export default memo(CreativeLibrary);
