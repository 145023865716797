import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  label: {
    marginLeft: 0,
    marginRight: 0,
  }
}));

const CustomSwitch = withStyles({
  switchBase: {
    color: '#1dafff',

    '&$checked': {
      color: '#1dafff',
    },

    '&$checked + $track': {
      backgroundColor: '#9aa0a6',
    },
  },
  checked: {},
  track: {
    backgroundColor: '#9aa0a6',
  },
})(Switch);

const ToggleSwitch = props => {
  const classes = useStyles();

  return (
    <Grid component="label" container alignItems="center" item xs={12}>
      <Grid item>
        <Typography
          variant="body2"
          style={{
            fontSize: '.75rem',
            color: '#47505d',
            opacity: props.checked ? .6 : 1,
          }}
        >
          {props.leftLabel}
        </Typography>
      </Grid>

      <Grid item>
        <FormControlLabel
          className={classes.label}
          control={
            <CustomSwitch
              disableRipple
              edge={props.edge}
              checked={props.checked}
              size={props.size}
              onChange={props.onChange}
            />
          }
          label={
            <Typography
              style={{ fontSize: '.75rem', color: '#47505d'}}
              variant="body2"
            >
              {props.label}
            </Typography>
          }
          labelPlacement={props.labelPlacement ? props.labelPlacement : 'end'}
        />
      </Grid>

      <Grid item>
        <Typography
          variant="body2"
          style={{
            fontSize: '.75rem',
            color: '#47505d',
            opacity: props.checked ? 1 : .6,
          }}
        >
          {props.rightLabel}
        </Typography>
      </Grid>
    </Grid>
  )
};

ToggleSwitch.propTypes = {
  checked: PropTypes.bool,
  edge: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
};

export default ToggleSwitch;
