import { useState } from 'react';

export const useLoader = (init = false) => {
  const [isLoading, setIsLoading] = useState(init);
  const [isSuccess, setIsSuccess] = useState(false);

  return {
    isLoading,
    isSuccess,
    setIsLoading,
    setIsSuccess,
  };
};
