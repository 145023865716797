import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import {
  Box,
  Container,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import AdvertiserContext from '../AdvertiserContext';
import AppHeader from '../AppHeader';
import { useAPI } from '../hooks/api';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: 'none',
  },
  paper: {
    padding: theme.spacing(5),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  select: {
    width: 200,
  },
}));

const QUICKSIGHT_ALL = '[ALL]';

const PathToPurchasePage = props => {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);
  const qsContainer = useRef(null);
  const { useGet } = useAPI();

  const [campaigns, setCampaigns] = useState([]);
  const [campaignId, setCampaignId] = useState(QUICKSIGHT_ALL);
  const [embedUrl, setEmbedUrl] = useState(null);
  const [qsDashboard, setQsDashboard] = useState({});
  const [qsParams, setQsParams] = useState({
    campaignId: QUICKSIGHT_ALL,
  });

  useEffect(() => {
    if (adContext.id) {
      getEmbedUrl();
      getCampaigns();
    }
  }, [adContext]);

  useEffect(() => {
    if (embedUrl) {
      handleQsEmbed(embedUrl);
    }
  }, [embedUrl]);

  useEffect(() => {
    if (
      qsParams &&
      qsDashboard &&
      qsParams.campaignId &&
      qsDashboard.setParameters
    ) {
      qsDashboard.setParameters(qsParams);
    }
  }, [qsParams]);

  const handleQsEmbed = async (embedUrl) => {
    const qsOptions = {
      url: embedUrl,
      container: '#quicksightEmbedContainer',
      height: 'AutoFit',
    };

    if (
      qsContainer &&
      qsContainer.current &&
      qsContainer.current.firstChild
    ) {
      qsContainer.current.firstChild.remove();
    }

    const dashboard = await embedDashboard(qsOptions);

    setQsDashboard(dashboard);
  };

  async function getCampaigns() {
    try {
      const response = await useGet('/campaigns');
      setCampaigns(response.results);
    } catch (error) {
      console.error('Error getting campaigns', error);
    }
  }

  async function getEmbedUrl() {
    try {
      const response = await useGet(`${process.env.API_URL}/v1/reporting-embed-url/?report=path_to_purchase`, true);
      setEmbedUrl(response.embedUrl);
    } catch (error) {
      console.error('Error getting reporting', error);
    }
  }

  const handleQsFilter = value => {
    setCampaignId(value);

    setQsParams(prev => {
      return ({
        ...prev,
        campaignId: value
      });
    });
  };

  return (
    <AppHeader history={props.history}>
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              className={clsx(classes.paper, 'background-img-inline-example')}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                mb={4}
              >
                <Box>
                  <Typography variant="h6">
                    Path to Purchase
                  </Typography>
                </Box>

                <Box>
                  <TextField
                    select
                    className={classes.select}
                    color="secondary"
                    label="Campaigns"
                    onChange={event => handleQsFilter(event.target.value)}
                    size="small"
                    value={campaignId}
                    variant="outlined"
                  >
                    <MenuItem dense value={QUICKSIGHT_ALL}>All</MenuItem>

                    {campaigns.map(campaign => (
                      <MenuItem dense key={campaign.id} value={campaign.id}>
                        <Typography variant="body2" noWrap>
                          {campaign.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>

              <div ref={qsContainer} id="quicksightEmbedContainer" />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </AppHeader>
  );
};

PathToPurchasePage.propTypes = {
  history: PropTypes.object,
};

export default PathToPurchasePage;
