import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import { NavLink } from 'react-router-dom';
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles';

import creativeIcon from '../images/icons/creative-icon.svg';
// Will use this later
// import dashboardIcon from '../images/icons/dashboard-icon.svg';
import reportsIcon from '../images/icons/reports-icon.svg';
import settingsIcon from '../images/icons/settings-icon.svg';
import supportIcon from '../images/icons/support-icon.svg';
import trackingIcon from '../images/icons/tracking-icon.svg';
import tvIcon from '../images/icons/tv-icon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    paddingTop: theme.spacing(1),
  },
  icon: {
    width: `100%`,
  },
  iconWrap: {
    width: `20px`,
    margin: `5px auto`,
  },
  linkText: {
    fontSize: `0.625rem`,
    marginTop: 0,
  },
  linkWrapper: {
    margin: `0 auto`,
    textAlign: `center`,
    width: `100%`,
    paddingBottom: theme.spacing(1),
    paddingTop: 5,
    maxHeight: 70,
  },
  listItem: {
    padding: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export function MainListItems({
  activeStyle,
  showCampaignsLink = false,
  showCreativesLink = false,
  showTrackingLink = false,
}) {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {/* <ListItem button disableRipple className={classes.listItem}>
        <NavLink
          to="/home"
          className={classes.linkWrapper}
          activeStyle={activeNavLink}
        >
          <div className={classes.linkWrapper}>
            <div className={classes.iconWrap}>
              <img
                alt="Dashboard Icon"
                src={dashboardIcon}
                className={classes.icon}
              />
            </div>
            <p className={classes.linkText}>DASHBOARD</p>
          </div>
        </NavLink>
      </ListItem> */}

      {showCampaignsLink && (
        <ListItem button disableRipple className={classes.listItem}>
          <NavLink
            to="/home"
            className={classes.linkWrapper}
            activeStyle={activeStyle}
          >
            <div className={classes.linkWrapper}>
              <div className={classes.iconWrap}>
                <img
                  alt="Tv Icon"
                  src={tvIcon}
                  className={classes.icon}
                />
              </div>
              <p className={classes.linkText}>CAMPAIGNS</p>
            </div>
          </NavLink>
        </ListItem>
      )}

      <ListItem button disableRipple className={classes.listItem}>
        <NavLink
          to="/reports"
          className={classes.linkWrapper}
          activeStyle={activeStyle}
        >
          <div className={classes.linkWrapper}>
            <div className={classes.iconWrap}>
              <img
                alt="Reports Icon"
                src={reportsIcon}
                className={classes.icon}
              />
            </div>
            <p className={classes.linkText}>REPORTS</p>
          </div>
        </NavLink>
      </ListItem>

      {showCreativesLink && (
        <ListItem button disableRipple className={classes.listItem}>
          <NavLink
            to="/creatives"
            className={classes.linkWrapper}
            activeStyle={activeStyle}
          >
            <div className={classes.linkWrapper}>
              <div className={classes.iconWrap}>
                <img
                  alt="Creative Icon"
                  src={creativeIcon}
                  className={classes.icon}
                />
              </div>
              <p className={classes.linkText}>CREATIVE</p>
            </div>
          </NavLink>
        </ListItem>
      )}

      {showTrackingLink && (
        <ListItem button disableRipple className={classes.listItem}>
          <NavLink
            to="/tracking"
            className={classes.linkWrapper}
            activeStyle={activeStyle}
          >
            <div className={classes.linkWrapper}>
              <div className={classes.iconWrap}>
                <img
                  alt="Tracking Icon"
                  src={trackingIcon}
                  className={classes.icon}
                />
              </div>
              <p className={classes.linkText}>TRACKING</p>
            </div>
          </NavLink>
        </ListItem>
      )}
    </List>
  )
}

export function SecondaryListItems({ activeStyle }) {
    const classes = useStyles();

    return (
      <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem button disableRipple className={classes.listItem}>
        <NavLink
          className={classes.linkWrapper}
          to="/settings"
          activeStyle={activeStyle}
        >
          <div className={classes.linkWrapper}>
            <div className={classes.iconWrap}>
              <img
                alt="Settings Icon"
                src={settingsIcon}
                className={classes.icon}
              />
            </div>
            <p className={classes.linkText}>SETTINGS</p>
          </div>
        </NavLink>
      </ListItem>

      <ListItem button disableRipple className={classes.listItem}>
        <NavLink
          className={classes.linkWrapper}
          to="/support"
          activeStyle={activeStyle}
        >
          <div className={classes.linkWrapper}>
            <div className={classes.iconWrap}>
              <img
                alt="Support Icon"
                src={supportIcon}
                className={classes.icon}
              />
            </div>
            <p className={classes.linkText}>SUPPORT</p>
          </div>
        </NavLink>
      </ListItem>
    </List>
    )
}

SecondaryListItems.propTypes = {
  activeStyle: PropTypes.object,
};

MainListItems.propTypes = {
  activeStyle: PropTypes.object,
  showCampaignsLink: PropTypes.bool,
  showCreativesLink: PropTypes.bool,
  showTrackingLink: PropTypes.bool,
};
