import React, { Fragment, useState, memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import numeral from 'numeral';
import MiddleEllipsis from 'react-middle-ellipsis';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  makeStyles,
  Tooltip,
  Menu,
  MenuItem,
  Divider,
  TableSortLabel,
  TableHead,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import { useLoader } from '../hooks/loader';
import { useSort } from '../hooks/sort';
import VastTagListExtension from './VastTagListExtension';

const useStyles = makeStyles(({ palette, spacing }) => ({
  groupCell: {
    whiteSpace: 'nowrap',
  },
  expanded: {
    paddingLeft: 40,
  },
  libraryHeader: {
    marginBottom: spacing(4),
  },
  name: {
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 220,
  },
  none: {
    color: '#727383',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 375,
    alignItems: 'center',
  },
  table: {
    maxHeight: 375,
    minHeight: 375,

    [`& tbody tr:last-child td`]: {
      borderBottom: 'unset',
    }
  },
  editButton: {
    color: palette.secondary.main,
    minWidth: 45,
    textTransform: 'uppercase',
  },
  divider: {
    marginLeft: 5,
    marginRight: 5,
  },
  dense: {
    color: 'rgba(114, 115, 131, 0.8)',
    fontSize: '.8rem',
    lineHeight: 'normal',
  },
  blankCell: {
    maxWidth: 40,
    width: 40,
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
}));

const columns = [
  { id: 'title', label: 'Creative Title', align: 'left', sortable: true, class: '' },
  { id: 'bitrates', label: 'Bit Rates', align: 'left', sortable: false, class: '' },
  { id: 'duration', label: 'Duration', align: 'center', sortable: false, class: '' },
  { id: 'mimeType', label: 'Mime Type', align: 'center', sortable: false, class: '' },
  { id: 'action', label: 'Action', align: 'center', sortable: false, class: '', disablePadding: true },
];

const VastTagList = ({
  data,
  onSubmit,
  onRemove,
}) => {
  const classes = useStyles();
  const { isLoading } = useLoader();
  const {
    order,
    orderBy,
    handleRequestSort,
    getComparator,
    stableSort
  } = useSort();

  const [expanded, setExpanded] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleEditClick = (id) => {
    setExpanded((prev) => {
      if (prev.includes(id)) {
        return prev.filter(p => p !== id);
      }

      return [...prev, id];
    })
  };

  const handleSubmit = (id, data) => {
    onSubmit(id, data);
    setExpanded(prev => prev.filter(expandedId => expandedId !== id));
  };

  const handleRemove = (id) => {
    onRemove(id);
    setAnchorEl(null);
  };

  const handleArrowDownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.container}>
      <Grid
        className={classes.libraryHeader}
        container
        alignItems="center"
        justify="space-between"
      >
        <Grid item xs={6}>
          <Typography variant="h2">Creative</Typography>
        </Grid>
      </Grid>

      {/* Creatives Library Table */}
      {isLoading ? (
        <Box className={classes.spinner}>
          <CircularProgress color="secondary" />
        </Box>
      ) : data.length === 0 ? (
        <Box className={classes.spinner}>
          <Typography className={classes.none}>No results</Typography>
        </Box>
      ) : (
        <Fade in={!isLoading}>
          <TableContainer className={classes.table}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.blankCell} />

                  {columns.map(cell => (
                    <TableCell
                      key={cell.id}
                      className={classes.dense}
                      align={cell.align ? cell.align : 'center'}
                      padding={cell.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === cell.id ? order : false}
                    >
                      {!cell.sortable ? cell.label : (
                        <TableSortLabel
                          active={orderBy === cell.id}
                          direction={orderBy === cell.id ? order : 'asc'}
                          onClick={(event) => handleRequestSort(event, cell.id)}
                        >
                          {cell.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {stableSort(
                  data,
                  getComparator(order, orderBy)
                ).map(({
                  id,
                  name,
                  bitrates,
                  duration,
                  vast_tag_url,
                  mimeType,
                }) => {
                  const isExpanded = expanded.includes(id);

                  return (
                    <Fragment key={id}>
                      <TableRow className={clsx(isExpanded ? classes.root : '')}>
                        <TableCell className={classes.height} padding="none">
                          <IconButton
                            onClick={() => {}}
                            size="medium"
                          >
                            <PlayCircleFilledIcon color="secondary" />
                          </IconButton>
                        </TableCell>

                        <TableCell id={`creative-${id}`} padding="none" scope="row">
                          <Grid container alignItems="center">
                            <Grid className={classes.name} item xs>
                              <Tooltip title={name}>
                                <div>
                                  <MiddleEllipsis>
                                    <span>
                                      {name}
                                    </span>
                                  </MiddleEllipsis>
                                </div>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell className={classes.groupCell} align="center">
                          {bitrates}
                        </TableCell>

                        <TableCell align="center">
                          {`${numeral(Math.ceil(duration)).format('0.0')}s`}
                        </TableCell>

                        <TableCell align="center">
                          {mimeType}
                        </TableCell>

                        <TableCell align="center">
                          <Grid container alignItems="center" wrap="nowrap">
                            <Grid item>
                              <Button
                                className={classes.editButton}
                                disableRipple
                                size="small"
                                onClick={() => handleEditClick(id)}
                              >
                                Edit
                              </Button>
                            </Grid>
                            <Divider className={classes.divider} orientation="vertical" flexItem />
                            <Grid item>
                              <IconButton
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                aria-label="delete"
                                size="small"
                                onClick={handleArrowDownClick}
                              >
                                <KeyboardArrowDownIcon className={classes.delete} />
                              </IconButton>
                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                              >
                                <MenuItem
                                  onClick={() => handleRemove(id)}>
                                  Remove
                                </MenuItem>
                              </Menu>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>

                      {isExpanded && (
                        <TableRow>
                          <TableCell className={classes.expanded} colSpan={6}>
                            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                              <VastTagListExtension
                                data={{ name, vast_tag_url }}
                                onSubmit={data => handleSubmit(id, data)}
                                onRemove={() => handleRemove(id)}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment >
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Fade>
      )}
    </Box>
  );
};

VastTagList.propTypes = {
  data: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default memo(VastTagList);
