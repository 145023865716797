import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
} from '@material-ui/core';

const LoadingSpinner = props => {
  return (
    <Box
      data-testid="loading-spinner"
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.6)',
      }}
    >
      <CircularProgress
        color="secondary"
        size={props.size ? props.size : 40 }
      />
    </Box>
  );
}

LoadingSpinner.propTypes = {
  size: PropTypes.number,
}

export default LoadingSpinner;
