import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  Grid,
  Toolbar,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useOktaAuth } from '@okta/okta-react';

import { useLoader } from './hooks/loader';
import { useAdvertisers } from './hooks/advertisers';
import { MainListItems, SecondaryListItems } from './NavListItems';
import AdvertiserContext from './AdvertiserContext';
import UserContext from '../providers/UserContext';
import CreateNewAdvertiser from './CreateNewAdvertiser';
import LoginButton from './LoginButton';
import ModalOverlay from './ModalOverlay';
import Turnstile from './Turnstile';
import '../styles/components/example-img.css';
import { Themes } from '../constants';
import { useIntercom } from '../helpers/intercomWrapper';

const drawerWidth = `5.625rem`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    zIndex: 1202,
    position: 'fixed',
  },
  divider: {
    backgroundColor: '#fafafa',
    opacity: .5,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  newform: {
    position: 'absolute',
    top: theme.spacing(18),
    right: theme.spacing(12),
    bottom: theme.spacing(8),
  },
  appBar: {
    maxHeight: 64,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    backgroundColor: `#009061`,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: 1000,
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  turnstile: {
    visibility: 'hidden',
    width: 0,
    height: 0,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  innerWrap: {
    width: '100%',
  },
  loggedIn: {
    backgroundColor: `#006f4b`,
  },
  logo: {
    width: 116,
    height: `auto`,
  },
  logoFooter: {
    width: 95,
    height: `auto`,
  },
  logoNBCU: {
    width: 192,
    height: `auto`,
  },
  poweredBy: {
    fontSize: '0.625rem',
  },
  padding: {
    paddingBottom: theme.spacing(5),
  },
  sideNav: {
    width: '100%',
  },
  skipSaveLink: {
    textDecoration: 'none',
  },
  nbcuFooter: {
    position: 'fixed',
    backgroundColor: '#131719',
    bottom: 0,
    boxSizing: 'border-box',
    color: '#979797',
    height: 40,
    paddingRight: 20,
    width: '100%',
    zIndex: 1201,
  }
}));

const mainListItemsStyles = {
  [Themes.DEFAULT]: {
    background: '#014831',
    width: '88%',
    maxHeight: 70,
    borderRadius: 5,
  },
  [Themes.NBCU]: {
    background: '#646669',
    width: '88%',
    maxHeight: 70,
    borderRadius: 5,
  },
};

/////////////////////////////
// APP HEADER COMPONENT
/////////////////////////////
const AppHeader = (props) => {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);
  const user = useContext(UserContext);
  const { authState, oktaAuth } = useOktaAuth();
  const { isLoading, setIsLoading } = useLoader();

  // useAdvertisers hook
  const {
    advertisers,
    createAdvertiser,
    currentAdvertiser,
    getAdvertisers,
    updateAdvertiser,
  } = useAdvertisers();
  const { boot, update } = useIntercom();

  // Init state
  const [auth, setAuth] = useState(false);
  const [isNewAdvertiser, setIsNewAdvertiser] = useState(false);

  // Check authentication after component mounts
  useEffect(() => {
    checkAuthentication();
  }, []);

  useEffect(() => {
    if (adContext.theme === Themes.DEFAULT) {
      const urlParams = new URLSearchParams(window.location.search);
      const intercomEnvValue = urlParams.get('intercom-env');
      //Set the intercom user
      if (user && user.email) {
        const intercomUser = {
          email: user.email,
          name: user.name,
          userHash: intercomEnvValue === 'staging' ? undefined : user.user_hash,
          customAttributes: {
            current_page_key: location.pathname,
          }
        };
        boot(intercomUser)
      }
    }
  }, [user, adContext])

  useEffect(() => {
    //Set the current advertiser
    if (currentAdvertiser && currentAdvertiser.id) {
      update({
        customAttributes:
        {
          current_advertiser_id: currentAdvertiser.id,
          current_advertiser_name: currentAdvertiser.name,
        }
      })
    }
  }, [updateAdvertiser, currentAdvertiser]);

  const checkAuthentication = async () => {
    const isAuth = await authState.isAuthenticated;

    if (isAuth && isAuth !== auth) {
      setIsLoading(true);
      await getAdvertisers();
      setAuth(isAuth);
    }

    setIsLoading(false);
  };

  function NavSkipSave() {
    if (props.navSkipSaveStatus === 'skip') {
      return (
        <Grid item container xs={6} justify="flex-end">
          <Link to="/home" className={classes.skipSaveLink}>
            <Button color="primary" variant="contained" disableElevation>
              SKIP <ArrowForwardIcon fontSize="small" />
            </Button>
          </Link>
        </Grid>
      );
    } else if (props.navSkipSaveStatus === 'saveAndExit') {
      return (
        <Grid item container xs={6} justify="flex-end">
          <Button
            className="Nav-exit-btn"
            onClick={() => props.triggerSave(props.step, true, null)}
            color="primary"
            variant="contained"
            disableElevation
            disabled={props.isSaveButtonDisabled}
            endIcon={<ArrowForwardIcon fontSize="small" />}
          >
            Save &#38; Exit
          </Button>
        </Grid>
      );
    } else if (props.navSkipSaveStatus === 'back') {
      return (
        <Grid item container xs={6} justify="flex-end">
          <Link to="/home" className={classes.skipSaveLink}>
            <Button
              className="Nav-exit-btn"
              color="primary"
              variant="contained"
              disableElevation
              startIcon={<ArrowBack fontSize="small" />}
            >
              {adContext.theme === Themes.NBCU ? 'Exit' : 'Back'}
            </Button>
          </Link>
        </Grid>
      );
    }

    return null;
  }

  // Render functions
  // TODO: Make dynamic logo import based on theme value
  const renderThemeLogo = () => (
    <>
      <img
        src={require('../images/nbcu/padman-white-and-yellow-logo.png')}
        alt="Peacock Ad Manager logo"
        className={classes.logoNBCU}
      />
    </>
  );

  const renderFooter = () => (
    <Box className={classes.nbcuFooter}>
      <Grid container justify="flex-end" alignItems="center" flex-direction='row' style={{ height: "40px" }}>
        <Grid item container justify="flex-end" alignItems="center" xs={6}>
          <span className={classes.poweredBy}>Powered by&nbsp;&nbsp;</span>

          <img
            src={require('../images/logo/tvS_Logo_White_RGB.svg')}
            alt="tvScientific logo"
            className={classes.logoFooter}
          />
        </Grid>
      </Grid>
    </Box>
  );

  const renderHeader = () => (
    <Fragment>
      <CssBaseline />

      <AppBar
        position="absolute"
        className={clsx(classes.appBar, !open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <Grid container alignItems="center">
            <Grid item container xs={6}>
              <Box display="flex">
                {adContext.theme === Themes.DEFAULT &&
                  <img
                    src={require('../images/logo/tvS_Logo_White_RGB.svg')}
                    alt="tvScientific logo"
                    className={classes.logo} />}

                {adContext.theme === Themes.NBCU && renderThemeLogo()}
              </Box>
            </Grid>
            <NavSkipSave />
          </Grid>
        </Toolbar>
      </AppBar>

      <main className={`${classes.content}`}>
        <div className={classes.appBarSpacer} />
        {props.children}
      </main>
      {adContext.theme === Themes.NBCU && renderFooter()}
    </Fragment>
  );

  // Conditionally render side nav drawer
  const renderHeaderAuth = () => (
    <Fragment>
      <CssBaseline />
      <Turnstile
        siteKey={process.env.CLOUDFLARE_TURNSTILE}
        className={classes.turnstile}
        onError={async () => {
          await oktaAuth.revokeAccessToken();
          await oktaAuth.signOut();
        }}
      />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open
      >
        <div className={classes.toolbarIcon}></div>

        <Grid
          container
          direction="column"
          alignItems="center"
          justify="space-between"
          className={clsx('Drawer-nav_wrap', classes.sideNav)}
        >
          <Grid className={classes.innerWrap} item>
            <MainListItems
              activeStyle={mainListItemsStyles[adContext.theme]}
              showCampaignsLink={user.is_tvsci_employee || adContext.cost_model !== 'CPA'}
              showCreativesLink={user.is_tvsci_employee || adContext.cost_model !== 'CPA'}
              showTrackingLink={user.is_tvsci_employee || adContext.cost_model !== 'CPA'}
            />
          </Grid>

          <Grid className={classes.innerWrap} item>
            <SecondaryListItems
              activeStyle={mainListItemsStyles[adContext.theme]}
            />
          </Grid>
        </Grid>
      </Drawer>

      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={clsx(classes.toolbar, classes.loggedIn)}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Grid container alignItems="center">
                {adContext.theme === Themes.DEFAULT && (
                  <img
                    src={require('../images/logo/tvS_Logo_White_RGB.svg')}
                    alt="tvScientific logo"
                    className={classes.logo}
                  />
                )}

                {adContext.theme === Themes.NBCU && renderThemeLogo()}
              </Grid>
            </Grid>

            <Grid item>
              <LoginButton
                advertisers={advertisers}
                currentAdvertiser={currentAdvertiser}
                history={props.history}
                updateAdvertiser={updateAdvertiser}
                isFetching={isLoading}
                isNewAdvertiser={isNewAdvertiser}
                setIsNewAdvertiser={setIsNewAdvertiser}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <main
        className={clsx(
          classes.content,
          adContext.theme === Themes.NBCU ? classes.padding : ''
        )}
      >
        <div className={classes.appBarSpacer} />

        {props.children}

        {/* Conditional Rendering for new advertiser form */}
        {isNewAdvertiser && (
          <ModalOverlay
            isOpen={isNewAdvertiser}
            onClose={() => setIsNewAdvertiser(false)}
          >
            <Grid container spacing={2}>
              <Grid item xs={5} className={'CreateAccount-header-res'} />

              <Grid className={classes.newform} container item xs={6}>
                <CreateNewAdvertiser
                  createAdvertiser={createAdvertiser}
                  onCancel={() => setIsNewAdvertiser(false)}
                  showSalesforce={adContext.theme === Themes.NBCU}
                  notFirst
                />
              </Grid>
            </Grid>
          </ModalOverlay>
        )}
      </main>

      {adContext.theme === Themes.NBCU && renderFooter()}
    </Fragment>
  );

  return (
    <Box
      display="flex"
      width="100%"
      className={adContext.theme ? `${adContext.theme}-theme` : ''}
    >
      {props.hidenav === 'true' ? renderHeader() : renderHeaderAuth()}
    </Box>
  )
}

AppHeader.propTypes = {
  hidenav: PropTypes.string,
  history: PropTypes.object,
  children: PropTypes.node,
  navSkipSaveStatus: PropTypes.string,
  isSaveButtonDisabled: PropTypes.bool,
  theme: PropTypes.string,
  triggerSave: PropTypes.func,
  step: PropTypes.string,
};

export default AppHeader;
