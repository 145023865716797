import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import { makeStyles } from '@material-ui/core/styles';

import { api } from '../../connection/TVSApi';
import AppHeader from '../AppHeader';
import Title from '../Title';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  paper: {
    paddingTop: spacing(5),
    paddingBottom: spacing(5),
    paddingLeft: spacing(9),
    paddingRight: spacing(9),
    maxWidth: 900,
    margin: 'auto',
  },
  submit: {
    ...typography.h4,
    color: '#fff',
    marginLeft: spacing(2),
    padding: spacing(2),
    width: 175,
  },
  subtitle: {
    ...typography.subtitle1,
    fontSize: '1.125rem',
  },
  success: {
    backgroundColor: palette.primary.light,
  },
  successMsg: {
    color: palette.primary.light,
  },
  title: {
    ...typography.h3,
  },
  wrap: {
    height: 'calc(100% - 64px)',
  },
}));

const formNames = ['name', 'email', 'advertiser', 'type', 'comment'];

const SupportPage = props => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [advertiser, setAdvertiser] = useState('');
  const [type, setType] = useState('');
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const classes = useStyles();

  const handleSubmit = event => {
    if (isSuccess) {
      event.preventDefault();
      return;
    }

    setIsLoading(true);
    setIsSuccess(false);

    const formData = new FormData();

    [name, email, advertiser, type, comment].forEach((val, index) => {
      if (val !== '') {
        formData.append(formNames[index], val);
      }
    });

    return api
      .postUrl(`https://formspree.io/f/mqkwzynz/`, formData, {
        'Content-Type': 'multipart/form-data',
      })
      .then(response => {
        setIsLoading(false);

        if (response.data.ok) {
          setName('')
          setEmail('')
          setAdvertiser('')
          setType('')
          setComment('')
          setIsSuccess(true);
        }

        return response;
      })
      .catch(error => {
        setIsLoading(false);
        setIsSuccess(false);
        console.error(error)
      });
  };

  return (
    <AppHeader history={props.history}>
      <Grid
        className={classes.wrap}
        container
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12}>
          <Paper variant="outlined" elevation={0} className={classes.paper}>
            <Title className={classes.title}>Support</Title>

            <Typography className={classes.subtitle}>
              Send a question and we’ll get back to you soon.
            </Typography>

            <Box mt={3} mb={5}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    color="secondary"
                    margin="none"
                    fullWidth
                    label="Name"
                    onChange={event => setName(event.target.value)}
                    value={name}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    color="secondary"
                    margin="none"
                    fullWidth
                    label="Email"
                    onChange={event => setEmail(event.target.value)}
                    value={email}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    color="secondary"
                    margin="none"
                    fullWidth
                    label="Advertiser"
                    onChange={event => setAdvertiser(event.target.value)}
                    value={advertiser}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    color="secondary"
                    margin="none"
                    fullWidth
                    label="Support Type"
                    select
                    onChange={event => setType(event.target.value)}
                    value={type}
                    variant="outlined"
                  >
                    <MenuItem value={'bug'}>Bug</MenuItem>

                    <MenuItem value={'feature_request'}>
                      Feature Request
                    </MenuItem>

                    <MenuItem value={'manage_service'}>
                      Manage Service
                    </MenuItem>

                    <MenuItem value={'other'}>Other</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    color="secondary"
                    margin="none"
                    fullWidth
                    label="Comment"
                    onChange={event => setComment(event.target.value)}
                    value={comment}
                    multiline
                    rows={5}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              style={{ textAlign: 'right' }}
              width="100%"
            >
              {isSuccess && (
                <Typography className={classes.successMsg}>
                  Your question has been submitted!
                </Typography>
              )}

              <Button
                className={clsx(
                  classes.submit,
                  isSuccess ? classes.success : {}
                )}
                color="secondary"
                disabled={isLoading}
                disableElevation={isSuccess}
                disableRipple
                onClick={handleSubmit}
                startIcon={
                  isSuccess ? <CheckSharpIcon /> : null
                }
                variant="contained"
              >
                {isLoading
                  ? 'Submitting...'
                  : isSuccess
                    ? 'Submitted'
                    : 'Submit'}
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </AppHeader>
  );
};

SupportPage.propTypes = {
  history: PropTypes.object,
};

export default SupportPage;
