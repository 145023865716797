import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  makeStyles,
} from '@material-ui/core';

import AsyncButton from './AsyncButton';
import { useAPI } from './hooks/api';

const useStyles = makeStyles(({ palette, spacing }) => ({
  bid: {
    maxWidth: 200,
    textAlign: 'right',
  },
  cancel: {
    color: palette.grey.main,
  },
  dialog: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
}));

const ManageBid = ({ adGroup, isOpen, onClose, setHasSaved }) => {
  const classes = useStyles();
  const { isLoading, usePatch } = useAPI();

  const initialBid = () => parseFloat(adGroup.cpm, 10).toFixed(2);
  const [bid, setBid] = useState(initialBid());

  const handleEditBid = event => {
    setBid(event.target.value);
  };

  const handleSaveBid = () => {
    if (bid && bid !== '') {
      const formattedBid = `${parseFloat(bid, 10).toFixed(2)}`;
      if (formattedBid == adGroup.cpm) {
        return;
      }
    }

    return usePatch(`/lineitems/${adGroup.id}`, { cpm: bid })
      .then(bidResponse => {
        if (bidResponse && [200, 201].indexOf(bidResponse.status) > -1) {
          setHasSaved(true);
          onClose();
        }

        return bidResponse;
      })
      .catch(error => console.error('Error in saving bid', error));
  };

  return (
    <Dialog
      className={classes.dialog}
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="edit-bid-dialog"
    >
      <DialogTitle id="edit-bid-dialog">Edit Bid</DialogTitle>

      <DialogContent className={classes.dialog}>
        <Grid container justify="space-between" spacing={2}>
          <Grid item xs={6}>
            <DialogContentText>
              Enter Max CPM Bid for <strong>{adGroup.name}</strong>:
            </DialogContentText>
          </Grid>

          <Grid item xs={5}>
            <TextField
              autoFocus
              className={classes.bid}
              color="secondary"
              label="Max CPM Bid"
              margin="dense"
              onChange={handleEditBid}
              type="number"
              value={bid}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container alignItems="center" justify="flex-end">
          <Grid container item justify="flex-end" xs={2}>
            <Button className={classes.cancel} onClick={onClose}>
              Cancel
            </Button>
          </Grid>

          <Grid item xs={3}>
            <AsyncButton
              isLoading={isLoading}
              color="secondary"
              onClick={handleSaveBid}
              textButton="Save"
              loadingButton="Saving..."
              size="small"
              type="small"
              variant="text"
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ManageBid.propTypes = {
  adGroup: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  setHasSaved: PropTypes.func,
};

export default ManageBid;
