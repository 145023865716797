import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Container,
  Paper,
  makeStyles,
} from '@material-ui/core';

import AppHeader from '../AppHeader';
import AdvertiserWizard from '../AdvertiserWizard';
import AdvertiserCostPerClick from '../AdvertiserCostPerClick';
import AdvertiserContext from '../AdvertiserContext';
import { Themes } from '../../constants';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: 19,
    minHeight: 750,
    position: 'relative',
    marginTop: -10,
  },
}));

 const SetupAdvertiser = () => {
  const classes = useStyles();
  const history = useHistory();
  const adContext = useContext(AdvertiserContext);

  const [progress, setProgress] = useState('wizard');
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    const userType = localStorage.getItem('userType');
    const name = localStorage.getItem('company');

    adContext.updateAdvertiser({
      name,
      userType,
    });
  }, []);

  useEffect(() => {
    if (adContext && adContext.theme) {
      setTheme(adContext.theme);
    }
  }, [adContext]);

  const handleNext = () => {
    if (adContext.theme === Themes.NBCU) {
      history.push('/campaign-setup');

      return;
    }

    setProgress('lastClick');
  };

  return (
    <AppHeader
      theme={theme}
      hidenav="true"
    >
      <Container
        maxWidth="lg"
        className={clsx(classes.container, 'SetupWizard')}
      >
        <Paper
          className={clsx(classes.paper, '--background-colorbar')}
          elevation={12}
        >
          {progress === 'wizard' &&
            <AdvertiserWizard
              showKPI={theme !== Themes.NBCU}
              showModel={theme !== Themes.NBCU}
              showWindow={theme !== Themes.NBCU}
              showSalesforce={theme === Themes.NBCU}
              onNext={handleNext}
            />}

          {progress === 'lastClick' &&
            <AdvertiserCostPerClick />}
        </Paper>
      </Container>
    </AppHeader>
  );
}

export default SetupAdvertiser;
