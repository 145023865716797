import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControlLabel,
  Grid,
  Slider,
  Switch,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { useAPI } from './hooks/api';
import { useCopy } from './hooks';
import { useLoader } from './hooks/loader';
import { useSaveExit } from './hooks/saveExit';
import { valuetext } from './util';
import CampaignFooter from './CampaignFooter';
import Title from './Title';
import { Gender, GenderCopies, Themes } from '../constants';

const useStyles = makeStyles(theme => ({
  root: {
    width: 300,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonRoot: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  smallTitle: {
    textAlign: 'center',
    color: 'rgb(114, 115, 131)',
    marginBottom: theme.spacing(1),
  },
  height100: {
    height: '100%',
  },
  bottomNav: {
    marginTop: theme.spacing(4),
  },
  slider: {
    ['& .tvs-MuiSlider-track']: {
      backgroundColor: theme.palette.grey.main,
    },
  },
}));

const [ageMin, ageMax] = [1, 7];
const [incomeMin, incomeMax] = [30, 250];

const incomeMarks = [
  {
    value: incomeMin,
    label: '<$30K',
  },
  {
    value: 250,
    label: '$250K+',
  },
];

const ageMarks = [
  {
    value: 1,
    label: 'P2+',
  },
  {
    value: 2,
    label: '18',
  },
  {
    value: 3,
    label: '25',
  },
  {
    value: 4,
    label: '35',
  },
  {
    value: 5,
    label: '45',
  },
  {
    value: 6,
    label: '54',
  },
  {
    value: 7,
    label: '65+',
  },
];

const ageKeys = {
  1: 2,
  2: 18,
  3: 25,
  4: 35,
  5: 45,
  6: 54,
  7: 65,
};

const ageValues = {
  2: 1,
  18: 2,
  25: 3,
  35: 4,
  45: 5,
  54: 6,
  65: 7,
};

const demographicsTip = `If you choose demographic targeting, your data CPM will be $1.50 CPM. Targeting may limit scale. Keep in mind you are targeting at the household level.`;

const Copies = {
  [Themes.DEFAULT]: {
    INTRO: null,
    FooterBack: 'Geo-Targeting',
    gender: GenderCopies[Themes.DEFAULT],
    GenderButton: [
      {
        gender: Gender.MALE,
        value: "male",
        handler: (handleSetGender) => () => {
          handleSetGender(Gender.MALE);
        },
      },
      {
        gender: Gender.FEMALE,
        value: "female",
        handler: (handleSetGender) => () => {
          handleSetGender(Gender.FEMALE);
        },
      }
    ]

  },
  [Themes.NBCU]: {
    INTRO: <>Select the gender, age, and household (HH) income of the audience you wish to target. To select additional targeting options, ensure the <b>Define advanced behaviors & interests</b> toggle is on.</>,
    FooterBack: 'Geotargeting',
    gender: GenderCopies[Themes.NBCU],
    GenderButton: [
      {
        gender: Gender.FEMALE,
        value: "female",
        handler: (handleSetGender) => () => {
          handleSetGender(Gender.FEMALE);
        },
      },
      {
        gender: Gender.MALE,
        value: "male",
        handler: (handleSetGender) => () => {
          handleSetGender(Gender.MALE);
        },
      },
    ]
  },
};

// TODO:
// - Lift state to custom hook
const DemoTargeting = props => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    combinedTargetingData,
    save,
    triggerSave,
    setAdjustRecommendedBid,
    setIsDefaultDemo = () => {},
  } = props;

  const { usePatch } = useAPI();
  const { saveProgress } = useSaveExit();
  const { isLoading, setIsLoading } = useLoader();
  const Copy = useCopy(Copies);

  const initialAge = () => {
    if (props.age) {
      const min = ageValues[props.age[0]] ? ageValues[props.age[0]] : 1;
      const max = ageValues[props.age[1]] ? ageValues[props.age[1]] : 7;
      return [min, max];
    }

    return [ageMin, ageMax];
  };

  const initialIncome = () =>
    props.income ? props.income : [incomeMin, incomeMax];

  const [gender, setGender] = useState(props.gender ? props.gender : 'All');
  const [age, setAge] = useState(initialAge());
  const [income, setIncome] = useState(initialIncome());

  const [isRangeError, setIsRangeError] = useState(false);

  useEffect(() => {
    if (props.gender) {
      setGender(props.gender);
    }

    if (props.income) {
      setIncome(props.income);
    }
  }, [props.age, props.gender, props.income]);

  useEffect(() => {
    let isDefault = true;

    if (gender.toLowerCase() !== 'all') {
      isDefault = false;
    }

    if (age[0] !== 1 || age[1] !== 7) {
      isDefault = false;
    }

    if (income[0] !== 30 || income[1] !== 250) {
      isDefault = false;
    }

    setIsDefaultDemo(isDefault);
  }, [age, gender, income]);

  useEffect(() => {
    if (
      age &&
      age[0] === ageValues[2] &&
      age[1] === ageValues[18]
    ) {
      if (age && age[0] === ageValues[2] && age[1] === ageValues[18]) {
        setIsRangeError(true);
      } else {
        setIsRangeError(false);
      }
    }
  }, [age]);

  useEffect(() => {
    if (isRangeError) {
      enqueueSnackbar(
        'In order to restrict targeting please select a maximum age over 18 or a starting age over 18.',
        {
          autoHideDuration: 10000,
          variant: 'warning',
        }
      );

      return;
    }

    closeSnackbar();
  }, [isRangeError]);

  useEffect(() => {
    saveProgress(save, 'DemoTargeting', handleSave, triggerSave);
  }, [save]);

  ////////////////////////////////////
  // Local state handlers
  ////////////////////////////////////
  const handleSetGender = value => {
    setGender(value);

    if (props.setGender) {
      props.setGender(value);
    }
  };

  const handleActiveGender = value => {
    return gender === value ? 'contained' : 'outlined';
  };

  const handleAgeSlider = (event, value) => {
    setAge(value);

    if (props.setAge) {
      props.setAge([ageKeys[value[0]], ageKeys[value[1]]]);
    }
  };

  const handleIncomeSlider = (event, value) => {
    setIncome(value);

    if (props.setIncome) {
      props.setIncome(value);
    }
  };

  const advanced = props.isAdvanced;

  const handleData = () => {
    const dataObj = {
      age: [ageKeys[age[0]], ageKeys[age[1]]],
      gender,
      income,
      advanced,
    };

    if (props.handleDemoData) {
      props.handleDemoData(dataObj);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    handleData();

    const data = {
      targeting: JSON.stringify({
        ...combinedTargetingData,
        advanced,
        age: [ageKeys[age[0]], ageKeys[age[1]]],
        income,
        gender,
      }),
    };

    try {
      const response = await usePatch(
        `/lineitems/${props.currentAdGroup.id}/`,
        data
      );

      setIsLoading(false);

      // Handle Save & Exit button click
      if (save && save.step && save.step === 'DemoTargeting') {
        if (save.exit) {
          history.push('/home');
        }
      }

      // Handle next button click
      if (!save.exit) {
        props.isAdvanced
          ? props.setStep('TargetingSegments')
          : props.setStep('Inventory');

        props.isAdvanced
          ? props.updateBreadcrumbs('targeting', 50)
          : props.updateBreadcrumbs('targeting', 75);
      }

      return response;
    } catch (error) {
      console.error('Error in saving DemoTargeting', error);

      setIsLoading(false);

      return error;
    }
  };

  const checkDemoData = () => {
    if (
      age[0] !== ageMin ||
      age[1] !== ageMax ||
      income[0] !== incomeMin ||
      income[1] !== incomeMax ||
      gender !== 'All'
    ) {
      props.setDemographicsTouched(true);
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!props.isEditing) {
      if (checkDemoData() && props.isAdvanced) {
        setAdjustRecommendedBid(true);
      } else if (checkDemoData() && !props.isAdvanced) {
        setAdjustRecommendedBid(true);
      } else if (!checkDemoData() && !props.isAdvanced) {
        setAdjustRecommendedBid(false);
      }
    }
  }, [age, income, gender, advanced]);

  return (
    <Grid container style={{ height: '100%' }} justify="space-between">
      <Box width="100%">
        {!props.isEditing && (
          <Fragment>
            <Title>
              Select Demographics{' '}
              <Tooltip title={demographicsTip}>
                <InfoOutlinedIcon fontSize="small" color="secondary" />
              </Tooltip>
            </Title>

            {Copy.INTRO && <p>{Copy.INTRO}</p>}

            <Divider className={classes.divider} />
          </Fragment>
        )}

        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item className={classes.root}>
            <Typography className={classes.smallTitle}>
              <strong>Gender:</strong> {gender}
            </Typography>

            <ButtonGroup
              color="secondary"
              aria-label="contained secondary button group"
              disableRipple
              fullWidth
              disableElevation
            >
              <Button
                onClick={() => {
                  handleSetGender('All');
                }}
                variant={handleActiveGender('All')}
                value="all"
                disableElevation
              >
                All
              </Button>

              {Copy.GenderButton.map(({ gender, handler, value }) => {
                return (
                  <Button
                    key={gender}
                    onClick={handler(handleSetGender)}
                    variant={handleActiveGender(gender)}
                    value={value}
                    disableElevation
                  >
                    {Copy.gender[gender]}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Grid>

          <Grid item className={classes.root}>
            <Typography
              className={clsx([classes.smallTitle, 'range-slider'])}
            >
              <strong>Age</strong>
            </Typography>

            <Slider
              className={isRangeError ? classes.slider : ''}
              value={age}
              min={1}
              max={7}
              marks={ageMarks}
              onChange={handleAgeSlider}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={valuetext}
              name="age"
              step={null}
              color="secondary"
              valueLabelFormat={v => ageKeys[v]}
            />
          </Grid>

          <Grid item className={classes.root}>
            <Typography
              className={clsx([classes.smallTitle, 'range-slider'])}
            >
              <strong>HH Income</strong>
            </Typography>

            <Slider
              value={income}
              min={incomeMin}
              max={incomeMax}
              marks={incomeMarks}
              step={50}
              onChange={handleIncomeSlider}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={valuetext}
              name="income"
              color="secondary"
            />
          </Grid>

          <Grid item className={classes.root}>
            <FormControlLabel
              control={
                <Switch
                  checked={props.isAdvanced}
                  color="secondary"
                  name="define-adv"
                  onChange={event =>
                    props.setIsAdvanced(event.target.checked)
                  }
                  size="small"
                />
              }
              label={
                <Typography variant="body2">
                  Define advanced behaviors &amp; interests
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container item xs={12} justfy="flex-end">
        {!props.isEditing && (
          <CampaignFooter
            isDisabled={isRangeError}
            isLoading={isLoading}
            back={Copy.FooterBack}
            next={props.isAdvanced ? 'Targeting Segments' : 'Inventory'}
            onBack={() => {
              props.setStep('GeoTargeting');
              props.updateBreadcrumbs('targeting', 0);
            }}
            onNext={() => {
              setIsLoading(true);
              triggerSave(
                'DemoTargeting',
                false,
                props.isAdvanced ? 'TargetingSegments' : 'Inventory'
              );
            }}
            page={2}
          />
        )}
      </Grid>
    </Grid>
  );
};

DemoTargeting.propTypes = {
  isAdvanced: PropTypes.bool,
  isEditing: PropTypes.bool,
  setIsAdvanced: PropTypes.func,
  setIsRangeError: PropTypes.func,
  age: PropTypes.array,
  gender: PropTypes.string,
  income: PropTypes.array,
  setAge: PropTypes.func,
  setGender: PropTypes.func,
  setIncome: PropTypes.func,
  setStep: PropTypes.func,
  handleDemoData: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
  advertiserId: PropTypes.number,
  currentAdGroup: PropTypes.object,
  combinedTargetingData: PropTypes.object,
  demoData: PropTypes.array,
  save: PropTypes.object,
  triggerSave: PropTypes.func,
  setAdjustRecommendedBid: PropTypes.func,
  setDemographicsTouched: PropTypes.func,
  setIsDefaultDemo: PropTypes.func
};

export default DemoTargeting;
