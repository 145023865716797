import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
// TODO: TBD - Add Custom Tracking Event
// import AddCircleIcon from '@material-ui/icons/AddCircle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
// TODO: TBD - Add Custom Tracking Event
// import IconButton from '@material-ui/core/IconButton';
// import InputBase from '@material-ui/core/InputBase';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import AdvertiserContext from './AdvertiserContext';
import { useTracking } from './hooks/tracking';
import Title from './Title';
import { Themes } from '../constants';

const useStyles = makeStyles((theme) => ({
  backBtn: {
    color: `rgba(0, 0, 0, 0.1)`,
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  addEvent: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    borderRadius: '10px',
    backgroundColor: 'rgb(255, 255, 255)',
    width: '89%',
    padding: theme.spacing(1),
    boxShadow: '0 5px 5px -3px rgba(24, 28, 32, 0.07), 0 3px 14px 2px rgba(24, 28, 32, 0.12), 0 8px 10px 1px rgba(24, 28, 32, 0.1)',
  },
  addEventBtn: {
    marginRight: theme.spacing(1),
  },
  bottomNav: {
    marginTop: theme.spacing(4),
    position: 'absolute',
    bottom: '10%'
  },
}));



/////////////////////////////
// TRACKING EVENTS COMPONENT
/////////////////////////////
const TrackingEvents = (props) => {
  const classes = useStyles();

  const { trackingEvents } = useTracking();
  const adContext = useContext(AdvertiserContext);

  // TODO: TBD - Add Custom Tracking Event
  // const [newTrackingEvent, setNewTrackingEvent] = useState('');
  // TODO: TBD - Add Custom Tracking Event
  // const updateNewTrackingEvent = (event) => {
  //   setNewTrackingEvent(event);
  // };

  // TODO: TBD - Add Custom Tracking Event
  // const handleClickAddTrackingEvent = () => {
  //   if (newTrackingEvent) {
  //     props.handleSelectEvent(newTrackingEvent);
  //     updateNewTrackingEvent('');
  //   }
  // };

  // For displaying custom tracking events
  // Compare trackingEvents and props.selectedEvents
  const customTrackingEvents = props.selectedEvents.filter((selectedEvent) => {
    // Filter out any duplicates
    return !trackingEvents.some((standardEvent) => {
      return selectedEvent.name === standardEvent.name;
    });
  }).map((filteredEvent) => {
    // Render the remaining, or in other words, the custom tracking events
    return (
      <Grid item xs={4} key={filteredEvent.name}>
        <Box m={1} className="Button-choice">
          <FormControlLabel
            control={<Checkbox
              name={filteredEvent.name}
              checked={props.selectedEvents.some((event) => event.name === filteredEvent.name)}
              onClick={() => props.handleSelectEvent(filteredEvent)}
            />}
            label={filteredEvent.name}
          />
        </Box>
      </Grid>
    );
  });

  return (
    <Fragment>
      <Grid container justify="space-between">
        <Grid item>
          <Title>Select Tracking Events</Title>

          {adContext.theme === Themes.NBCU && (
            <p>
              Choose the events you wish to track and have displayed in your campaign reporting.
            </p>
          )}
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Grid container className={classes.margin}>
        {props.totalEvents.map((trackingEvent) => {
          return <Grid item xs={4} key={trackingEvent.name}>
            <Box m={1} className="Button-choice">
              <FormControlLabel
                control={
                  <Checkbox
                    name={trackingEvent.name}
                    checked={props.selectedEvents.some(
                      event => event.name === trackingEvent.name
                    )}
                    onClick={() => props.handleSelectEvent(trackingEvent)}
                  />
                }
                label={trackingEvent.name}
              />
            </Box>
          </Grid>
        })}

        {customTrackingEvents}

        {/* TODO: TBD - Add Custom Tracking Event */}
        {/* <Grid id="new-event-container" item xs={4}>
          <Box className={classes.addEvent}>
            <IconButton
              className={classes.addEventBtn}
              onClick={() => handleClickAddTrackingEvent()}
              size="small"
            >
              <AddCircleIcon color="secondary" />
            </IconButton>

            <InputBase
              placeholder="Add tracking event"
              inputProps={{ 'aria-label': 'naked' }}
              value={newTrackingEvent}
              onChange={(event) => updateNewTrackingEvent(event.target.value)} />
          </Box>
        </Grid> */}
      </Grid>

      <Grid className={classes.bottomNav} container alignItems="center" justify="flex-end">
        <Grid container item xs={6} justify="flex-start">
          <Box>
            <Button
              color="secondary"
              className="Button-campaign-nav"
              onClick={() => {
                props.setStep('TrackingSetup');
                props.updateBreadcrumbs("tracking", 0);
              }}
              startIcon={<ArrowBackIcon />}
              variant="outlined"
              disableElevation
            >
              Select Tracking Method
            </Button>
          </Box>
        </Grid>

        <Grid container item xs={6} justify="flex-end">
          <Button
            disableElevation
            className="Button--medium"
            color="secondary"
            variant="contained"
            size="medium"
            onClick={() => {
              props.setStep("ConfigureTrackingEvents");
              props.updateBreadcrumbs("tracking", 50);
            }}>
            Next
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

TrackingEvents.propTypes = {
  selectedEvents: PropTypes.array,
  handleSelectEvent: PropTypes.func,
  setStep: PropTypes.func,
  totalEvents: PropTypes.array,
  updateBreadcrumbs: PropTypes.func,
};

export default TrackingEvents;
