import React, { useState, memo, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorMessage from './ErrorMessage';

const useStyles = makeStyles(() => ({
  action: {
    margin: '5px 0',
  },
  submit: {
    '&:disabled': {
      color: 'gray',
    },
  }
}));

const VastTagListExtension = ({
  data,
  onSubmit,
  onRemove,
}) => {
  const classes = useStyles();

  const [warning, setWarning] = useState({
    tags: {},
  });

  const { control, formState, reset, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: { title: '', url: '' },
  });

  const createHandleTagsTitleBlur = useCallback((onBlur) => (event) => {
    const { value } = event.target;
    const MESSAGE = 'The Creative Title is longer than 36 characters and may appear cut-off';
    const title = value.length > 36 ? MESSAGE : null;

    onBlur(event);
    setWarning({ title });
  }, []);

  useEffect(() => {
    reset(data);
  }, [data]);

  return (
    <Grid
      component="form"
      container
      alignItems="flex-start"
      wrap="nowrap"
      spacing={2}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={4}>
        <Controller
          control={control}
          name="name"
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'The field must not be empty',
            },
          }}
          render={({ field: { onBlur, ...field }, fieldState: { error} }) => {
            const warningMessage = warning.title || null;
            const errorMessage = error ? error.message : null;
            const alertMessage = errorMessage || warningMessage;

            return (
              <>
                <TextField
                  {...field}
                  onBlur={createHandleTagsTitleBlur(onBlur)}
                  error={alertMessage !== null}
                  color="secondary"
                  label="Creative Title"
                  placeholder="Enter Creative Title"
                  variant="outlined"
                  fullWidth
                />
                {alertMessage && <ErrorMessage>{alertMessage}</ErrorMessage>}
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={7}>
        <Controller
          control={control}
          name="vast_tag_url"
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'The field must not be empty',
            },
            pattern: {
              value: /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/i,
              message: 'The VAST Tag URL is not configured correctly'
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <TextField
                {...field}
                error={error !== undefined}
                color="secondary"
                label="VAST Tag URL"
                placeholder="Enter VAST Tag URL"
                variant="outlined"
                multiline
                fullWidth
              />
              {error && <ErrorMessage>{error.message}</ErrorMessage>}
            </>
          )}
        />
      </Grid>
      <Grid item xs container wrap="nowrap">
        <Grid item>
          <IconButton
            className={classes.action}
            disabled={!formState.isValid}
            type="submit"
          >
            <CheckCircleOutlineIcon
              color={formState.isValid ? 'primary' : 'disabled'}
            />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            className={classes.action}
            onClick={onRemove}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

VastTagListExtension.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default memo(VastTagListExtension);
