import React, { Fragment, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';
import numeral from 'numeral';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import AdvertiserContext from './AdvertiserContext';
import { useAPI } from './hooks/api';
import { useLoader } from './hooks/loader';
import { useSaveExit } from './hooks/saveExit';
import CampaignFooter from './CampaignFooter';
import LoadingSpinner from './ui/LoadingSpinner';
import Title from './Title';
import tvsIcon from '../images/network-logos/tvs-logo-white.png';
import { Themes } from '../constants';

const useStyles = makeStyles(theme => ({
  arrowBack: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  boxLabel: {
    display: 'flex',

    ['& .tvs-MuiFormControlLabel-root']: {
      margin: '0 auto',
    },
  },
  bundleGroup: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  fieldset: {
    width: '100%',
  },
  info: {
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: '1rem',
  },
  labelName: {
    ...theme.typography.paragraph,
    fontWeight: 'bold',
    margin: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '95%',
  },
  labelCpm: {
    ...theme.typography.paragraph,
    margin: 0,
  },
  step2: {
    color: '#9aa0a6',
  },
  tooltip: {
    backgroundColor: `#f2f3f5`,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    maxWidth: 180,
  },
  wrap: {
    position: 'relative',
    height: '100%',
  },
}));

// TODO: refactor this component into <SelectCategories /> if necessary
/////////////////////////////
// SELECT GENRES COMPONENT
/////////////////////////////
const SelectGenres = props => {
  const classes = useStyles();
  const history = useHistory();

  const adContext = useContext(AdvertiserContext);
  const { isLoading, setIsLoading } = useLoader();
  const { isLoading: isFetching, setIsLoading: setIsFetching } = useLoader();
  const { useGetAll, usePatch } = useAPI();
  const { saveProgress } = useSaveExit();

  const {
    screenSize,
    combinedTargetingData,
    currentAdGroup,
    save,
    canNext,
    triggerSave,
    setStep,
    isDefaultDemo,
    isNoBidFees,
  } = props;

  const initialBundles = () =>
    props.bundles && props.bundles.length > 0 ? props.bundles : [];

  const [bundles, setBundles] = useState(initialBundles());
  const [bundleUrl, setBundleUrl] = useState(null);
  const [genres, setGenres] = useState([]);

  useEffect(() => {
    if (bundleUrl != null) {
      getBundles();
    }
  }, [bundleUrl]);

  useEffect(() => {
    if (props.bundles && props.bundles.length > 0) {
      setBundles(props.bundles);
    }
  }, [props.bundles]);

  useEffect(() => {
    saveProgress(save, 'Genres', handleSave, triggerSave);
  }, [save]);

  useEffect(() => {
    if (isDefaultDemo != null && isNoBidFees != null) {
      handleBundleUrl();
    }
  }, [isDefaultDemo, isNoBidFees]);

  // Collects and sorts bundles by type
  function getBundles() {
    setIsFetching(true);

    return useGetAll(bundleUrl, [], total => {
      if (total && total.length > 0) {
        const sorted = total.reduce((acc, curr) => {
          const type =
            curr.type && curr.type !== '' ? curr.type.toLowerCase() : 'reach';

          if (!acc[type] || !acc[type].length || !acc[type].length === 0) {
            return {
              ...acc,
              [type]: [curr],
            };
          }

          return {
            ...acc,
            [type]: [...acc[type], curr],
          };
        }, {});

        // Remove Run of Network bundle
        const genresBundles = sorted.peacock.filter(
          p => p.bundle_name !== 'Run Of Network'
        );

        setGenres(genresBundles);
        setIsFetching(false);
      }
    });
  }

  const handleBundleUrl = () => {
    const interest = '?fees=interest';
    const demo = '?fees=demo';
    let url = '/bundles';

    if (isNoBidFees) {
      url = `${url}${interest}`;
      setBundleUrl(url);
      return;
    }

    if (isDefaultDemo === false) {
      url = `${url}${demo}`;
    }

    setBundleUrl(url);
  };

  // Event handlers
  const handleCheck = event => {
    const { checked, name } = event.target;

    if (checked) {
      setBundles(prev => [...prev, name]);

      if (props.setBundles) {
        props.setBundles(prev => [...prev, name]);
      }
    } else {
      const filtered = bundles.filter(bundle => bundle !== name);
      setBundles(filtered);

      if (props.setBundles) {
        props.setBundles(filtered);
      }
    }
  };

  const handleSave = async () => {
    setIsLoading(true);

    const cpmArr = [];
    const bundlesCPM = _.flatten(
      _.map(bundles, bundle =>
        [...genres].filter(i => i.bundle_name === bundle)
      )
    );

    _.map(bundlesCPM, bundle => cpmArr.push(Number(bundle.estimated_cpm)));

    cpmArr.sort((a, b) => a - b);

    props.setCpmRange([cpmArr[0], cpmArr.slice(-1)[0]]);

    const bundlesArray = bundles.filter(b => b !== 'Run Of Network');

    const dataObj = {
      inventory: 'bundles',
      bundles: bundlesArray,
      screen_size: screenSize,
    };

    props.handleInventoryData(dataObj);

    const data = {
      targeting: JSON.stringify({
        ...combinedTargetingData,
        ...dataObj,
      }),
    };

    try {
      const response = await usePatch(
        `/lineitems/${currentAdGroup.id}/`,
        data
      );

      setIsLoading(false);

      // Handle Save & Exit button click
      if (save && save.step && save.step === 'Genres') {
        if (save.exit) {
          history.push('/home');
        }
      }

      // Handle next button click
      if (!save.exit) {
        setStep('NameAdGroup');
      }

      return response;
    } catch (error) {
      console.error('Error in saving Bundles', error);
      setIsLoading(false);
      return error;
    }
  };

  const renderLabel = label => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <img
            style={{ width: 25 }}
            src={label.icon && label.icon.url ? label.icon.url : tvsIcon}
            alt={label.icon && label.icon.name ? label.icon.name : 'TVS'}
          />
        </Grid>

        <Grid item xs={10}>
          <p className={classes.labelName}>{label.bundle_name}</p>
          <p className={classes.labelCpm}>
            Estimated CPM:{' '}
            {numeral(Math.ceil(label.estimated_cpm)).format('$0,0')} +

            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={label.info || ''}
            >
              <InfoOutlinedIcon className={classes.info} fontSize="small" />
            </Tooltip>
          </p>
        </Grid>
      </Grid>
    );
  };

  const renderBundleGroup = (bundle, index) => (
    <Grid key={`genres-${index}`} item xs={4}>
      <FormControl className={classes.fieldset} component="fieldset">
        <FormGroup>
          <Grid container spacing={2}>
            <Grid
              key={`${bundle.bundle_name.toLowerCase()} - ${index}`}
              item
              xs={12}
            >
              <Box className={clsx('Button-choice', classes.boxLabel)}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bundles.includes(bundle.bundle_name)}
                      onClick={handleCheck}
                      name={bundle.bundle_name}
                    />
                  }
                  label={renderLabel(bundle)}
                />
              </Box>
            </Grid>
          </Grid>
        </FormGroup>
      </FormControl>
    </Grid>
  );

  const renderGenres = () => (
    <Box p={2} pt={3} pb={4}>
      <Grid
        className={classes.bundleGroup}
        container
        item
        spacing={2}
        xs={12}
      >
        {genres.length > 0 &&
          genres
            .filter(bundle => !bundle.ott)
            .map((b, i) => renderBundleGroup(b, i))}
      </Grid>
    </Box>
  );

  return (
    <Fragment>
      <Grid container className={classes.wrap}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Grid container item alignItems="center" justify="flex-start">
              {!props.isEditing && (
                <Grid item>
                  <IconButton
                    className={classes.arrowBack}
                    size="small"
                    onClick={() => {
                      props.setStep('Inventory');
                    }}
                  >
                    <ArrowBackIosIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}

              <Grid item xs={11}>
                <Title className={classes.title}>
                  <span className={classes.step2}>Step 2:</span> Select Genre
                </Title>
              </Grid>
            </Grid>
          </Grid>

          {adContext.theme === Themes.NBCU && (
            <p>
              Select the content genre in which you’d like your campaign to run.
            </p>
          )}

          <Divider className={classes.divider} />

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            <Paper elevation={0} className={classes.paper}>
              {isFetching ? <LoadingSpinner /> : renderGenres()}
            </Paper>

            {!props.isEditing && (
              <Box mb={7}>
                <CampaignFooter
                  isLoading={isLoading}
                  isDisabled={!canNext}
                  back={
                    props.hasAdvancedTargeting
                      ? 'TargetingSegments'
                      : 'Demographics'
                  }
                  next={'Save Ad Group...'}
                  onBack={() => {
                    if (props.hasAdvancedTargeting) {
                      props.setStep('TargetingSegments');
                    } else {
                      props.setStep('DemoTargeting');
                    }

                    props.updateBreadcrumbs('targeting', 25);
                  }}
                  onNext={() => {
                    setIsLoading(true);
                    triggerSave('Genres', false, 'NameAdGroup');
                  }}
                  page={4}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

SelectGenres.propTypes = {
  isEditing: PropTypes.bool,
  bundles: PropTypes.array,
  hasAdvancedTargeting: PropTypes.bool,
  setBundles: PropTypes.func,
  setStep: PropTypes.func,
  isDefaultDemo: PropTypes.bool,
  isNoBidFees: PropTypes.bool,
  handleInventoryData: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
  currentAdGroup: PropTypes.object,
  combinedTargetingData: PropTypes.object,
  save: PropTypes.object,
  canNext: PropTypes.bool,
  triggerSave: PropTypes.func,
  setCpmRange: PropTypes.func,
  setInventoryOverlay: PropTypes.func,
  inventoryOverlay: PropTypes.bool,
  screenSize: PropTypes.string,
};

export default SelectGenres;
