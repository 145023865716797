import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { useAPI } from './hooks/api';
import AdvertiserContext from './AdvertiserContext';
import amexIcon from '../images/american-express-dark.svg';
import discoverIcon from '../images/discover-dark.svg';
import mastercardIcon from '../images/master-card-dark.svg';
import visaIcon from '../images/visa-dark.svg';

const authorizeAddPayment = process.env.AUTHORIZE_NET_ADD_PAYMENT;
// TODO: url for integrating edit payment
// const authorizeEditPayment =
//   'https://test.authorize.net/customer/editPayment';

const paymentIcons = {
  amex: amexIcon,
  discover: discoverIcon,
  visa: visaIcon,
  mastercard: mastercardIcon,
};

const formatLastFour = digits => digits.split('XXXX')[1];

const useStyles = makeStyles(theme => ({
  iframe: {
    marginBottom: theme.spacing(2),
  },
  methods: {
    fontSize: '.8rem',
  },
  paymentListItem: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),

    '&.tvs-Mui-selected': {
      backgroundColor: '#d3f0ff',
    },

    '&.tvs-Mui-selected:hover': {
      backgroundColor: '#d3f0ff',
    },
  },
  payments: {
    margin: theme.spacing(1),
    maxHeight: 244,
    overflow: 'auto',
  },
  radioGroup: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  radios: {
    fontSize: `1rem`,
  },
}));

const AuthorizeNetIFrame = ({
  onCancel,
  onMessage,
  onReceiveCommunication,
  onResize,
  onSelectPayment,
  onPaymentType,
  isInvoice,
  setIsInvoice,
}) => {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);
  const { useGet } = useAPI();
  const iframeRef = useRef();

  const submitRef = useCallback(form => {
    // On render submit form immediately
    if (form != null) {
      form.submit();
    }
  }, []);

  const [isEnterCard, setIsEnterCard] = useState(false);
  const [formToken, setFormToken] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);

  useEffect(() => {
    window.AuthorizeNetIFrame = {
      onReceiveCommunication: str => {
        if (onReceiveCommunication) {
          onReceiveCommunication(str);
        }

        const message = parse(str);

        console.log('message from iframe', message);
        console.log('message.action from iframe', message.action);

        if (isValidMessage(message)) {
          if (onMessage) {
            onMessage(message);
          }

          switch (message.action) {
            case 'successfulSave':
              // if a save is detected, hit the refresh
              // endpoint on payment_profiles
              getProfilesRefresh();
              break;

            case 'resizeWindow':
              console.log('resizeWindow', message);

              if (onResize) {
                onResize(message.width, message.height);
              }
              break;

            case 'cancel':
              console.log('cancel', message);

              getProfilesRefresh()
                .then(() => getAuthorizeToken())
                .then(() => {
                  handleCloseForm();
                });

              if (onCancel) {
                onCancel();
              }
              break;
          }
        }
      },
    };
    return () => {
      delete window.AuthorizeNetIFrame;
    };
  }, []);

  useEffect(() => {
    getProfilesRefresh();
    getAuthorizeToken();
  }, []);

  useEffect(() => {
    if (isInvoice) {
      setIsInvoice(true);
    }
  }, []);

  useEffect(() => {
    if (paymentType === 'saved') {
      onSelectPayment(selectedPayment);
    }

    if (isInvoice && paymentType === 'invoice' && onSelectPayment) {
      onSelectPayment(null);
    }
  }, [paymentType]);

  useEffect(() => {
    if (paymentMethods.length > 0 && formToken !== null) {
      setPaymentType('saved');
    }
  }, [paymentMethods]);

  function getAuthorizeToken() {
    return useGet(`/advertisers/${adContext.id}/authorize_token`)
      .then(res => {
        console.log('res from token', res);

        if (res.status === 'Ok') {
          setFormToken(res.token);
        }

        return res;
      })
      .catch(err => console.error(err));
  }

  // TODO: figure out if we need this
  // function getPaymentProfiles() {
  //   return useGet(`/payment_profiles`)
  //     .then(res => {
  //       console.log('res from payment profiles', res);

  //       if (res && res.results && res.results.length > 0) {
  //         setPaymentMethods(res.results.reverse());
  //       } else {
  //         return getProfilesRefresh();
  //       }

  //       return res;
  //     })
  //     .catch(err => console.error(err));
  // }

  function getProfilesRefresh() {
    return useGet(`/payment_profiles/refresh`)
      .then(res => {
        console.log('res from refreshed profiles', res);

        if (res && res.results && res.results.length > 0) {
          const reversed = res.results.reverse();
          handleSelectPayment(reversed[0].url);
          setPaymentMethods(reversed);
        }

        return res;
      })
      .catch(err => console.error(err));
  }

  const isValidMessage = o => typeof o === 'object' && o !== null;

  const handlePaymentType = event => {
    setPaymentType(event.target.value);

    if (onPaymentType) {
      onPaymentType(event.target.value);
    }
  };

  const handleSelectPayment = url => {
    if (onSelectPayment) {
      onSelectPayment(url);
    }

    setSelectedPayment(url);
  };

  const handleCloseForm = () => {
    setIsEnterCard(false);
  };

  const renderPaymentMethod = method => {
    const cardType = method.card_type === 'AmericanExpress'
      ? 'AMEX'
      : method.card_type;

    return (
      <ListItem
        button
        key={method.id}
        className={classes.paymentListItem}
        selected={selectedPayment === method.url}
        onClick={() => handleSelectPayment(method.url)}
      >
        <ListItemIcon>
          <img src={paymentIcons[cardType.toLowerCase()]} />
        </ListItemIcon>

        <ListItemText
          primary={
            <Typography className={classes.methods} variant="body2">
              {`${cardType} ending in `}
              {`${formatLastFour(method.card_number)}`}
              &nbsp;{` | `}&nbsp; {`Expiration ${method.expiration_date}`}
            </Typography>
          }
        />
      </ListItem>
    )
  };

  return (
    <Box height="100%" width="100%">
      <Box className={classes.radioGroup}>
        <RadioGroup value={paymentType} onChange={handlePaymentType}>
          <FormControlLabel
            className={classes.radios}
            control={<Radio disabled={paymentMethods.length === 0} />}
            label="Saved Cards"
            value="saved"
          />

          <Divider />

          <Collapse in={paymentType === 'saved'}>
            <List className={classes.payments} dense>
              {paymentMethods.length > 0 &&
                paymentMethods.map(p => renderPaymentMethod(p))}
            </List>
          </Collapse>

          {isInvoice &&
            <>
              <Divider />

              <FormControlLabel
                className={classes.radios}
                control={<Radio />}
                label="Invoice"
                value="invoice"
              />
            </>}
        </RadioGroup>

        <Box mt={2}>
          <Button
            color="secondary"
            onClick={() => setIsEnterCard(true)}
            startIcon={<AddIcon />}
            size="small"
          >
            Enter a New Card
          </Button>
        </Box>
      </Box>

      {/*
        Authorize Net - New Payment Form IFrame
      */}
      <Dialog
        fullWidth
        className={classes.dialog}
        maxWidth="md"
        onClose={handleCloseForm}
        open={isEnterCard}
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Enter a new card</Typography>

            <IconButton
              onClick={handleCloseForm}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <form
            ref={submitRef}
            method="POST"
            action={authorizeAddPayment}
            target="new-auth-net"
          >
            <input name="token" type="hidden" value={formToken} />

            <Button
              style={{ display: 'none' }}
              type="submit"
            >
              Add Payment
            </Button>
          </form>

          <div className={classes.iframe} style={{ height: 540 }}>
            <iframe
              ref={iframeRef}
              id="new-auth-net"
              name="new-auth-net"
              frameBorder={0}
              height="100%"
              width="100%"
            />
          </div>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

AuthorizeNetIFrame.propTypes = {
  onCancel: PropTypes.func,
  onMessage: PropTypes.func,
  onReceiveCommunication: PropTypes.func,
  onResize: PropTypes.func,
  onSelectPayment: PropTypes.func,
  onPaymentType: PropTypes.func,
  isInvoice: PropTypes.bool,
  setIsInvoice: PropTypes.func,
};

export default AuthorizeNetIFrame;
