import moment from 'moment-timezone';

export const useDate = () => {
  const formatDateTime = (date, time) => {
    if (date && time) {
      const hour = new Date(time).getHours();
      const minute = new Date(time).getMinutes();
      const dateTime = moment(date).hour(hour).minute(minute);

      return moment(dateTime).utc().format();
    }

    return null;
  };

  const formatEndDate = endDate => {
    if (Date.parse(endDate)) {
      return moment(endDate).format('l');
    }

    return 'No End Date';
  };

  return {
    formatDateTime,
    formatEndDate,
  };
};
