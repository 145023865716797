import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  makeStyles
} from '@material-ui/core';

import ManageBudget from './ManageBudget'

const useStyles = makeStyles(() => ({
  name: {
    marginTop: 6,
  },
}));

const AdGroupBudgeting = ({
  adGroup,
  campaign,
  cpm,
  isError,
  isNew,
  isSingleEdit,
  setAdGroups,
  setCpm,
  setCampaignBudget,
  setCampaignHoldOut,
  setDailyBudget,
  setDisplays,
  setIsBudgetError,
}) => {
  const classes = useStyles();

  return (
    <Box mt={2} width="100%">
      <Grid container alignItems="center" justify="space-between" spacing={2}>
        <Grid item xs={12}>
          <Box mt={3}>
            <ManageBudget
              isNew={isNew}
              isSingleEdit={isSingleEdit}
              adGroup={adGroup}
              currentCampaign={campaign}
              setAdGroups={setAdGroups}
              setCampaignBudget={setCampaignBudget}
              setCampaignHoldOut={setCampaignHoldOut}
              setDailyBudget={setDailyBudget}
              setDisplays={setDisplays}
              setIsBudgetError={setIsBudgetError}
            />
          </Box>
        </Grid>

        <Box my={4} mb={3} width="100%">
          <Divider />
        </Box>

        <Grid container item xs={12}>
          <Grid item xs={2}>
            <TextField
              id="cpm-bid"
              error={isError && (!cpm || cpm === '')}
              className={classes.name}
              color="secondary"
              fullWidth
              label="Max CPM Bid"
              variant="outlined"
              value={cpm}
              type="number"
              onChange={(event) => setCpm(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputProps: { min: 0 }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

AdGroupBudgeting.propTypes = {
  isError: PropTypes.bool,
  isNew: PropTypes.bool,
  isSingleEdit: PropTypes.bool,
  adGroup: PropTypes.object,
  campaign: PropTypes.object,
  cpm: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  setAdGroups: PropTypes.func,
  setCpm: PropTypes.func,
  setCampaignBudget: PropTypes.func,
  setCampaignHoldOut: PropTypes.func,
  setDailyBudget: PropTypes.func,
  setDisplays: PropTypes.func,
  setIsBudgetError: PropTypes.func,
};

export default AdGroupBudgeting;
