import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse, Container } from '@material-ui/core';

import DemoTargeting from './DemoTargeting';
import TargetingSegments from './TargetingSegments';

const MemoDemoTargeting = memo(DemoTargeting);
const MemoTargetingSegments = memo(TargetingSegments);

const AdGroupDemo = ({
  age,
  gender,
  income,
  isAdvanced,
  segments,
  setAge,
  setGender,
  setIncome,
  setIsAdvanced,
  setSegments,
  setIsDefaultDemo,
  setIsNoBidFees,
}) => {
  return (
    <Box mt={2} width="100%">
      <Container disableGutters maxWidth={false}>
        <Box mb={isAdvanced ? 4 : 0}>
          <MemoDemoTargeting
            isEditing
            age={age}
            gender={gender}
            income={income}
            isAdvanced={isAdvanced}
            setAge={setAge}
            setGender={setGender}
            setIncome={setIncome}
            setIsAdvanced={setIsAdvanced}
            setIsDefaultDemo={setIsDefaultDemo}
            setIsNoBidFees={setIsNoBidFees}
          />
        </Box>

        {/* Targeting Segments Behaviors & Interests */}
        <Collapse in={isAdvanced}>
          <MemoTargetingSegments
            isEditing
            segments={segments}
            setSegments={setSegments}
          />
        </Collapse>
      </Container>
    </Box>
  );
};

AdGroupDemo.propTypes = {
  age: PropTypes.array,
  gender: PropTypes.string,
  handleDemoData: PropTypes.func,
  income: PropTypes.array,
  isAdvanced: PropTypes.bool,
  segments: PropTypes.array,
  setAge: PropTypes.func,
  setGender: PropTypes.func,
  setIncome: PropTypes.func,
  setIsAdvanced: PropTypes.func,
  setSegments: PropTypes.func,
  setIsDefaultDemo: PropTypes.func,
  setIsNoBidFees: PropTypes.func,
};

export default AdGroupDemo;
