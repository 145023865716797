import React from 'react';
import { Themes } from '../constants';

const AdvertiserContext = React.createContext({
  id: 0,
  name: '',
  theme: Themes.NBCU,
});

AdvertiserContext.displayName = 'AdvertiserContext';

export default AdvertiserContext;
