import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { api } from '../../connection/TVSApi';
import { useAPI } from '../hooks/api';
import AdvertiserContext from '../AdvertiserContext';
import AppHeader from '../AppHeader';
import ManageTracking from '../ManageTracking';
import UserContext from '../../providers/UserContext';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: 'none'
  },
  campaignContainerSpacing: {
    padding: theme.spacing(2),
    maxWidth: 'none'
  },
  paper: {
    padding: theme.spacing(5),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  title: {
    marginTop: 0,
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ff4400',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
}));

const QUICKSIGHT_ALL = '[ALL]';

const TrackingPage = props => {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);
  const user = useContext(UserContext);
  const { oktaAuth } = useOktaAuth();
  const { useGet } = useAPI();
  const history = useHistory();

  const [embedUrl, setEmbedUrl] = useState(null);
  const [qsParams, setQsParams] = useState({ campaignId: QUICKSIGHT_ALL });
  const [campaignId, setCampaignId] = useState(QUICKSIGHT_ALL);
  const [qsDashboard, setQsDashboard] = useState({});
  const [campaigns, setCampaigns] = useState([]);
  const [isTracking, setIsTracking] = useState(false);

  useEffect(() => {
    if (!user.is_tvsci_employee && adContext.cost_model === 'CPA') {
      history.push("/reports");
    }
  }, [user, adContext]);

  const getCampaignsData = url => {
    return useGet(url)
      .then(response => {
        setCampaigns(response.results);
        return response;
      });
  };

  const getData = url => (
    api
      .get(url, {
        'X-TVS-AdvertiserContext': adContext.id,
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      })
      .then(response => {
        setEmbedUrl(response.data.embedUrl);
        return response;
      })
      .catch((error) => console.error(error))
  );

  const setQuicksightFilter = newValue => {
    setCampaignId(newValue);

    const params = {
      ...qsParams,
      campaignId: newValue,
    };

    setQsParams(params);
    qsDashboard.setParameters({ ...params });
  };

  const handleOpenTracking = () => {
    setIsTracking(true);
  };

  const handleCloseTracking = () => {
    setIsTracking(false);
  };

  useEffect(() => {
    if (adContext && adContext.id) {
      getData('/reporting-embed-url?report=tracking_events');
      getCampaignsData('/campaigns/');
    }
  }, [adContext]);

  useEffect(() => {
    if (embedUrl) {
      handleQsEmbed(embedUrl);
    }
  }, [embedUrl]);

  const handleQsEmbed = async (embedUrl) => {
    // https://www.npmjs.com/package/amazon-quicksight-embedding-sdk#step-2-configure-embedding
    const quicksightOptions = {
      url: embedUrl,
      container: '#quicksightEmbedContainer',
      height: 'AutoFit',
    };

    if (document.getElementById("quicksightEmbedContainer").firstChild) {
      document.getElementById("quicksightEmbedContainer").firstChild.remove()
    }

    const dashboard = await embedDashboard(quicksightOptions);

    setQsDashboard(dashboard);
  };

  return (
    <AppHeader history={props.history}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              className={clsx(classes.paper, 'background-img-inline-example')}
            >
              <Toolbar disableGutters>
                <Typography variant="h2" component="div">
                  Tracking
                </Typography>
              </Toolbar>

              <Container className={classes.container}>
                <Grid container>
                  <Grid item xs={6}>
                    <InputLabel id="campaign-select-label">Campaign</InputLabel>

                    <Select
                      labelId="campaign-select-label"
                      id="campaign-select"
                      value={campaignId}
                      onChange={event =>
                        setQuicksightFilter(event.target.value)
                      }
                    >
                      <MenuItem value={QUICKSIGHT_ALL}>
                        All
                      </MenuItem>

                      {campaigns.map(campaign => (
                        <MenuItem key={campaign.id} value={campaign.id}>
                          {campaign.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid container justify="flex-end" item xs={6}>
                    <Button
                      color="secondary"
                      onClick={() => handleOpenTracking()}
                      startIcon={<AddIcon size="small" />}
                    >
                      Create Tracking Code
                    </Button>
                  </Grid>
                </Grid>
              </Container>

              <div id="quicksightEmbedContainer"></div>
            </Paper>
          </Grid>
        </Grid>

        {isTracking &&
          <ManageTracking
            isOpen={isTracking}
            onClose={handleCloseTracking}
            exposureLid={adContext.exposureLid}
            outcomeLid={adContext.outcomeLid}
          />}
      </Container>
    </AppHeader>
  );
};

TrackingPage.propTypes = {
  history: PropTypes.object,
};

export default TrackingPage;
