import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

const useStyles = makeStyles(() => ({
  paper: {
    background: 'rgba(38, 47, 60, 0.1)',
    border: '1px solid #727383',
    height: '100%',
  },
  submenu: {
    zIndex: 5,
    fontSize: `0.75rem`,
    fontSeight: 500,
    background: '#181c20',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  submenuText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  include: {
    color: `#1dafff`,
    fontSize: '.75rem',
    fontWeight: 500,
    minWidth: 124,
  },
  exclude: {
    color: `#ff6577`,
    fontSize: '.75rem',
    fontWeight: 500,
    minWidth: 124,
  },
  paperBody: {
    padding: '8px 20px',
    overflowY: 'auto',
    minHeight: '180px',
  },
}));

const IncludePanel = ({
  title,
  children,
  isAllExcluded,
  onToggleIncludeAll,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        className={classes.submenu}
      >
        <Typography className={classes.submenuText}>{title}</Typography>

        {isAllExcluded && (
          <Button
            onClick={() => onToggleIncludeAll(isAllExcluded)}
            size="small"
            className={classes.include}
            startIcon={
              <AddCircleOutlineOutlinedIcon
                style={{
                  width: 15,
                  height: 15,
                }}
              />
            }
          >
            Include All
          </Button>
        )}

        {!isAllExcluded && (
          <Button
            onClick={() => onToggleIncludeAll(isAllExcluded)}
            size="small"
            className={classes.exclude}
            startIcon={
              <NotInterestedIcon
                style={{
                  width: 15,
                  height: 15,
                }}
              />
            }
          >
            Exclude All
          </Button>
        )}
      </Grid>
      <Grid
        container
        className={classes.paperBody}
      >
        {children}
      </Grid>
    </Paper>
  );
};

IncludePanel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isAllExcluded: PropTypes.bool.isRequired,
  onToggleIncludeAll: PropTypes.func.isRequired,
};

export default IncludePanel;
