import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Container, Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    paddingTop: spacing(0),
    paddingBottom: spacing(4),
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(5px)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1100,
  },
  paper: {
    // marginTop: spacing(-2),
    paddingTop: spacing(14),
    paddingLeft: spacing(6),
    paddingRight: spacing(6),
    paddingBottom: spacing(8),
    position: 'relative',
    minHeight: 700,
  },
}));

// TODO:
// - Use Material UI Modal component
const ModalOverlay = props => {
  const classes = useStyles();
  const { isOpen, onClose, className } = props;

  const handleClose = event => {
    // Close modal if user presses Esc
    if (event.key && event.key === 'Escape') {
      onClose();
    }
  };

  // Bind keydown event on load
  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', handleClose, true);
    }

    return () => {
      window.removeEventListener('keydown', handleClose, true);
    };
  }, [isOpen]);

  return (
    <div
      id="modal-overlay"
      onKeyDown={handleClose}
      onClick={handleClose}
      className={clsx(classes.overlay, className)}
    >
      <Container className={classes.container}>
        <Paper
          className={clsx(classes.paper, '--background-colorbar')}
          elevation={12}
        >
          {props.children}
        </Paper>
      </Container>
    </div>
  );
};

ModalOverlay.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ModalOverlay;
