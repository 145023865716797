import React, { useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Box,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FileUploader from './FileUploader';
import ManageBudget from './ManageBudget';
import ModalWrapper from './ModalWrapper';
import Title from './Title';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from './ui/Accordians';

import DisplayPreview from './DisplayPreview';
import { useAPI } from './hooks/api';
import { useLoader } from './hooks/loader';
import { useUpload } from './hooks/upload';

const useStyles = makeStyles(({ palette }) => ({
  cancel: {
    color: palette.grey.main,
  },
  container: {
    position: 'relative',
    height: 'auto',
  },
  footer: {
    backgroundColor: '#e5e7eb',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  name: {
    marginTop: 6,
  },
  panelHeader: {
    backgroundColor: '#e5e7eb',
  },
  panels: {
    height: 720,
    minHeight: 720,
  },
  preview: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

// Edit sections for ad groups
const editPanels = [
  { title: 'Display Basics', id: 'basics' },
  { title: 'Creative', id: 'creatives' },
  { title: 'Budgeting', id: 'budget' },
];

const compareArrays = (data1, data2) => {
  if (data1.length !== data2.length) {
    return true;
  }

  let hasEdited = false;

  data1.forEach(d => {
    if (!data2.includes(d)) {
      hasEdited = true;
    }
  });

  return hasEdited;
};

const ManageDisplay = props => {
  const classes = useStyles();

  const {
    campaign,
    display,
    displayRef,
    isOpen,
    isModal,
    isNew,
    onClose,
    setHasSaved,
  } = props;

  const { useGet, usePost, usePatch } = useAPI();
  const { isLoading, setIsLoading } = useLoader();
  const { isLoading: isUploading, setIsLoading: setIsUploading } =
    useLoader();
  const { deleteFile } = useUpload();
  const { enqueueSnackbar } = useSnackbar();

  const formMethods = useForm({
    defaultValues: {
      creatives: []
    },
    mode: 'onBlur',
  });

  const { watch, formState, setValue } = formMethods;

  const creatives = watch('creatives');

  console.log(formState.isDirty, formState.dirtyFields); // HACK: make sure formState is read before render to enable the Proxy [https://react-hook-form.com/advanced-usage#FormProviderPerformance]

  const {
    creatives: displayCreatives,
    daily_budget,
    name: displayName,
    id: displayId,
  } = display;

  const initialName = () => display && displayName && displayName !== ''
    ? displayName
    : 'Ad Exposure Retargeting - Display';

  const [name, setName] = useState(initialName());
  const [isExpanded, setIsExpanded] = useState({
    basics: true,
    creatives: true,
    budget: true,
  });

  // Internal state
  const [adGroups, setAdGroups] = useState([]);
  const [displays, setDisplays] = useState([]);
  const [currentPreview, setCurrentPreview] = useState(null);
  const [isCreativePreview, setIsCreativePreview] = useState(false);

  const [creativeUrls, setCreativeUrls] = useState(displayCreatives);

  const initialBudget = () =>
    daily_budget && daily_budget !== '00' && daily_budget !== ''
      ? daily_budget
      : '';

  const [dailyBudget, setDailyBudget] = useState(initialBudget());
  const [deleteQueue, setDeleteQueue] = useState([]);

  const initialCampaignBudget = () => campaign && campaign.daily_budget
    ? campaign.daily_budget
    : '';
  const [campaignBudget, setCampaignBudget] = useState(
    initialCampaignBudget()
  );

  // Allows access to handleUpdateDisplay from parent
  useImperativeHandle(displayRef, () => ({
    handleUpdateDisplay,
  }));

  useEffect(() => {
    getCreatives();
  }, []);


  useEffect(() => {
    setCreativeUrls(() => {
      const urls = creatives.map(c => c.url);
      return [...urls];
    });
  }, [creatives]);

  function getCreatives() {
    const creativeRequests = creativeUrls.map(c => useGet(c, true));

    return Promise.all(creativeRequests)
      .then(res => {
        console.log('res from creative urls', res);
        setValue('creatives', res);
        return res;
      })
      .catch(error => {
        console.error(error);
      });
  }

  const validateDisplay = () => {
    const data = {};

    const hasEditedCreatives = compareArrays(creativeUrls, displayCreatives);

    if (hasEditedCreatives) {
      data.creatives = creativeUrls;
    }

    if (name.trim() !== displayName.trim()) {
      data.name = name;
    }

    if (dailyBudget !== daily_budget) {
      data.daily_budget = dailyBudget;
    }

    console.log('dataObj', data);

    return data;
  };

  const handleExpanded = panel => event => {
    if (event && event.target != null) {
      setIsExpanded(x => ({
        ...x,
        [panel]: !x[panel],
      }));
    }
  };

  const handleClosePreview = () => {
    setCurrentPreview(null);
    setIsCreativePreview(false);
  };

  const handleDeleteQueue = id => {
    setDeleteQueue(prev => [...prev, id]);
  };

  const saveDisplays = async (data) => {
    const saveCreativeRequests = data.map(creative => usePatch(`/static_display_creatives/${creative.id}`, creative));
    const savedCreatives = await Promise.all(saveCreativeRequests);

    return savedCreatives.map(creative => creative.data);
  };

  const handleDeleteFile = key => {
    return deleteFile(`/static_display_creatives/${key}`)
      .then(response => {
        console.log('response from removing file', response);
        return response;
      })
      .catch(error => console.log('Error deleting file:', error));
  };

  const handleDeleteCreatives = () => {
    const deleteRequests = deleteQueue.map(d => handleDeleteFile(d));

    return Promise.all(deleteRequests)
      .then(responses => {
        console.log('response from delete creative', responses);
        return responses;
      })
      .catch(error => {
        console.warn(error);
        return error;
      });
  };

  const handleSaveDisplay = data => {
    return usePatch(`/static_display_lineitems/${displayId}`, data)
      .then(response => {
        console.log('response from manage display', response);
        return response;
      })
      .catch(error => {
        console.warn(error);
        return error;
      });
  };

  const handleCreateDisplay = async data => {
    const dataObj = {
      ...data,
      active: false,
      campaign: campaign.url,
      creatives: creativeUrls,
      draft: false,
      end_date: campaign.end_date,
      name,
      start_date: campaign.start_date,
    };

    try {
      const response = await usePost('/static_display_lineitems/', dataObj);

      if (response) {
        console.log('Res from creating display', response);
      }

      return response;
    } catch (error) {
      console.log('Error in creating display', error);
      return error;
    }
  };

  const handleCampaignBudget = () => {
    return usePatch(`/campaigns/${campaign.id}`, {
      daily_budget: campaignBudget,
    });
  };

  const handleSyncBudgets = () => {
    if (adGroups.length > 0 || displays.length > 0) {
      const adGroupRequests = adGroups.map(a =>
        usePatch(`lineitems/${a.id}`, {
          active: a.active,
          daily_budget: a.daily_budget,
        })
      );

      const displayRequests = displays.map(d =>
        usePatch(`static_display_lineitems/${d.id}`, {
          active: d.active,
          daily_budget: d.daily_budget,
        })
      );

      return Promise.all([...adGroupRequests, ...displayRequests])
        .then(responses => {
          console.log('Res from adGroups budgets', responses);
          return responses;
        })
        .catch(error => {
          console.log('Error saving budgets in AdGroups', error);
        });
    }
  };

  // Final API call to patch ad group
  const handleUpdateDisplay = () => {
    setIsLoading(true);

    // If ad group doesn't have any creatives, return
    if (creativeUrls && creativeUrls.length === 0) {
      setIsLoading(false);
      enqueueSnackbar('Display must have at least one creative', {
        variant: 'warning',
      });
      return;
    }

    // If ad group doesn't have any creatives, return
    if ((!dailyBudget || dailyBudget === '') && isNew) {
      setIsLoading(false);
      enqueueSnackbar('Display must have a budget allocated', {
        preventDuplicate: true,
        variant: 'warning',
      });
      return;
    }

    // Validate all changes if any
    const dataObj = validateDisplay();

    // If no changes are made, return
    if (Object.keys(dataObj).length === 0) {
      setIsLoading(false);
      return;
    }

    const request = isNew
      ? handleCreateDisplay({
        ...dataObj,
        campaign: campaign.url,
      }) : handleSaveDisplay(dataObj);

    return request
      .then(res => {
        if (res && res.status && [200, 201].indexOf(res.status) > -1) {
          if (deleteQueue.length) {
            return handleDeleteCreatives()
              .then(res => res)
              .catch(err => {
                console.warn(err);
                setIsLoading(false);
                return err;
              });
          }
        }

        return res;
      })
      .then(res => {
        if (res) {
          if (
            campaign.daily_budget &&
            campaignBudget !== '' &&
            campaign.daily_budget !== campaignBudget
          ) {
            return handleCampaignBudget();
          }
        }

        return res;
      })
      .then(res => {
        if (res && formState.dirtyFields.creatives) {
          return saveDisplays(creatives);
        }

        return res;
      })
      .then(res => {
        if (res) {
          if (adGroups.length > 0) {
            return handleSyncBudgets()
              .then(res => res)
              .catch(err => {
                console.error(err);
                setIsLoading(false);
                return err;
              });
          }
        }

        return res;
      })
      .then(res => {
        if (res) {
          setHasSaved(true);
          setIsLoading(false);
          onClose();
        }

        return res;
      })
      .catch(err => {
        console.error(err);
        setIsLoading(false);
        return err;
      });
  };

  const renderBasics = () => (
    <>
      <Grid container alignItems="center" justify="space-between" spacing={2}>
        <Grid item xs={5}>
          <Box mt={3}>
            <TextField
              autoFocus
              className={classes.name}
              color="secondary"
              fullWidth
              id="ad-group-name"
              label="Name"
              placeholder="Enter a name for your ad group"
              variant="outlined"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );

  const renderCreatives = () => (
    <FileUploader
      accept="image/jpeg, image/png"
      creativeUrls={creativeUrls}
      handleDeleteFile={handleDeleteQueue}
      setCreativeUrls={setCreativeUrls}
      setCurrentPreview={setCurrentPreview}
      setIsCreativePreview={setIsCreativePreview}
      setIsLoading={setIsUploading}
      setIsUploading={val => {
        setIsUploading(val)

        if (props.setIsUploading) {
          props.setIsUploading(val)
        }
      }}
      type="image"
      uploadView={display.creatives && display.creatives.length > 0 && 'start'}
    />
  );

  const renderBudgeting = () => (
    <Box mt={2} pb={8} width="100%">
      <Grid container alignItems="center" justify="space-between" spacing={2}>
        <Grid item xs={12}>
          <Box mt={3}>
            <ManageBudget
              isNew={isNew}
              isNewDisplay={isNew}
              adGroup={!isNew ? display : { ...display, id: '00-new' }}
              currentCampaign={campaign}
              setAdGroups={setAdGroups}
              setCampaignBudget={setCampaignBudget}
              setDailyBudget={setDailyBudget}
              setDisplays={setDisplays}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  // Handle component renders
  const renderPanelDetails = id => {
    switch (id) {
      case 'basics':
        return renderBasics();
      case 'creatives':
        return renderCreatives();
      case 'budget':
        return renderBudgeting();
      default:
        return null;
    }
  };

  // Render an accordion panel for each editable section
  const renderPanel = panel => (
    <Accordion
      expanded={isExpanded[panel.id]}
      onChange={handleExpanded(panel.id)}
      TransitionProps={{ unmountOnExit: panel.id !== 'demo' }}
    >
      <AccordionSummary aria-controls="adgroup-panel" id={`edit-${panel.id}`}>
        <Grid
          className={classes.panelHeader}
          container
          alignItems="center"
          justify="space-between"
        >
          <Grid item>
            <Typography>{panel.title}</Typography>
          </Grid>

          <Grid item>
            {isExpanded[panel.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        {renderPanelDetails(panel.id)}
      </AccordionDetails>
    </Accordion>
  );

  const renderContent = () => (
    <Box className={classes.container} width="100%">
      <Box
        className={classes.panels}
        display="flex"
        flexDirection="column"
        width="100%"
        px={0}
        pb={22}
      >
        {isModal &&
          <Box mb={2}>
            <Title>Manage Display - {name}</Title>
          </Box>}

        <Box flexGrow={1} width="100%">
          <Grid container spacing={3}>
            {editPanels.map((s, i) => (
              <Grid key={s.id} item xs={12}>
                {s.id === 'budget'
                  ? (
                    <Box pb={10}>
                      {renderPanel(s, i)}
                    </Box>
                  ) : renderPanel(s, i)}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );

  const renderWithModal = () => (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      isUploading={isUploading}
      onSubmit={handleUpdateDisplay}
      submit="Save Display"
      hasSubmit
      aria-labelledby="edit-ad-group-dialog"
    >
      {renderContent()}

      {isCreativePreview && (
        <Box className={classes.preview} width="100%" height="100%">
          <DisplayPreview
            handleClose={handleClosePreview}
            name={currentPreview.name}
            url={currentPreview.preview_url}
          />
        </Box>
      )}
    </ModalWrapper>
  );

  return (
    <FormProvider {...formMethods}>
      {isModal ? renderWithModal() : renderContent()}
    </FormProvider>
  );
};

ManageDisplay.propTypes = {
  campaign: PropTypes.object,
  display: PropTypes.object,
  displayRef: PropTypes.object,
  isOpen: PropTypes.bool,
  isModal: PropTypes.bool,
  isNew: PropTypes.bool,
  onClose: PropTypes.func,
  setHasSaved: PropTypes.func,
  setIsUploading: PropTypes.func,
};

export default ManageDisplay;
