import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(({ spacing }) => ({
  divider: {
    borderColor: '#fff',
  },
  header: {
    marginTop: spacing(5),
    marginBottom: spacing(2),
  },
  icon: {
    color: '#70767f',
  },
  player: {
    maxWidth: 870,
    margin: '0 auto',
  },
  preview: {
    backgroundColor: 'rgba(38, 47, 60, 0.95)',
    position: 'absolute',
    top: -3,
    bottom: 0,
    left: -16,
    right: 0,
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewWrap: {
    height: '100%',
    width: '90%',
  },
  title: {
    color: '#fff',
  },
}));

const CreativePreview = ({ handleClose, name, url }) => {
  const classes = useStyles();

  return (
    <Box className={classes.preview}>
      <Box className={classes.previewWrap}>
        <Grid container spacing={3}>
          <Grid
            container
            item
            className={classes.header}
            alignItems="center"
            justify="space-between"
            xs={12}
          >
            <Grid container item alignItems="center" xs>
              <Grid item>
                <IconButton
                  onClick={handleClose}
                >
                  <ArrowBackIosIcon className={classes.icon} />
                </IconButton>
              </Grid>

              <Grid item alignItems="center">
                <Typography className={classes.title} variant="h3">
                  Preview Creative: {name}
                </Typography>
              </Grid>
            </Grid>

            <Grid item alignContent="center">
              <IconButton
                onClick={handleClose}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid className={classes.player} item xs={12}>
              <ReactPlayer
                playing
                controls
                url={url}
                width="100%"
                height="auto"
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

CreativePreview.propTypes = {
  handleClose: PropTypes.func,
  name: PropTypes.string,
  url: PropTypes.string,
};

export default CreativePreview;
