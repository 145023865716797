import { useContext, useEffect, useState } from 'react';

import { useAPI } from './api';
import { useDate } from './date';
import AdvertiserContext from '../AdvertiserContext';
import { Themes } from '../../constants';

export const useCampaigns = () => {
  const adContext = useContext(AdvertiserContext);
  const { useGet, usePatch } = useAPI();
  const { formatDateTime } = useDate();

  const [isCapAds, setIsCapAds] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isExperiment, setIsExperiment] = useState(false);
  const [isGoals, setIsGoals] = useState(false);
  const [isIncremental, setIsIncremental] = useState(false);
  const [freqCaps, setFreqCaps] = useState([]);
  const [adCapCount, setAdCapCount] = useState(0);
  const [adCapFreq, setAdCapFreq] = useState(0);
  const [adCapUnit, setAdCapUnit] = useState('DAY');
  const [holdOut, setHoldOut] = useState(null);
  const [percent, setPercent] = useState('5');
  const [plan, setPlan] = useState('2_WAY_SPLIT');
  const [selectedGoal, setSelectedGoal] = useState('CPA');
  const [cpa, setCPA] = useState('');
  const [displayBudget, setDisplayBudget] = useState(0);

  useEffect(() => {
    const nextHoldOut = adContext.theme === Themes.DEFAULT ? '1PCT_HOLDOUT' : null;

    setHoldOut(nextHoldOut);
  }, [adContext.theme]);

  const getCampaignData = async (campaignId) => {
    if (campaignId && adContext.id) {
      try {
        const campaignData = await useGet(`/campaigns/${campaignId}`);

        if (campaignData.display_pct != null) {
          handleDisplay(true);
          setPercent(campaignData.display_pct);
        }

        if (campaignData.freq_caps && campaignData.freq_caps.length > 0) {
          setFreqCaps(campaignData.freq_caps);
        }

        if (
          campaignData.experiment_type &&
          campaignData.experiment_type !== ''
        ) {
          handleIncremental();
          setHoldOut(campaignData.experiment_type)
        }

        if (campaignData.goal_amt && campaignData.goal_type) {
          handleGoals();
          handleCPA(campaignData.goal_amt);
          handleSelectedGoal(campaignData.goal_type);
        }

        return campaignData;
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCapAds = (value) => {
    setIsCapAds(value);
  };

  const handleDisplay = () => {
    setIsDisplay(prev => !prev);
  };

  const handleGoals = () => {
    setIsGoals(prev => !prev);
  };

  const handleExperiment = (value) => {
    setIsExperiment(value);
  };

  const handleIncremental = () => {
    if (isGoals || isCapAds || isDisplay) {
      setIsCapAds(false);
      setIsDisplay(false);
      setIsGoals(false);
    }

    setIsIncremental(prev => !prev);
  };

  const handleSelectedGoal = value => {
    setSelectedGoal(value);
  };

  const handleCPA = value => {
    setCPA(value);
  };

  const updateCampaign = (id, data) => {
    return usePatch(`/campaigns/${id}/`, data)
      .then(response => {
        console.log('response from edit campaign: ', response);
        return response;
      })
      .catch(error => {
        console.error('error in edit campaign', error)
        return error;
      });
  };

  const saveCampaignProgress = async (save, id) => {
    if (save && save.step) {
      if (!save.exit && save.next && id) {
        return await usePatch(`/campaigns/${id}`, {
          progress: save.next,
        });
      }

      if (save.exit) {
        return await usePatch(`/campaigns/${id}/`, {
          progress: save.step,
        });
      }
    }
  };

  const launchCampaign = async ({
    active,
    campaignId,
    daily_budget,
    day_parts,
    endDate,
    endTime,
    name,
    noEndDate,
    payment_profile,
    nbcu_purchase_order,
    startDate,
    startTime,
  }) => {
    const adUrl = adContext.url;
    const startDateTime = formatDateTime(startDate, startTime);
    let endDateTime = null;

    // Set end date and time if exists
    if (!noEndDate) {
      endDateTime = formatDateTime(endDate, endTime);
    }

    // Set up the data
    const dataObj = {
      active,
      advertiser: adUrl,
      daily_budget,
      day_parts,
      draft: false,
      end_date: endDateTime,
      experiment_type: null,
      freq_cap_duration: null,
      freq_cap_impressions: null,
      freq_cap_type: null,
      goal_amt: null,
      goal_type: null,
      name,
      payment_profile,
      nbcu_purchase_order,
      start_date: startDateTime,
    };

    // Check toggled inputs and
    // update data if exists
    if (isCapAds) {
      dataObj.freq_cap_duration = adCapFreq;
      dataObj.freq_cap_impressions = adCapCount;
      dataObj.freq_cap_type = adCapUnit;
    }

    if (isIncremental) {
      dataObj.experiment_type = holdOut;
    }

    if (isGoals) {
      dataObj.goal_amt = cpa;
      dataObj.goal_type = selectedGoal;
    }

    if (isDisplay) {
      dataObj.display_pct = percent;
    }

    console.log('dataObj in campaign', dataObj);

    try {
      const response = await usePatch(`/campaigns/${campaignId}/`, dataObj);
      console.log('Response in launch campaign', response);
      return response;
    } catch (error) {
      console.error('Error in launching Campaign', error);
      return error;
    }
  };

  // Expose singleton campaign object
  return {
    campaign: {
      isCapAds,
      isDisplay,
      isExperiment,
      isGoals,
      isIncremental,
      handleCapAds,
      handleDisplay,
      handleExperiment,
      handleGoals,
      handleIncremental,
      adCap: {
        count: adCapCount,
        freq: adCapFreq,
        unit: adCapUnit,
        setCount: setAdCapCount,
        setFreq: setAdCapFreq,
        setUnit: setAdCapUnit,
        freqCaps,
        setFreqCaps,
      },
      incremental: {
        holdOut,
        setHoldOut,
      },
      display: {
        percent,
        setPercent,
        displayBudget,
        setDisplayBudget,
      },
      experiment: {
        plan,
        setPlan,
      },
      goals: {
        optimize: selectedGoal,
        cpa,
        handleOptimize: handleSelectedGoal,
        handleCPA,
      },
    },
    getCampaignData,
    launchCampaign,
    saveCampaignProgress,
    updateCampaign,
  };
};
