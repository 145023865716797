import { useHistory } from 'react-router-dom';

export const useSaveExit = () => {
  const history = useHistory();

  const saveProgress = async (save, step, handleSave, callback) => {
    if (save && save.step && save.step === step) {
      const res = await handleSave();

      if (res) {
        return callback(false, false, false);
      }

      return res;
    }
  };

  const saveAndExit = (save, step) => {
    // Handle Save & Exit button click
    if (save && save.step && save.step === step) {
      if (save.exit) {
        history.push('/home');
      }
    }
  };

  return {
    saveAndExit,
    saveProgress,
  };
};
