import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  error: {
    color: "#f44336",
    padding: '5px 0',
  },
}));

const ErrorMessage = ({ children }) => {
  const styles = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      wrap="nowrap"
      spacing={1}
      className={styles.error}
    >
      <Grid item>
        <WarningIcon fontSize="inherit" />
      </Grid>
      <Grid item>
        {children}
      </Grid>
    </Grid>
  );
};

ErrorMessage.propTypes = {
  children: PropTypes.node,
};

export default ErrorMessage;
