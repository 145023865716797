import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { convertToTime } from './util';
import freqCapIcon from '../images/icon-config-freqcaps.png'

const useStyles = makeStyles(theme => ({
  name: {
    marginTop: 6,
  },
  cap: {
    color: '#181c20',
    fontSize: '.925rem',
    fontWeight: 400,
    lineHeight: '1.4rem',
  },
  freqCap: {
    color: '#5c6b73',
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1),
  },
  freqCapsBtn: {
    width: '100%',
    textAlign: 'left',
  },
  freqCapWrap: {
    border: '1px solid rgb(224, 224, 224)',
    padding: theme.spacing(3),
    borderRadius: 10,
    textAlign: 'left',
    width: '100%',
  },
}));

const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const AdGroupBasics = ({
  isError,
  isStartBefore,
  isEndBefore,
  name,
  deliveryTime,
  endDate,
  freqCaps,
  startDate,
  setIsFreqCaps,
  currentDayPartsCopy,
  dayParts,
  setIsDayParting,
  setName,
  setStartDate,
  setEndDate,
  setDeliveryTime,
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justify="space-between" spacing={2}>
      <Grid item xs={5}>
        <TextField
          error={isError && (!name || name === '')}
          className={classes.name}
          color="secondary"
          fullWidth
          id="ad-group-name"
          label="Name"
          placeholder="Enter a name for your ad group"
          variant="outlined"
          value={name}
          onChange={event => setName(event.target.value)}
        />
      </Grid>

      <Grid item xs={5}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <KeyboardDatePicker
                color="secondary"
                disableToolbar
                disabled={isStartBefore}
                format="MM/dd/yyyy"
                id="start-date"
                inputVariant="outlined"
                label="Start Date"
                margin="normal"
                onChange={setStartDate}
                value={startDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <KeyboardDatePicker
                color="secondary"
                disabled={isEndBefore}
                disableToolbar
                emptyLabel="No End Date"
                format="MM/dd/yyyy"
                fullWidth
                id="end-date"
                inputVariant="outlined"
                label="End Date"
                margin="normal"
                onChange={setEndDate}
                value={endDate}
                variant="inline"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={12}>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              mb={1}
            >
              <Button
                className="freq-cap-btn"
                onClick={() => setIsFreqCaps(true)}
                startIcon={<img src={freqCapIcon} width={17} />}
              >
                <Typography className={classes.freqCap}>
                  Configure frequency cap
                </Typography>
              </Button>

              {freqCaps && freqCaps.length > 0 && (
                <ButtonBase
                  disableRipple
                  onClick={() => setIsFreqCaps(true)}
                  className={classes.freqCapsBtn}
                >
                  <Box my={1} className={classes.freqCapWrap}>
                    {freqCaps.map(f => (
                      <Typography key={f.fctype} className={classes.cap}>
                        {`${f.impressions} ads every ${f.duration} ${f.fctype.toLowerCase()}(s)`}
                      </Typography>
                    ))}
                  </Box>
                </ButtonBase>
              )}
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Button
                className="freq-cap-btn"
                onClick={() => setIsDayParting(true)}
                startIcon={<img src={freqCapIcon} width={17} />}
              >
                <Typography className={classes.freqCap}>
                  {currentDayPartsCopy}
                </Typography>
              </Button>

              {dayParts.length > 0 &&
                <ButtonBase
                  className={classes.freqCapsBtn}
                  p={0}
                  onClick={() => setIsDayParting(true)}
                >
                  <Box my={1} className={classes.freqCapWrap}>
                    {dayParts.map((part, index) =>
                      <Typography key={index} className={classes.cap}>
                        {days.filter(d => part.days[d.toLowerCase()].selected).join(', ')}

                        {` | `}

                        {`${convertToTime(part.time[0], -12, 12)}-
                        ${convertToTime(part.time[1], -12, 12)}`}
                      </Typography>)}
                  </Box>
                </ButtonBase>}
            </Box>
          </Box>

          <Box width="41%">
            <TextField
              select
              color="secondary"
              fullWidth
              label="Delivery Timezone"
              variant="outlined"
              value={deliveryTime}
              onChange={evt => setDeliveryTime(evt.target.value)}
            >
              <MenuItem value="est">
                Eastern Time (GMT-05:00)
              </MenuItem>

              <MenuItem value="user">
                Viewers&apos; Local Time
              </MenuItem>
            </TextField>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

AdGroupBasics.propTypes = {
  isError: PropTypes.bool,
  isNew: PropTypes.bool,
  isStartBefore: PropTypes.bool,
  isEndBefore: PropTypes.bool,
  name: PropTypes.string,
  freqCaps: PropTypes.array,
  dayParts: PropTypes.array,
  currentDayPartsCopy: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  setName: PropTypes.func,
  setIsFreqCaps: PropTypes.func,
  setIsDayParting: PropTypes.func,
  setEndDate: PropTypes.func,
  setStartDate: PropTypes.func,
  deliveryTime: PropTypes.string,
  setDeliveryTime: PropTypes.func,
};

export default AdGroupBasics;
