import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  makeStyles
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import AsyncButton from './AsyncButton';

const useStyles = makeStyles(({ palette, spacing }) => ({
  back: {
    color: palette.secondary.main,
    fontSize: '0.9375rem',
    minWidth: 120,
    paddingLeft: spacing(2),
    paddingTop: 7,
    paddingBottom: 7,
    paddingRight: spacing(2),
  },
  bottomNav: {
    paddingTop: spacing(2),
    paddingBottom: spacing(0),
  },
}));

const CampaignFooter = ({
  isDisabled,
  isLoading,
  isNextHidden,
  back,
  endIcon,
  next,
  onBack,
  onNext,
  page,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.bottomNav} alignItems="flex-end">
      <Grid container item alignItems="center" justify="space-between">
        <Grid item xs={page ? 4 : 6}>
          <Button
            className={classes.back}
            color="secondary"
            disableElevation
            onClick={onBack}
            size="large"
            startIcon={<ArrowBackIcon />}
            variant="outlined"
          >
            {back}
          </Button>
        </Grid>

        {page && (
          <Grid item xs={4} className="step-count u-align--center">
            {`${page} of 4`}
          </Grid>
        )}

        {!isNextHidden &&
          <Grid container item xs={page ? 4 : 6} justify="flex-end">
            <AsyncButton
              isDisabled={isLoading || isDisabled}
              color="secondary"
              disableElevation
              endIcon={endIcon ? endIcon : <ArrowForwardIcon />}
              isLoading={isLoading}
              loadingButton="Saving..."
              onClick={onNext}
              size="large"
              type="regular"
              variant="outlined"
              {...rest}
            >
              {next}
            </AsyncButton>
        </Grid>}
      </Grid>
    </Grid>
  );
};

CampaignFooter.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isNextHidden: PropTypes.bool,
  back: PropTypes.string,
  endIcon: PropTypes.node,
  next: PropTypes.string,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  page: PropTypes.number,
};

export default CampaignFooter;
