import { useAdvertisers } from './hooks/advertisers';
import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const User = ({ children }) => {
  const { authState } = useOktaAuth();
  const { getUser } = useAdvertisers();
  const [user, setUser] = useState({});

  const updateUser = async () => {
    const result = await getUser();
    setUser(result);
  }

  useEffect(() => {
    if (authState.isAuthenticated) {
      updateUser();
    }
  }, [authState])

  return children(user);
};

export default User;
