import React from 'react'
import clsx from 'clsx'
import GetAppIcon from '@material-ui/icons/GetApp'
import GlobalFilter from './GlobalFilter'
import { lighten, makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import styled from '@emotion/styled'
import multiDownload from 'multi-download';
import { useSnackbar } from 'notistack';
import { useAPI } from './hooks/api'

const StyledButton = styled(Button)`
  opacity: 0.5;
  border-radius: 4px;
  background-color: #eeeeee;
  padding-left: 20px;
  padding-right: 20px;
`

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 30%',
  },
}))


const TableToolbar = props => {
  const classes = useToolbarStyles()
  const {
    numSelected,
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilter,
    selectedInvoices,
    setIsLoading
  } = props

  const { useGetBlob } = useAPI();
  const { enqueueSnackbar } = useSnackbar();

  const handleInvoicesClick = async () => {
    setIsLoading(true)
    enqueueSnackbar(`${selectedInvoices.length} Invoices downloading`, {
      autoHideDuration: 3000,
      preventDuplicate: true,
      variant: 'success',
    })
    const invoicesPromises = []
    selectedInvoices.map(i => {
      invoicesPromises.push(useGetBlob(`/billing_events/${i}/create_invoice/`))
    })

    try {
      const responses = await Promise.all(invoicesPromises)
      const downloads = []
      responses.map((invoice) => {
        downloads.push(window.URL.createObjectURL(new Blob([invoice], { type: 'text/plain' })))
      })

      multiDownload(downloads, {
        rename: ({ index, }) => {
          return `Invoice ${selectedInvoices[index]}.pdf`
        }
      });
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h1" id="tableTitle">
          Invoices
        </Typography>
      )}

      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />

      <StyledButton
        disabled={selectedInvoices.length === 0}
        variant="contained"
        disableElevation
        onClick={handleInvoicesClick}
        color="default" startIcon={<GetAppIcon />}>
        DOWNLOAD
      </StyledButton>
    </Toolbar>
  )
}

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  preGlobalFilteredRows: PropTypes.array.isRequired,
  globalFilter: PropTypes.string.isRequired,
  selectedInvoices: PropTypes.array,
  setIsLoading: PropTypes.bool
}

export default TableToolbar
