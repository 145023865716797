import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Checkbox,
  ButtonGroup,
  Button,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CheckIcon from '@material-ui/icons/Check';
import { Link, useHistory } from 'react-router-dom';

import AdvertiserContext from '../AdvertiserContext';
import AppHeader from '../AppHeader';
import AsyncButton from '../AsyncButton';
import Title from '../Title';
import { api } from '../../connection/TVSApi';
import { Themes } from '../../constants';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    paddingTop: spacing(10),
    paddingBottom: spacing(10),
  },
  checkbox: {
    cursor: 'pointer',
    marginRight: spacing(1),
  },
  formButton: {
    marginTop: 10,
    "& .tvs-MuiButton-root": {
      width: "100%",
      marginBottom: 20,
    }
  },
  input: {
    marginTop: spacing(2),
  },
  pageTitle: {
    maxWidth: 650,
    fontSize: 40,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0,
    color: "#181c20",
    marginBottom: 40,
  },
  pageSubtitle: {
    maxWidth: 450,
    fontSize: 28,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0,
    color: "#9aa0a6"
  },
  illustration: {
    maxWidth: "100%",
    height: "auto",
  },
  buttonGroup: {
    marginTop: spacing(2),
    boxShadow: "none",
    "& .tvs-MuiButton-root": {
      border: "none",
    },
  },
  activeButton: {
    fontSize: '1rem',
    backgroundColor: "#c3e7fa",
    color: "#3f4951",
    fontWeight: "normal",
    boxShadow: "none !important",
    paddingTop: 13,
    paddingBottom: 13,
    "&:hover": {
      backgroundColor: "#c3e7fa",
      cursor: "unset",
    },
    "& .tvs-MuiSvgIcon-root": {
      marginRight: spacing(1),
    }
  },
  inactiveButton: {
    fontSize: '1rem',
    backgroundColor: "#ffffff",
    color: "#3f4951",
    fontWeight: "normal",
    paddingTop: 13,
    paddingBottom: 13,
    "&:hover": {
      color: "#ffffff",
    }
  },
  signInLink: {
    textAlign: "center",
    color: "#9aa0a6",
  },
  terms: {
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  warningMessage: {
    marginTop: 4,
  },
}));

const headline = 'Welcome to the next generation of TV advertising';
const subheadline = 'Build and launch Connected TV campaigns in minutes';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const urlRegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  website: yup.string().required('Website url is required').matches(urlRegExp, "Website url is invalid"),
  companyName: yup.string().required('Company Name is required'),
  phone: yup.string().matches(phoneRegExp, { message: "Phone number is not valid", excludeEmptyString: true }),
  isAgree: yup.boolean().isTrue('You must agree to the Terms & Conditions')
});

const RequestAccountPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const adContext = useContext(AdvertiserContext);

  const [navSkipSaveStatus] = useState('');

  // Set State
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isAgree, setIsAgree] = useState(false);

  const [isAdvertiser, setIsAdvertiser] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [warnings, setWarnings] = useState([]);

  const handleRequestAccount = async () => {
    const portalId = '8975772';
    const formGuid = '3f015daa-85db-4a86-938b-004bdb2661c5';
    const hubspotUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`
    const config = {
      'Content-Type': 'application/json',
    }

    try {
      await schema.validate({
        firstName,
        lastName,
        email,
        website,
        companyName,
        phone,
        isAgree
      }, { abortEarly: false })

      setIsLoading(true);
      setIsSuccess(false);

      let qualifier = "I'm an Agency"

      if (isAdvertiser) {
        qualifier = "I'm an Advertiser"
      }

      const data =
      {
        portalId,
        formGuid,
        fields: [
          {
            name: 'role_qualifier',
            value: qualifier,
          },
          {
            name: 'firstname',
            value: firstName,
          },
          {
            name: 'lastname',
            value: lastName,
          },
          {
            name: 'email',
            value: email,
          },
          {
            name: 'website',
            value: website,
          },
          {
            name: 'company',
            value: companyName,
          },
          {
            name: 'phone',
            value: phone,
          },
          {
            name: 'message',
            value: message,
          },
        ],
      }

      return (
        api.postUrl(hubspotUrl, data, config).then(res => {
          setIsLoading(false);
          // Handle failed
          if (
            res.response &&
            res.response.status &&
            res.response.status >= 400
          ) {
            console.error('Error in Requesting Account: ', res.response);
            setIsSuccess(false);

            const { errors } = res.response.data;

            if (errors.length > 0) {
              setWarnings([errors[0].message]);
            }

            return;
          }

          // Handle success
          if (res.data.redirectUri) {
            location.replace(res.data.redirectUri);

            return;
          }

          setWarnings([]);
          setIsSuccess(true);
        })
      );
    } catch (err) {
      setWarnings(err.errors)
      return false;
    }
  };

  if (adContext.theme === Themes.NBCU) {
    return (
      <AppHeader hidenav="true" navSkipSaveStatus={navSkipSaveStatus}>
        <Box className={clsx(classes.container, '')}>
          <Container maxWidth="lg">
            <Box>
              <Grid container item direction="row" display="flex" md={12}>
                <Grid item md={6} style={{ "display": "flex", "width": "50%", "height": "200px", "margin": "auto", "alignItems": "center", "justifyContent": "center" }}>
                  <Title>Welcome to Peacock Ad Manager</Title>
                </Grid>
                <Grid item md={6}>
                  <img
                    style={{ backgroundSize: 'contain', width: '100%' }}
                    src={require('../../images/nbcu/padman-hero.png')}
                    alt="Peacock Ad Hero logo"
                    className={classes.logo}
                  />
                </Grid>
              </Grid>
              <Grid container item direction="row" justify="space-evenly" display="flex">
                <Grid item md={5} xs={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid container justify="space-evenly">
                        <Box py={5}>
                          <Grid item p={2}>
                            <Typography variant="h4" className="peacockText">
                              Request Access to Peacock Ad Manager
                            </Typography>
                            <Grid container justify="center">
                              <Grid item xs={6}>
                                <Box mt={2}>
                                  <Button
                                    color="secondary"
                                    size="large"
                                    fullWidth
                                    disableElevation
                                    variant='outlined'
                                    disabled
                                  >
                                    Coming Soon
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={5} xs={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid container justify="space-evenly">
                        <Grid item p={2}>
                          <Box py={5}>
                            <Typography variant="h4" className="peacockText">
                              Log into Peacock Ad Manager
                            </Typography>
                            <Grid container justify="center">
                              <Grid item xs={8}>
                                <Box mt={2}>
                                  <Button
                                    color="secondary"
                                    size="large"
                                    fullWidth
                                    variant='contained'
                                    onClick={() => history.push('/home')}
                                  >
                                    Log In
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </AppHeader>
    )
  }

  return (
    <AppHeader hidenav="true" navSkipSaveStatus={navSkipSaveStatus}>
      <Box className={clsx(classes.container, 'CreateAccount')}>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={6}
            direction="row"
            justify="center"
            alignItems="stretch"
          >
            <Grid item xs={12} md={7} className={'CreateAccount-header'}>
              <Typography variant="h1" className={classes.pageTitle}>
                {headline}
              </Typography>
              <Typography variant="subtitle1" component="h3" className={classes.pageSubtitle}>
                {subheadline}
              </Typography>
              <Box>
                <img src={require('../../images/img-signup-request.png')} alt="signup request illistration" className={classes.illustration} />
              </Box>
            </Grid>

            <Grid container item xs={12} md={5} direction="row" justify="center">
              <Grid container direction="column" justify="flex-end">
                <Box>
                  <Title>Request Platform Access Now</Title>
                  <div id="hubspotForm"></div>

                  <ButtonGroup
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    className={classes.buttonGroup}
                  >
                    <Button
                      className={isAdvertiser ? classes.activeButton : classes.inactiveButton}
                      color="secondary"
                      size="large"
                      fullWidth
                      onClick={() => setIsAdvertiser(true)}
                    >
                      {isAdvertiser ? <CheckIcon color="secondary" /> : ''}
                      I&#39;m an Advertiser
                    </Button>
                    <Button
                      className={isAdvertiser ? classes.inactiveButton : classes.activeButton}
                      color="secondary"
                      size="large"
                      fullWidth
                      onClick={() => setIsAdvertiser(false)}
                    >
                      {isAdvertiser ? '' : <CheckIcon color="secondary" />}
                      I&#39;m an Agency
                    </Button>
                  </ButtonGroup>

                  <Grid container spacing={2} className={classes.input}>
                    <Grid item xs>
                      <TextField
                        color="secondary"
                        fullWidth
                        autoFocus
                        id="firstName"
                        label="First Name"
                        required
                        variant="outlined"
                        value={firstName}
                        onChange={event => setFirstName(event.target.value)}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        color="secondary"
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        required
                        variant="outlined"
                        value={lastName}
                        onChange={event => setLastName(event.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <TextField
                    color="secondary"
                    fullWidth
                    className={classes.input}
                    id="email"
                    label="Email"
                    required
                    variant="outlined"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                  />


                  <TextField
                    color="secondary"
                    fullWidth
                    className={classes.input}
                    id="website"
                    label="Website URL"
                    required
                    variant="outlined"
                    value={website}
                    onChange={event => setWebsite(event.target.value)}
                  />

                  <TextField
                    color="secondary"
                    fullWidth
                    className={classes.input}
                    id="company-name"
                    label="Company Name"
                    required
                    variant="outlined"
                    value={companyName}
                    onChange={event => setCompanyName(event.target.value)}
                  />

                  <TextField
                    color="secondary"
                    fullWidth
                    className={classes.input}
                    id="phone"
                    label="Phone Number"
                    variant="outlined"
                    value={phone}
                    onChange={event => setPhone(event.target.value)}
                  />

                  <TextField
                    color="secondary"
                    fullWidth
                    className={classes.input}
                    id="message"
                    label="Message"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={message}
                    onChange={event => setMessage(event.target.value)}
                  />

                  <Box key="isAgree" className={classes.input}>
                    <label
                      className={classes.terms}
                      htmlFor="terms-conditions-input"
                    >

                      <Checkbox
                        color="secondary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        checked={isAgree}
                        onChange={() => setIsAgree(!isAgree)}
                        id="terms-conditions-input"
                        name="terms-conditions-input"
                      />

                      <span>I have read the&nbsp;</span>

                      <a target="_blank" rel="noopener noreferrer" href="https://www.tvscientific.com/platform-master-agreement-demand/">
                        Terms & Conditions
                      </a>
                    </label>
                  </Box>

                  <Grid
                    container
                    spacing={2}
                    className={classes.warningMessage}
                  >
                    <Grid item xs={12}>
                      {warnings.map((message) => (
                        <Typography variant="subtitle2" color="error" key={message}>
                          {message}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>

                  <Box className={classes.formButton}  >
                    <AsyncButton
                      fullWidth
                      isLoading={isLoading}
                      isSuccess={isSuccess}
                      successMsg={'Your request has been sent!'}
                      textButton="Request Access"
                      loadingButton="Sending Request..."
                      successButton="Sent!"
                      onClick={handleRequestAccount}
                    />
                    <Box className={classes.signInLink}>
                      Have an account? <Link id="sign-in-link" to="/home">Sign In</Link>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>


        </Container>
      </Box>
    </AppHeader>
  );
};

export default RequestAccountPage;
