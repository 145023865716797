import { useContext, useEffect, useReducer, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { sortBy } from 'lodash';

import AdvertiserContext from '../AdvertiserContext';
import { useAPI } from './api';
import { getTheme } from '../../helpers';
import { Themes } from '../../constants';

//////////////////////////
// useAdvertisers HOOK
//////////////////////////
export const useAdvertisers = () => {
  const { authState } = useOktaAuth();
  const adContext = useContext(AdvertiserContext);
  const { useGet, useGetAll, usePatch, usePost } = useAPI();

  const advertisersReducer = (state, action) => {
    switch (action.type) {
      case 'set':
        return sortBy(action.payload, 'name');
      case 'add':
        return sortBy([...state, ...action.payload], 'name');
      default:
        return state;
    }
  };

  // Init Advertisers State
  const [advertisers, dispatchAdvertisers] = useReducer(
    advertisersReducer,
    []
  );

  const [currentAdvertiser, setCurrentAdvertiser] = useState(null);

  // After advertisers are called set current advertiser
  useEffect(() => {
    const cachedId = parseInt(localStorage.getItem('AdvertiserContext'), 10);
    const advertiser = advertisers.find(a => a.id === cachedId) || advertisers[0];

    if (advertiser) {
      setCurrentAdvertiser(advertiser);
    }
  }, [advertisers]);

  useEffect(() => {
    if (!currentAdvertiser) {
      return;
    }

    const {
      active,
      billing_method,
      category,
      cost_model,
      deductive_exposure_lid: exposureLid,
      deductive_outcome_lid: outcomeLid,
      default_payment_profile,
      domain,
      has_incrementality,
      id,
      invoice_approved,
      is_nbcu_tenant,
      looker_experience,
      looker_validated,
      name,
      path_to_purchase_validated,
      url,
    } = currentAdvertiser;

    // Add Advertiser Context ID to localStorage
    localStorage.setItem('AdvertiserContext', id);

    // Update the App Advertiser Context
    adContext.updateAdvertiser({
      active,
      billing_method,
      category,
      cost_model,
      default_payment_profile,
      domain,
      exposureLid,
      has_incrementality,
      id,
      invoice_approved,
      is_nbcu_tenant,
      looker_experience,
      looker_validated,
      name,
      outcomeLid,
      path_to_purchase_validated,
      url,
    });
  }, [currentAdvertiser]);

  useEffect(() => {
    const {
      theme: prevTheme,
      is_nbcu_tenant: idNbcuTenant
    } = currentAdvertiser || {};
    const { isAuthenticated } = authState || {};
    const isNbcuSubdomain = window.location.href.includes('peacock');
    const cachedTheme = localStorage.getItem('Theme');

    const theme = getTheme(
      isAuthenticated,
      idNbcuTenant,
      isNbcuSubdomain,
      cachedTheme
    );

    if (prevTheme === theme) {
      return;
    }

    localStorage.setItem('Theme', theme);
    adContext.updateAdvertiser({ theme });
  }, [currentAdvertiser, authState.isAuthenticated]);

  // Adds https protocol to user inputted domain
  const formatAdvertiserUrl = url => {
    if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
      return url.trim();
    }

    return `https://${url}`.trim();
  };

  // Checks for unsecure protocol
  const validateAdvertiserUrl = url => !(url.indexOf('http://') === 0);

  // TODO: use Regex to parse url string
  const verifyRootDomain = url => {
    let protocol = null;

    if (url.includes('http://')) {
      protocol = 'http://';
    } else if (url.includes('https://')) {
      protocol = 'https://';
    }

    if (protocol != null) {
      const split = url.split(protocol);
      const str = split[1] ? split[1] : null;

      if (str != null) {
        const urlArray = str.split('/');
        const filtered = urlArray
          .filter(a => (a !== '' && !a.includes('http')));
        return filtered.length === 1
      }
    }

    const domain = url.split('/');
    const filtered = domain.filter(a => a !== '');
    return filtered.length === 1;
  };

  const setDefaultPayment = async (url) => await usePatch(
    `/advertisers/${adContext.id}/`,
    { default_payment_profile: url }
  );

  const handleUpdateOwner = owner => {
    if (!adContext.owner || adContext.owner !== owner) {
      return adContext.updateAdvertiser({ owner });
    }
  };

  const handleUpdateLooker = uses_looker => {
    if (!adContext.uses_looker || adContext.uses_looker !== uses_looker) {
      return adContext.updateAdvertiser({ uses_looker });
    }
  };

  // Exposed function for updating the
  // current advertiser and advertiser context
  const updateAdvertiser = advertiser => {
    setCurrentAdvertiser(advertiser);

    if (adContext.id) {
      return useGet('/advertisers/me/');
    }
  };

  // Creates a single advertiser
  const createAdvertiser = data => {
    const { name, owner, theme } = adContext;

    const dataObj = {
      name,
      owner,
      is_nbcu_tenant: theme === Themes.NBCU,
      ...data,
    };

    return usePost('/advertisers/', dataObj)
      .then(response => {
        if (response.statusText === 'Created') {
          return updateAdvertiser(response.data).then(res => {
            dispatchAdvertisers({ type: 'add', payload: [response.data] })
            return res;
          })
          .catch(error => {
            console.warn(error)
            return error;
          });
        }

        return response;
      })
      .catch(error => {
        console.warn(error)
        return error;
      });
  };

  // TODO: Move to custom useUser hook
  const getUser = () => {
    return useGet('/users/me/')
      .then(user => {
        if (user) {
          handleUpdateLooker(user.uses_looker);
        }

        if (user && user.url) {
          handleUpdateOwner(user.url);
        }

        return user;
      })
      .catch(error => console.error(error));
  };

  const getAdvertisers = async () => {
    if (!adContext.owner) {
      await getUser();
    }

    return useGetAll('/advertisers', [], total => {
      dispatchAdvertisers({ type: 'set', payload: total });
    });
  };

  // Expose the functions/variables
  return {
    advertisers,
    createAdvertiser,
    getAdvertisers,
    currentAdvertiser,
    updateAdvertiser,
    formatAdvertiserUrl,
    validateAdvertiserUrl,
    verifyRootDomain,
    setDefaultPayment,
    getUser
  };
};
