import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import { IntercomProvider } from '../helpers/intercomWrapper';

const Root = () => {
  const [intercomEnv, setIntercomEnv] = useState('production');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const intercomEnvValue = urlParams.get('intercom-env');

    if (intercomEnvValue) {
      setIntercomEnv(intercomEnvValue);
    }

  }, []);

  let appId = process.env.INTERCOM_APP_ID_PROD;

  if (intercomEnv === 'staging') {
    console.log('using staging intercom');
    appId = process.env.INTERCOM_APP_ID_STAGING;
  }

  return (
    <Router>
      <IntercomProvider appId={appId}>
        <App />
      </IntercomProvider>
    </Router>
  );
};

export default Root;



