import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useOktaAuth } from '@okta/okta-react';
import { useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  InputBase,
  InputAdornment,
  Menu,
  MenuItem,
  ListItemText,
  Paper,
  Popper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Themes } from '../constants';
import { Autocomplete } from '@material-ui/lab';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

import AdvertiserContext from './AdvertiserContext';

const useStyles = makeStyles(
  ({ palette, spacing, typography, transitions }) => ({
    addCircle: {
      fontSize: '1rem',
    },
    advertiserItem: {
      paddingBottom: spacing(1),
      paddingTop: spacing(1),
    },
    avatar: {
      width: `1.5rem`,
      height: `1.5rem`,
      fontSize: `12px !important`,
    },
    createAdvertiser: {
      color: '#727383cc',
      paddingBottom: spacing(1),
      paddingTop: spacing(1),
      cursor: 'pointer',
      fontSize: '.825rem',

      '&:hover, &:hover p': {
        color: palette.secondary.main,
      },
    },
    create: {
      transition: 'background-color 300ms',
    },
    nbcu: {
      transition: 'background-color 300ms',
      backgroundColor: '#faca13',
      color: '#000000',
    },
    divider: {
      background: `#014831`,
      marginLeft: `1.125rem`,
      marginRight: `1.125rem`,
      height: `1.5rem`,
    },
    inputBase: {
      padding: 10,
      width: '100%',
      borderBottom: '1px solid #dfe2e5',

      '& input': {
        borderRadius: 4,
        backgroundColor: palette.common.white,
        padding: 8,
        transition: transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #ced4da',
        fontSize: '.825rem',

        '&:focus': {
          borderColor: palette.secondary.main,
        },
      },
    },
    paper: {
      boxShadow: 'none',
      margin: 0,
      color: '#586069',
      fontSize: 13,
    },
    option: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      fontSize: '0.9rem',

      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },

      '&[data-focus="true"]': {
        backgroundColor: palette.action.hover,
      },
    },
    popperDisablePortal: {
      position: 'relative',
    },
    listbox: {
      maxHeight: '70vh',
    },
    popper: {
      borderRadius: 3,
      border: '1px solid rgba(27,31,35,.15)',
      boxShadow: '0 3px 12px rgba(27,31,35,.15)',
      minWidth: 375,
      maxWidth: 500,
      zIndex: 1,
      fontSize: 13,
      color: '#586069',
      backgroundColor: '#f6f8fa',
    },
    text: {
      ...typography.body2,
      verticalAlign: 'middle',
      fontWeight: 500,
    },
    userButton: {
      color: '#ffffff',
    },
  })
);

const TVSAvatar = props => (
  <Avatar className={props.className}>{props.letter}</Avatar>
);

TVSAvatar.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  letter: PropTypes.string,
};

const menuPosition = {
  vertical: 'bottom',
  horizontal: 'right',
};

// TODO:
// - Break apart logic for finer use cases
// - Ultimately rename or repurpose component

/////////////////////////////
// LOGIN BUTTON COMPONENT
/////////////////////////////
const LoginButton = props => {
  const { authState, oktaAuth } = useOktaAuth();
  const adContext = useContext(AdvertiserContext);
  const location = useLocation();
  const classes = useStyles();
  const anchorRef = useRef(null);
  const { advertisers, currentAdvertiser, updateAdvertiser } = props;
  const [authenticated, setAuthenticated] = useState(false);
  const [current, setCurrent] = useState(currentAdvertiser);
  const [search, setSearch] = useState('');
  const [user, setUser] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const checkAuthentication = async () => {
    const isAuth = await authState.isAuthenticated;

    if (isAuth !== authenticated) {
      const authUser = await oktaAuth.getUser();
      setAuthenticated(isAuth);
      setUser(authUser);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  useEffect(() => {
    if (props.isNewAdvertiser) {
      setOpen(false);
    }
  }, [props.isNewAdvertiser]);

  // return focus to the button when we
  // transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  // Event handlers
  const handleMenuOpen = event => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    // If focusing the input, do not close
    if (reason === 'toggleInput') {
      return;
    }

    if (!props.isNewAdvertiser && reason !== 'blur') {
      setOpen(false);
    }
  };

  const handleChangeAdvertiser = advertiser => {
    setOpen(false);
    setCurrent(advertiser);

    if (
      ![
        '/home',
        '/reports',
        '/creatives',
        '/tracking',
        '/support',
      ].includes(location.pathname)
    ) {
      return props.history.push('/home', { advertiser });
    }

    return updateAdvertiser(advertiser);
  };

  const handleOpenAdvertisers = () => {
    setOpen(true);
  };

  const handleCreate = () => {
    if (advertisers && advertisers.length > 0) {
      return openNewCampaign();
    }

    return props.setIsNewAdvertiser(true);
  };

  const openNewCampaign = () => {
    props.history.push('/campaign-setup');
  };

  const login = () => {
    oktaAuth.signInWithRedirect();
  };

  const logout = () => {
    handleMenuClose();
    oktaAuth.signOut();
  };

  if (authenticated === null) return null;

  if (!authenticated) {
    return (
      <Button color="inherit" onClick={login}>
        Login
      </Button>
    );
  }

  // Render full component
  return (
    <Grid container alignItems="center" justify="flex-end">
      <Grid item>
        <Button
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          ref={anchorRef}
          className={classes.userButton}
          disableRipple
          onClick={handleOpenAdvertisers}
          startIcon={
            <TVSAvatar
              letter={adContext && adContext.id && adContext.name.charAt(0)}
              className={classes.avatar}
            />
          }
          endIcon={<ExpandMoreIcon />}
        >
          {adContext.name}
        </Button>

        <Popper
          className={classes.popper}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Autocomplete
              open
              onClose={handleClose}
              classes={{
                paper: classes.paper,
                option: classes.option,
                popperDisablePortal: classes.popperDisablePortal,
                listbox: classes.listbox,
              }}
              inputValue={search}
              onInputChange={(e, newValue) => {
                setSearch(newValue);
              }}
              value={current}
              onChange={(e, value, reason) => {
                if (reason === 'select-option') {
                  handleChangeAdvertiser(value);
                }
              }}
              size="small"
              style={{ maxHeight: '75vh' }}
              blurOnSelect
              clearOnBlur
              disablePortal
              includeInputInList
              loading={props.isFetching}
              loadingText="Loading advertisers..."
              renderTags={() => null}
              noOptionsText="No Matches"
              getOptionLabel={advertiser => advertiser.name}
              getOptionSelected={(option, value) => {
                return option.id === value.id;
              }}
              selectOnFocus
              options={advertisers}
              PaperComponent={({ children }) => (
                <Paper className={classes.paper}>
                  <Box width="100%" ml={1}>
                    <Box
                      onClick={async () => {
                        await props.setIsNewAdvertiser(true);
                      }}
                      className={classes.createAdvertiser}
                      width="100%"
                      display="flex"
                      alignItems="center"
                      ml={1}
                      py={1}
                    >
                      <Box display="flex" flexDirection="flex-end" mr={1}>
                        <AddCircleIcon
                          className={classes.addCircle}
                          fontSize="small"
                        />
                      </Box>

                      <Typography variant="body2">
                        CREATE ADVERTISER
                      </Typography>
                    </Box>
                  </Box>

                  <Divider />

                  {children}
                </Paper>
              )}
              renderOption={advertiser => advertiser.name}
              renderInput={params => (
                <InputBase
                  autoFocus
                  color="secondary"
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  className={classes.inputBase}
                  placeholder="Search Advertiser"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  }
                />
              )}
            />
          </ClickAwayListener>
        </Popper>
      </Grid>

      <Grid item>
        <Divider
          className={clsx(classes.divider, 'Vertical-divider')}
          orientation="vertical"
        />
      </Grid>

      <Grid item>
        <Button
          id="create-campaign"
          className={clsx(
            adContext.theme === Themes.NBCU ? classes.nbcu : classes.create,
            'Nav-create-campaign'
          )}
          color={!advertisers.length ? 'secondary' : 'primary'}
          disableElevation
          disableFocusRipple
          disableRipple
          onClick={handleCreate}
          startIcon={<AddIcon />}
          variant="contained"
        >
          {!advertisers.length ? 'CREATE ADVERTISER' : 'CREATE CAMPAIGN'}
        </Button>
      </Grid>

      <Grid item>
        <Divider
          className={clsx(classes.divider, 'Vertical-divider')}
          orientation="vertical"
        />
      </Grid>

      <Grid item>
        <IconButton onClick={handleMenuOpen} color="inherit">
          <AccountCircleIcon />
        </IconButton>

        <Menu
          anchorEl={menuAnchorEl}
          anchorOrigin={menuPosition}
          transformOrigin={menuPosition}
          open={!!menuAnchorEl}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={logout}>
            <ListItemText
              secondary={
                <span>
                  {user && user.name} <br /> {user && user.email}
                </span>
              }
              primary="Logout"
            />
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};

LoginButton.propTypes = {
  authState: PropTypes.object,
  classes: PropTypes.object,
  className: PropTypes.object,
  history: PropTypes.object,
  oktaAuth: PropTypes.object,
  advertisers: PropTypes.array,
  currentAdvertiser: PropTypes.object,
  updateAdvertiser: PropTypes.func,
  isFetching: PropTypes.bool,
  isNewAdvertiser: PropTypes.bool,
  setIsNewAdvertiser: PropTypes.func,
};

export default React.memo(LoginButton);
