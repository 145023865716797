import React, { useContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import AdvertiserContext from './AdvertiserContext';
import AsyncButton from './AsyncButton';
import { useLoader } from './hooks/loader';
import { useAdvertisers } from './hooks/advertisers';
import dashboardIcon from '../images/general-dashboard.png';
import cartIcon from '../images/cart-dashboard.png';
import downloadIcon from '../images/download-dashboard.png';

const useStyles = makeStyles(theme => ({
  card: {
    width: 150,
    height: 150,
    cursor: 'pointer',

    '&:hover': {
      boxShadow:
        '0px 5px 5px -3px rgb(29 175 255 / 20%), 0px 8px 10px 1px rgb(29 175 255 / 14%), 0px 3px 14px 2px rgb(29 175 255 / 12%)',
    },
  },
  selectedCard: {
    backgroundColor: 'rgba(29, 175, 255, 0.2)',

    '&:hover': {
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingBottom: '16px !important',
  },
  cardLabel: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  container: {
    minHeight: 710,
  },
  footer: {
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  subtitle: {
    fontSize: '0.75rem',
    color: '#47505d',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  dashboard: {
    fontSize: '1.125rem',
  },
  general: {
    width: 58,
    height: 58,
    margin: '0 auto',
  },
  cart: {
    width: 68,
    height: 58,
    margin: '0 auto',
  },
  download: {
    width: 35,
    height: 58,
    margin: '0 auto',
  },
  radioHead: {
    fontSize: '1.125rem',
    color: '#181c20',
    fontWeight: 600,
  },
  radioLabel: {
    fontSize: '.925rem',
    color: '#181c20',
    fontWeight: 400,
  },
}));

const kpiOptions = [
  {
    value: 'IMPRESSIONS',
    label: 'Impressions',
  },
  {
    value: 'WEB_SITE_VISITS',
    label: 'Web site visits',
  },
  {
    value: 'PURCHASES',
    label: 'Purchases',
  },
  {
    value: 'APP_INSTALLS',
    label: 'App installs',
  },
  {
    value: 'IN_APP_PURCHASES',
    label: 'In-app purchases',
  },
];

const errorMessages = {
  name: 'This advertiser already exists. Please contact your account owner or change your advertiser name.',
  domain: 'Please enter a valid website.',
  path: 'Websites cannot contain an additional path (ex: https://website.com/path)',
  secure: 'We currently only support secure domains. Please enter a valid "https://" domain.'
};

const AdvertiserWizard = props => {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoader();

  const {
    createAdvertiser,
    formatAdvertiserUrl,
    validateAdvertiserUrl,
    verifyRootDomain,
  } = useAdvertisers();

  const {
    onNext,
    showSalesforce,
    showKPI,
    showModel,
    showWindow,
  } = props;

  const [name, setName] = useState('');
  const [domain, setDomain] = useState('');
  const [sfAdvertiserId, setSfAdvertiserId] = useState('');
  const [sfAgencyId, setSfAgencyId] = useState('');
  const [kpi, setKpi] = useState('');
  const [looker, setLooker] = useState(null);
  const [attrModel, setAttrModel] = useState(null);
  const [attrWindow, setAttrWindow] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (adContext) {
      if (adContext.name) {
        setName(adContext.name);
      }

      if (adContext.domain) {
        setDomain(adContext.domain);
      }
    }
  }, [adContext]);

  useEffect(() => {
    if (errorMessage != null) {
      enqueueSnackbar(errorMessage, {
        autoHideDuration: 7000,
        preventDuplicate: true,
        variant: 'error',
      });
    }
  }, [errorMessage]);

  // Input validation with useMemo
  const isNextDisabled = useMemo(() =>
    (showSalesforce && !showKPI && !showModel && !showWindow &&
      (!domain || !sfAdvertiserId || !sfAgencyId || !name)) ||
    (showKPI && showModel && showWindow &&
      (!domain ||
        !looker ||
        !kpi ||
        !attrModel ||
        !attrWindow ||
        (adContext.userType === 'agency' && name === ''))),
  [
    showKPI,
    showModel,
    showWindow,
    showSalesforce,
    sfAdvertiserId,
    sfAgencyId,
    name,
    domain,
    looker,
    kpi,
    attrModel,
    attrWindow,
  ]);

  const handleName = event => {
    setName(event.target.value);
    adContext.updateAdvertiser({ name: event.target.value });
  };

  const handleSaveAdvertiser = async () => {
    setErrorMessage(null);
    setIsLoading(true);

    if (!verifyRootDomain(domain)) {
      setIsLoading(false);
      setErrorMessage(errorMessages.path);
      return;
    }

    if (!validateAdvertiserUrl(domain)) {
      setIsLoading(false);
      setErrorMessage(errorMessages.secure);
      return;
    }

    const dataObj = {
      domain: formatAdvertiserUrl(domain),
    };

    if (adContext.userType === 'agency') {
      dataObj.name = name;
    }

    if (adContext.userType === 'advertiser') {
      dataObj.name = adContext.name;
    }

    if (showKPI && showModel && showWindow) {
      dataObj.primary_kpi = kpi;
      dataObj.looker_experience = looker;
      dataObj.attribution_model = attrModel;
      dataObj.attribution_window = attrWindow;
    }

    if (showSalesforce) {
      dataObj.advertiser_external_crm_id = sfAdvertiserId;
      dataObj.agency_external_crm_id = sfAgencyId;
    }

    return createAdvertiser(dataObj)
      .then(res => {
        setIsLoading(false);

        // if a bad request is made or we get an error response
        // from the api, prevent user from advancing
        if (res && res.response) {
          return res;
        }

        localStorage.removeItem('company');
        localStorage.removeItem('userType');

        onNext();

        return res;
      })
      .catch(error => {
        console.log('error in AdvertiserWizard', error);
        setIsLoading(false);
        return error;
      })
  };

  return (
    <Container className={classes.container}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        minHeight={710}
        p={6}
      >
        <Box>
          <Typography variant="h3">
            {adContext.userType === 'agency' &&
              `Set up your first advertiser's profile`}

            {adContext.userType === 'advertiser' &&
              `Set up ${adContext.name}'s advertiser profile`}
          </Typography>

          <Box mt={2}>
            <Typography className={classes.subtitle}>
              This information will inform how we configure the dashboard and
              performance reports for this advertiser. These settings can be
              modified at any time in the Advertiser Settings.
            </Typography>
          </Box>

          <Divider />

          {showSalesforce && !showKPI && !showModel && !showWindow && (
            <Box display="flex" justifyContent="center" pt={4}>
              <Grid container item xs={5} spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    fullWidth
                    color="secondary"
                    label="Advertiser Name"
                    onChange={handleName}
                    placeholder="Enter name of advertiser"
                    value={name}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    color="secondary"
                    label="Advertiser Website Address"
                    onChange={event => setDomain(event.target.value)}
                    placeholder="Enter advertiser website address"
                    value={domain}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    color="secondary"
                    label="Salesforce Advertiser ID"
                    onChange={event => setSfAdvertiserId(event.target.value)}
                    placeholder="Enter Salesforce Advertiser ID"
                    value={sfAdvertiserId}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    color="secondary"
                    label="Salesforce Agency ID"
                    onChange={event => setSfAgencyId(event.target.value)}
                    placeholder="Enter Salesforce Agency ID"
                    value={sfAgencyId}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {showKPI && showModel && showWindow && (
            <Grid container alignItems="baseline">
              <Grid container item spacing={3} xs={6} justify="space-between">
                {adContext.userType === 'agency' && (
                  <Grid item xs={10}>
                    <Box mt={4} />

                    <TextField
                      autoFocus
                      fullWidth
                      color="secondary"
                      label="Advertiser Name"
                      onChange={event => setName(event.target.value)}
                      placeholder="Enter name of advertiser"
                      value={name}
                      variant="outlined"
                    />
                  </Grid>
                )}

                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    color="secondary"
                    label="Advertiser Website Address"
                    onChange={event => setDomain(event.target.value)}
                    placeholder="Enter advertiser website address"
                    value={domain}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box mt={2} />

                  <Typography className={classes.dashboard} variant="h4">
                    Choose a dashboard that fit their type of business
                  </Typography>

                  <Box
                    py={2}
                    pr={2}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Card
                      raised={looker !== 'DEFAULT'}
                      className={clsx(
                        classes.card,
                        looker === 'DEFAULT' ? classes.selectedCard : {}
                      )}
                      onClick={() => setLooker('DEFAULT')}
                    >
                      <CardContent className={classes.cardContent}>
                        <div className={classes.general}>
                          <img
                            src={dashboardIcon}
                            alt="dashboard"
                            width="100%"
                          />
                        </div>

                        <div className={classes.cardLabel}>
                          <Typography>General</Typography>
                        </div>
                      </CardContent>
                    </Card>

                    <Card
                      raised={looker !== 'D2C'}
                      className={clsx(
                        classes.card,
                        looker === 'D2C' ? classes.selectedCard : {}
                      )}
                      onClick={() => setLooker('D2C')}
                    >
                      <CardContent className={classes.cardContent}>
                        <div className={classes.cart}>
                          <img src={cartIcon} alt="dashboard" width="100%" />
                        </div>

                        <div className={classes.cardLabel}>
                          <Typography>E-commerce</Typography>
                        </div>
                      </CardContent>
                    </Card>

                    <Card
                      raised={looker !== 'GAMING'}
                      className={clsx(
                        classes.card,
                        looker === 'GAMING' ? classes.selectedCard : {}
                      )}
                      onClick={() => setLooker('GAMING')}
                    >
                      <CardContent className={classes.cardContent}>
                        <div className={classes.download}>
                          <img
                            src={downloadIcon}
                            alt="dashboard"
                            width="100%"
                          />
                        </div>

                        <div className={classes.cardLabel}>
                          <Typography>App install</Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              </Grid>

              <Grid container item justify="flex-end" spacing={3} xs={6}>
                <Grid item xs={10}>
                  <Box mt={4} />

                  <TextField
                    fullWidth
                    select
                    color="secondary"
                    label="Primary Advertising KPI"
                    onChange={event => setKpi(event.target.value)}
                    value={kpi}
                    variant="outlined"
                  >
                    {kpiOptions.map(k => (
                      <MenuItem key={k.value} value={k.value}>
                        {k.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid container justify="flex-end" item xs={12}>
                  <Grid container justify="flex-end" item xs={6}>
                    <FormControl component="fieldset">
                      <Box mb={1}>
                        <FormLabel
                          color="secondary"
                          component="legend"
                          className={classes.radioHead}
                        >
                          Attribution Model
                        </FormLabel>
                      </Box>

                      <RadioGroup
                        aria-label="attribution model"
                        name="model"
                        value={attrModel}
                        onChange={event => setAttrModel(event.target.value)}
                      >
                        <FormControlLabel
                          value="LAST_TOUCH"
                          control={<Radio size="small" />}
                          label={
                            <Typography className={classes.radioLabel}>
                              Last touch
                            </Typography>
                          }
                        />

                        <FormControlLabel
                          value="FIRST_TOUCH"
                          control={<Radio size="small" />}
                          label={
                            <Typography className={classes.radioLabel}>
                              First touch
                            </Typography>
                          }
                        />

                        <FormControlLabel
                          value="LINEAR"
                          control={<Radio size="small" />}
                          label={
                            <Typography className={classes.radioLabel}>
                              Linear
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid container justify="flex-end" item xs={6}>
                    <FormControl component="fieldset">
                      <Box mb={1}>
                        <FormLabel
                          color="secondary"
                          component="legend"
                          className={classes.radioHead}
                        >
                          Attribution Window
                        </FormLabel>
                      </Box>

                      <RadioGroup
                        aria-label="attribution window"
                        name="window"
                        value={attrWindow}
                        onChange={event => setAttrWindow(event.target.value)}
                      >
                        <FormControlLabel
                          value={'30'}
                          control={<Radio size="small" />}
                          label={
                            <Typography className={classes.radioLabel}>
                              30 days
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={'14'}
                          control={<Radio size="small" />}
                          label={
                            <Typography className={classes.radioLabel}>
                              14 days
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={'7'}
                          control={<Radio size="small" />}
                          label={
                            <Typography className={classes.radioLabel}>
                              7 days
                            </Typography>
                          }
                        />

                        <FormControlLabel
                          value={'3'}
                          control={<Radio size="small" />}
                          label={
                            <Typography className={classes.radioLabel}>
                              3 days
                            </Typography>
                          }
                        />

                        <FormControlLabel
                          value={'2'}
                          control={<Radio size="small" />}
                          label={
                            <Typography className={classes.radioLabel}>
                              2 days
                            </Typography>
                          }
                        />

                        <FormControlLabel
                          value={'1'}
                          control={<Radio size="small" />}
                          label={
                            <Typography className={classes.radioLabel}>
                              1 days
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>

        <Box className={classes.footer}>
          <AsyncButton
            color="secondary"
            isDisabled={isNextDisabled}
            isLoading={isLoading}
            onClick={handleSaveAdvertiser}
            size="medium"
            textButton="Next"
            variant="contained"
          />
        </Box>
      </Box>
    </Container>
  );
};

AdvertiserWizard.propTypes = {
  onNext: PropTypes.func,
  showKPI: PropTypes.bool,
  showModel: PropTypes.bool,
  showWindow: PropTypes.bool,
  showSalesforce: PropTypes.bool,
};

export default AdvertiserWizard;
