export const getMinorityData = (data, key = 'selected') => {
  const [selected, unselected] = data.reduce((acc, curr) => {
    const index = curr[key] ? 0 : 1;

    acc[index].push(curr);

    return acc;
  }, [[], []]);

  const isSelectedMinority = selected.length < unselected.length || unselected.length === 0;

  return {
    data: isSelectedMinority ? selected : unselected,
    isAllSelected: data.length === selected.length,
    isAllUnselected: data.length === unselected.length,
    isSelectedMinority,
    selected,
    unselected,
  };
};
