import React, { useContext, useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Tabs,
  Tab,
  makeStyles,
  withStyles
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useAPI } from '../hooks/api';
import { useAdvertisers } from '../hooks/advertisers';
import AdvertiserContext from '../AdvertiserContext';
import AppHeader from '../AppHeader';
import BillingTable from '../BillingTable';
import PaymentMethods from '../PaymentMethods';
import Title from '../Title';
import infoIcon from '../../images/icons/info-icon.svg';
import { Themes } from '../../constants';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  root: {
    flexGrow: 1,
    marginTop: 60,
    marginBottom: 60,
  },
  tab: {
    fontSize: 20
  },
  helperTextWrap: {
    display: 'inline-block',
  },
  info: {
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'text-top',
    width: 14,
    height: 14,
  },
  paper: {
    paddingTop: spacing(5),
    paddingBottom: spacing(5),
    paddingLeft: spacing(9),
    paddingRight: spacing(9),
    maxWidth: 900,
    margin: 'auto',
  },
  submit: {
    ...typography.h4,
    color: '#fff',
    marginLeft: spacing(2),
    padding: spacing(2),
    width: 175,
  },
  subtitle: {
    ...typography.subtitle1,
    fontSize: '1.125rem',
  },
  success: {
    backgroundColor: palette.primary.light,
  },
  successMsg: {
    color: palette.primary.light,
  },
  title: {
    ...typography.h3,
  },
  tooltip: {
    backgroundColor: `#f2f3f5`,
    color: palette.text.primary,
    padding: spacing(2),
    maxWidth: 180,
  },
  wrap: {
    height: 'calc(100% - 64px)',
  },
}));

const lockedTooltip = `These fields can only be edited if there aren’t currently active campaigns. If you’d like to edit these fields, pause all campaigns first.`

const AdvertiserTab = () => {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);
  const { useGet, usePatch } = useAPI();
  const { updateAdvertiser } = useAdvertisers();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [category, setCategory] = useState('');

  useEffect(() => {
    if (adContext.id) {
      if (category === '') {
        getCategory();
      }

      if (name === '') {
        setName(adContext.name);
      }

      if (website === '') {
        setWebsite(adContext.domain);
      }
    }
  }, [adContext.category]);

  const getCategory = () => {
    if (adContext.category) {
      return useGet(adContext.category, true)
        .then(response => {
          if (response) {
            setCategory(response.name);
          }
          return response;
        })
        .catch(err => console.error(err));
    }
    return setCategory(`Uncategorized`)
  };

  const handleSubmit = () => {
    const { id } = adContext;

    setIsLoading(true);
    setIsSuccess(false);

    return usePatch(`/advertisers/${id}/`, { name })
      .then(response => {
        setIsLoading(false);
        setIsSuccess(true);

        if (response) {
          updateAdvertiser(response);
        }

        return response;
      })
      .catch(error => {
        setIsLoading(false);
        setIsSuccess(false);
        console.error(error);
      });
  };

  const renderHelperText = () => (
    <Grid container justify="flex-start" alignItems="center" spacing={1}>
      <Grid item>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={lockedTooltip}
        >
          <span className={classes.info}>
            <img src={infoIcon} />
          </span>
        </Tooltip>
      </Grid>

      <Grid item>
        Why is this field locked?
      </Grid>
    </Grid>
  );

  return (
    <Fragment>
      <Title className={classes.title}>Advertiser Settings</Title>

      <Box mt={3} mb={5}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              color="secondary"
              margin="none"
              fullWidth
              label="Advertiser Name"
              onChange={event => setName(event.target.value)}
              value={name}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              color="secondary"
              disabled
              helperText={adContext.active && renderHelperText()}
              margin="none"
              fullWidth
              label="Advertiser Website"
              value={website}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              color="secondary"
              disabled
              fullWidth
              helperText={adContext.active && renderHelperText()}
              label="Company Category"
              margin="none"
              onChange={(event) => setCategory(event.target.value)}
              value={category}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ArrowDropDownIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        style={{ textAlign: 'right' }}
        width="100%"
      >
        {isSuccess && (
          <Typography component={'span'} className={classes.successMsg}>
            Your settings have been saved!
          </Typography>
        )}

        <Button
          className={clsx(classes.submit)}
          color="secondary"
          disabled={isLoading}
          disableElevation={isSuccess}
          disableRipple
          onClick={handleSubmit}
          variant="contained"
        >
          {isLoading
            ? 'Saving...'
            : isSuccess
              ? 'Save Settings'
              : 'Save Settings'}
        </Button>
      </Box>
    </Fragment>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const StyledTab = withStyles({
  root: {
    marginRight: 100
  },
  selected: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#181c20 !important"
  },

})(Tab);

function Settings(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const adContext = useContext(AdvertiserContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <AppHeader history={props.history}>
      <div className={classes.root}>
        <Grid style={{ margin: '0 auto' }} item xs={9}>
          <Paper elevation={0} style={{ paddingLeft: 40, paddingRight: 40, paddingBottom: 100 }} >
            <Tabs
              style={{ padding: 30 }}
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="secondary"
            >
              <StyledTab className={classes.tab} label="Advertiser Settings" />
              <StyledTab className={classes.tab} label="Billing History" />
              {adContext.theme === Themes.DEFAULT && <StyledTab className={classes.tab} label="Payment Methods" />}
            </Tabs>

            <TabPanel value={value} index={0}>
              <AdvertiserTab {...props} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <BillingTable />
            </TabPanel>
            {adContext.theme === Themes.DEFAULT && (
              <TabPanel value={value} index={2}>
                <PaymentMethods />
              </TabPanel>
            )}
          </Paper>

        </Grid>
      </div>
    </AppHeader>
  );
}


Settings.propTypes = {
  history: PropTypes.object,
};

export default Settings;


