import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  // IconButton, // TODO: TBD - Add Custom Tracking Event
  InputAdornment,
  // InputBase, // TODO: TBD - Add Custom Tracking Event
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
// import AddCircleIcon from '@material-ui/icons/AddCircle'; // TODO: TBD - Add Custom Tracking Event
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from './ui/Accordians';
import { useTracking } from './hooks/tracking';
import InstallTrackingCode from './InstallTrackingCode';
import ModalWrapper from './ModalWrapper';

const useStyles = makeStyles(({ palette, spacing }) => ({
  activeButton: {
    fontSize: '1rem',
    backgroundColor: '#c3e7fa',
    color: '#181c20',
    fontWeight: 'normal',
    boxShadow: 'none !important',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    minWidth: 170,
    '&:hover': {
      backgroundColor: '#c3e7fa',
      cursor: 'unset',
    },
    '& .tvs-MuiSvgIcon-root': {
      marginRight: spacing(1),
    },
  },
  addEvent: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    borderRadius: '10px',
    backgroundColor: '#fff',
    width: '93%',
    padding: spacing(1),
    boxShadow:
      '0 5px 5px -3px rgba(24, 28, 32, 0.07), 0 3px 14px 2px rgba(24, 28, 32, 0.12), 0 8px 10px 1px rgba(24, 28, 32, 0.1)',
  },
  addEventBtn: {
    marginRight: spacing(1),
  },
  buttonGroup: {
    marginTop: spacing(2),
    boxShadow: 'none',
    '& .tvs-MuiButton-root': {
      border: 'none',
    },
  },
  cancel: {
    color: palette.grey.main,
  },
  container: {
    overflow: 'hidden',
    position: 'relative',
    height: 'auto',
  },
  ecommerce: {
    width: 220,
    marginRight: 5,

    '& .tvs-MuiSelect-root': {
      paddingTop: 14,
      paddingBottom: 12,
    },

    '& label': {
      marginLeft: 6,
    },

    '& fieldset': {
      border: 0,
      boxShadow: '0 5px 5px -3px rgb(24 28 32 / 7%), 0 3px 14px 2px rgb(24 28 32 / 12%), 0 2px 4px 1px rgb(24 28 32 / 10%)',
    },
  },
  footer: {
    backgroundColor: '#e5e7eb',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  gradient: {
    bottom: 101,
    pointerEvents: 'none',
  },
  inactiveButton: {
    fontSize: '1rem',
    backgroundColor: '#ffffff',
    color: '#181c20',
    fontWeight: 'normal',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    minWidth: 170,
    '&:hover': {
      color: '#ffffff',
    },
  },
  noEvents: {
    color: '#9aa0a6',
    fontSize: '1.0875rem',
    fontWeight: 600,
  },
  panelHeader: {
    backgroundColor: '#e5e7eb',
  },
  panels: {
    height: 720,
    overflow: 'auto',
    minHeight: 720,
  },
}));

// Edit sections for tracking
const trackingPanels = [
  { title: '1. Select Tracking Method', id: 'method' },
  { title: '2. Select Tracking Event', id: 'events' },
  { title: '3. Configure Tracking Event', id: 'configure' },
  { title: '4. Install Tracking Code', id: 'code' },
];

const ManageTracking = ({ exposureLid, isOpen, onClose, outcomeLid }) => {
  const classes = useStyles();

  const {
    ecommerce,
    ecommerceSites,
    handleEventSlug,
    handleEventValue,
    handleSelectEvent,
    trackingMethod,
    selectedEvents,
    setEcommerce,
    setTrackingMethod,
    totalEvents,
  } = useTracking('pixel');

  // TODO: TBD - Add Custom Tracking Event
  // const [customEventName, setCustomEventName] = useState('');

  const [expanded, setExpanded] = useState({
    method: true,
    events: false,
    configure: false,
    code: false,
  });

  const handleExpanded = panel => event => {
    if (event && event.target != null) {
      setExpanded(x => ({
        ...x,
        [panel]: !x[panel],
      }));
    }
  };

  // TODO: TBD - Add Custom Tracking Event
  // const handleCustomEvent = () => {
  //   if (customEventName && customEventName !== '') {
  //     const eventData = {
  //       name: customEventName.trim(),
  //       slug: customEventName.trim().replace(/[ -]/g, '_'),
  //       value: '',
  //     };

  //     if (selectedEvents.find(event => event.name === eventData.name)) {
  //       setSelectedEvents(prev =>
  //         prev.filter(item => item.name !== eventData.name)
  //       );

  //       setTotalEvents(prev => prev.filter(item => item.name !== eventData.name));
  //     } else {
  //       setSelectedEvents(prev => [...prev, eventData]);
  //       setTotalEvents(prev => [...prev, eventData]);
  //       setCustomEventName('');
  //     }
  //   }
  // };

  const renderMethod = () => (
    <ButtonGroup
      variant="contained"
      color="primary"
      aria-label="contained primary button group"
      className={classes.buttonGroup}
    >
      <Button
        disableRipple
        color="secondary"
        size="large"
        className={
          trackingMethod === 'pixel'
            ? classes.activeButton
            : classes.inactiveButton
        }
        onClick={() => setTrackingMethod('pixel')}
      >
        Tracking Pixel
      </Button>

      <Button
        disableRipple
        color="secondary"
        size="large"
        className={
          trackingMethod === 'postback'
            ? classes.activeButton
            : classes.inactiveButton
        }
        onClick={() => setTrackingMethod('postback')}
      >
        Postback
      </Button>
    </ButtonGroup>
  );

  const renderNoEvents = () => (
    <Box
      display="flex"
      justifyContent="center"
      py={2}
      width="100%"
    >
      <Typography className={classes.noEvents}>
        Please select one or more tracking events (Step 2)
      </Typography>
    </Box>
  );

  const renderEvents = () => (
    <Grid container>
      <Grid container item justify="flex-end" xs={12}>
        <Box mb={4} mr={3} mt={1}>
          <TextField
            color="secondary"
            className={classes.ecommerce}
            label="Ecommerce Platform"
            onChange={event => setEcommerce(event.target.value)}
            placeholder="Ecommerce Platform"
            select
            size="small"
            value={ecommerce}
            variant="outlined"
          >
            {ecommerceSites.map((e, i) => (
              <MenuItem key={e} value={e.replace(/\s+/g, '-').toLowerCase()}>
                {i === 0 ? <em>{e}</em> : e}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Grid>

      <Grid container item xs={12}>
        {totalEvents.map(t => (
          <Grid item xs={4} key={t.slug}>
            <Box m={1} mr={2} className={clsx('Button-choice', 'full')}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!selectedEvents.find(e => e.name === t.name)}
                    name={t.name}
                    onClick={() => handleSelectEvent(t)}
                  />
                }
                label={t.name}
              />
            </Box>
          </Grid>
        ))}

        {/* TODO: TBD - Add Custom Tracking Event */}
        {/* <Grid id="new-event-container" item xs={4}>
          <Box className={classes.addEvent}>
            <IconButton
              className={classes.addEventBtn}
              onClick={handleCustomEvent}
              size="small"
            >
              <AddCircleIcon color="secondary" />
            </IconButton>

            <InputBase
              inputProps={{ 'aria-label': 'naked' }}
              onChange={event => setCustomEventName(event.target.value)}
              placeholder="Add tracking event"
              value={customEventName}
            />
          </Box>
        </Grid> */}
      </Grid>
    </Grid>
  );

  const renderConfigureEvent = tracking => (
    <Fade in timeout={200}>
      <Box mb={2} width="100%">
        <Grid
          container
          item
          alignItems="center"
          justify="space-between"
          spacing={2}
          xs={12}
        >
          <Grid item xs={4}>
            <TextField
              disabled
              color="secondary"
              className={classes.textField}
              label="Tracking Event"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              value={tracking.name}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              color="secondary"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              label="Event Slug"
              defaultValue={tracking.slug}
              onChange={event =>
                handleEventSlug(event.target.value, tracking.name)
              }
              variant="outlined"
              fullWidth
            >
              {tracking.slug}
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <TextField
              color="secondary"
              className={classes.textField}
              defaultValue={tracking.value}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              onChange={event =>
                handleEventValue(event.target.value, tracking.name)
              }
              label="Enter Value"
              placeholder="Enter Value"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );

  const renderConfigure = () => {
    if (!selectedEvents.length) {
      return renderNoEvents();
    }

    return (
      <Box mt={2} width="100%">
        <Grid container>
          {selectedEvents.length > 0 &&
            selectedEvents.map(e => (
              <Grid key={e.name} container item xs={12}>
                {renderConfigureEvent(e)}
              </Grid>
            ))}
        </Grid>
      </Box>
    );
  };

  const renderCode = () => (
    <InstallTrackingCode
      exposureLid={exposureLid}
      outcomeLid={outcomeLid}
      trackingMethod={trackingMethod}
      selectedEvents={selectedEvents}
      ecommerce={ecommerce}
    />
  );

  // // Handle component renders
  const renderPanelDetails = id => {
    switch (id) {
      case 'method':
        return renderMethod();
      case 'events':
        return renderEvents();
      case 'configure':
        return renderConfigure();
      case 'code':
        return renderCode();
      default:
        return null;
    }
  };

  // Render an accordion panel for each editable section
  const renderPanel = panel => (
    <Accordion
      expanded={expanded[panel.id]}
      onChange={handleExpanded(panel.id)}
      TransitionProps={{ unmountOnExit: panel.id !== 'demo' }}
    >
      <AccordionSummary aria-controls="adgroup-panel" id={`edit-${panel.id}`}>
        <Grid
          className={classes.panelHeader}
          container
          alignItems="center"
          justify="space-between"
        >
          <Grid item>
            <Typography>{panel.title}</Typography>
          </Grid>

          <Grid item>
            {expanded[panel.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Box py={1} px={2} width="100%">
          {renderPanelDetails(panel.id)}
        </Box>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <Box flexGrow={1}>
        <Box mb={4}>
          <Typography variant="h3">Add Tracking Event</Typography>
        </Box>

        <Grid container spacing={3}>
          {trackingPanels.map((s, i) => (
            <Grid key={s.id} item xs={12}>
              {renderPanel(s, i)}
            </Grid>
          ))}
        </Grid>
      </Box>
    </ModalWrapper>
  );
};

ManageTracking.propTypes = {
  isOpen: PropTypes.bool,
  exposureLid: PropTypes.string,
  onClose: PropTypes.func,
  outcomeLid: PropTypes.string,
};

export default ManageTracking;
