import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  Grid,
  Switch,
  Typography,
  FormControlLabel,
  makeStyles,
  withStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  item: {
    padding: '12px 0',
    borderBottom: '1px solid rgba(154, 160, 166, 0.5)',

    '&:last-child': {
      borderBottom: 'none',
    },
  },
  itemText: {
    color: '#fff',
    fontWeight: 'normal',
  },
  switchText: {
    color: '#fff',
    fontWeight: 'normal',
  },
  disabledText: {
    color: '#5c6b73',
  },
}));

const StyledFormControlLabel = withStyles({
  root: {
    marginLeft: 10,
    color: 'white',
  },
})(FormControlLabel);

const StyledSwitch = withStyles({
  switchBase: {
    color: '#ff6577',
  },
  track: {
    backgroundColor: '#8b3d46',
  },
})(Switch);

const IncludeToggle = ({
  title,
  name,
  control
}) => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      xs={12}
      alignItems="center"
      justify="space-between"
      className={classes.item}
    >
      <Grid>
        <Typography className={classes.itemText}>
          {title}
        </Typography>
      </Grid>
      <Grid>
        <Controller
          render={({ field: { value, ...restField } }) => (
            <StyledFormControlLabel
              control={
                <Grid
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item className={clsx(classes.switchText, value && classes.disabledText)}>Exclude</Grid>
                  <Grid item>
                    <StyledSwitch
                      {...restField}
                      checked={value}
                      size="small"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Grid>
                  <Grid item className={clsx(classes.switchText, !value && classes.disabledText)}>Include</Grid>
                </Grid>
              }
            />
          )}
          name={name}
          defaultValue="false"
          control={control}
        />
      </Grid>
    </Grid>
  );
};

IncludeToggle.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
};

export default IncludeToggle;
