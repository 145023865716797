import React, { Suspense, lazy, useState, useEffect, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Fade,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  FormControl,
  ListItemText
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Commented imports to be added in later
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
// import QueueOutlinedIcon from '@material-ui/icons/QueueOutlined';
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
// import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import DeleteIcon from '@material-ui/icons/Delete';

import AdvertiserContext from '../../AdvertiserContext';
import AppHeader from '../../AppHeader';
import DeleteDraftCampaign from '../../DeleteDraftCampaign';
import LoadingSpinner from '../../ui/LoadingSpinner';
import ManageBudget from '../../ManageBudget';
import ModalOverlay from '../../ModalOverlay';
import SortableTableHead from '../../ui/SortableTableHead';
import { api } from "../../../connection/TVSApi";
import { useAPI } from '../../hooks/api';
import { useLoader } from '../../hooks/loader';
import { useSort } from '../../hooks/sort';
import { formatToUSD } from '../../util';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import { useAdvertisers } from '../../hooks/advertisers';
import { useLogRocket } from '../../hooks/logRocket';
import { getStatus } from './getStatus';
import { Themes } from '../../../constants';
import { Statuses, StatusList, StatusKeys, StatusNames } from './statuses';
import AlertBox from '../../AlertBox';
import UserContext from '../../../providers/UserContext';

const ManageCampaign = lazy(() => import('../../ManageCampaign'));

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  link: {
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: '700',
  },
  draft: {
    color: '#EB9D00',
    background: 'rgba(255, 171, 5, 0.25)',
    // fontSize: '0.625rem',
  },
  active: {
    color: '#0fbf84',
    background: 'rgba(122, 220, 187, 0.55)',
    // fontSize: '0.625rem',
  },
  divider: {
    marginLeft: spacing(1),
    // marginRight: spacing(1),
  },
  paused: {
    color: '#ff0000',
    background: 'rgba(255, 0, 0, 0.31)',
    // fontSize: '0.625rem',
  },
  fade: {
    position: 'relative',
  },
  font: {
    // fontSize: '0.75rem',
  },
  scheduled: {
    color: '#1dafff',
    background: 'rgba(29, 175, 255, 0.24)',
  },
  stats: {
    fontWeight: '700',
  },
  tableCell: {
    // fontSize: '0.75rem',
    minHeight: 81,
  },
  height: {
    // fontSize: '0.75rem',
    minHeight: 81,
    height: 81,
  },
  manageBtn: {
    fontWeight: 500,
    maxWidth: 100,
  },
  formControl: {
    margin: spacing(1),
    minWidth: 120,
  },
  menuIcon: {
    marginRight: 12,
  },
  container: {
    paddingTop: spacing(4),
    paddingBottom: spacing(4),
    maxWidth: 'none'
  },
  campaignContainerSpacing: {
    padding: spacing(2),
    maxWidth: 'none'
  },
  loader: {
    height: 110,

    [breakpoints.up('md')]: {
      height: 125,
    },

    [breakpoints.up('lg')]: {
      height: 165,
    },

    [breakpoints.up('xl')]: {
      height: 210,
    },
  },
  pageLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    background: 'rgba(255,255,255,0.6)',
  },
  paper: {
    minHeight: 110,
    padding: spacing(5),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',

    [breakpoints.up('md')]: {
      minHeight: 238,
    },

    [breakpoints.up('lg')]: {
      minHeight: 298,
    },

    [breakpoints.up('xl')]: {
      minHeight: 468,
    },
  },
  modal: {
    overflowY: 'auto',
  }
}));

const headCells = [
  { id: 'id', numeric: true, sortable: true, label: 'ID', disablePadding: false },
  { id: 'name', numeric: false, sortable: true, label: 'Campaign', disablePadding: false },
  { id: 'start_date', numeric: false, sortable: true, label: 'Start date', disablePadding: false },
  { id: 'end_date', numeric: false, sortable: true, label: 'End date', disablePadding: false },
  { id: 'daily_budget', numeric: true, sortable: true, label: 'Daily Budget', disablePadding: false },
  { id: 'spend', numeric: true, sortable: true, label: 'Spend', disablePadding: false },
  { id: 'budgetRemaining', numeric: true, sortable: true, label: 'Budget remaining', disablePadding: false },
  { id: 'conversions', numeric: true, sortable: true, label: 'Conversions', disablePadding: false },
  { id: 'costPerVisit', numeric: true, sortable: true, label: 'Cost per visit', disablePadding: false },
  { id: 'status', numeric: false, sortable: true, label: 'Status', disablePadding: false },
  { id: 'action', numeric: false, sortable: false, label: 'Action', disablePadding: true },
];

const CampaignsPage = (props) => {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);
  const user = useContext(UserContext);
  const QUICKSIGHT_ALL = "[ALL]";

  const { useGetAll, usePatch, useGet } = useAPI();
  const location = useLocation();
  const { updateAdvertiser } = useAdvertisers();
  const { isLoading, setIsLoading } = useLoader(true);
  const { isLoading: isPageLoading } = useLoader();
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const {
    order,
    orderBy,
    handleRequestSort,
    getComparator,
    stableSort
  } = useSort();

  const [alert, setAlert] = useState(null);
  const [isAlertOpen, setAlertOpen] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [selected, setSelected] = useState([]);
  const [currentAnchorEl, setCurrentAnchorEl] = useState(null);
  const [targetMenu, setTargetMenu] = useState(null);
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [isCampaign, setIsCampaign] = useState(false);
  const [isDupeCampaign, setIsDupeCampaign] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [campaignId, setCampaignId] = useState(QUICKSIGHT_ALL);
  const [quicksightParams, setQuicksightParams] = useState({ campaignId: QUICKSIGHT_ALL });
  const [quicksightDashboard, setQuicksightDashboard] = useState({});
  const [embedUrl, setEmbedUrl] = useState(null);
  const [isEditBudget, setIsEditBudget] = useState(false);
  const [isDeleteDraft, setIsDeleteDraft] = useState(false);
  const [statuses, setStatuses] = useState(StatusKeys);
  const { tagUser } = useLogRocket();

  useEffect(() => {
    if (!user.is_tvsci_employee && adContext.cost_model === 'CPA') {
      history.push("/reports");
    }
  }, [user, adContext]);

  useEffect(() => {
    // Tag user okta id, name and email in Log Rocket
    tagUser();
  }, []);

  useEffect(() => {
    if (location.state) {
      updateAdvertiser(location.state.advertiser);
    }
  }, [location.state]);

  useEffect(() => {
    if (adContext.id !== null) {
      getDashboardData('/reporting-embed-url?report=dashboard_chart');
      getCampaigns();
    }
  }, [adContext]);

  useEffect(() => {
    // TODO: properly consume updated data
    // instead of making another api call
    if (hasSaved) {
      getCampaigns();
    }
  }, [hasSaved]);

  useEffect(() => {
    getAlerts()
  }, [])

  function getCampaigns() {
    return useGetAll('/campaigns', [], total => {
      // Sorting campaigns by status by default Active -> Draft -> Paused
      total.sort((a, b) => StatusKeys.indexOf(getStatus(a.active, a.draft)) - StatusKeys.indexOf(getStatus(b.active, b.draft)));
      total.sort((a, b) => b.id - a.id);
      setCampaigns(total.map(campaign => ({
        ...campaign,
        status: getStatus(campaign.active, campaign.draft)
      })));
    });
  }

  const handleSaveCampaign = () => {
    setHasSaved(true);
    setIsDupeCampaign(false);
  };

  const handleStatusesChange = (event) => {
    const { value } = event.target;
    const nextStatuses = typeof value === 'string' ? value.split(',') : value;

    if (nextStatuses[nextStatuses.length - 1] === 'all') {
      setStatuses(statuses.length === StatusKeys.length ? [] : StatusKeys);

      return;
    }

    setStatuses(nextStatuses);
  };

  const handleManageCampaign = campaign => {
    console.log('campaign', campaign);
    setHasSaved(false);
    setCurrentCampaign(campaign);
    setIsCampaign(true);
  };

  const handleDuplicateCampaign = async campaign => {
    setCurrentCampaign(campaign);
    setIsDupeCampaign(true);
    setIsCampaign(true);
    setHasSaved(false);
  };

  const handleDraftCampaign = campaign => {
    props.history.push(`/campaign-edit/${campaign}`);
  }

  const handleDeleteDraft = campaign => {
    setCurrentCampaign(campaign);
    setIsDeleteDraft(true);
    setHasSaved(false);
  };

  const handleCloseDeleteDraft = () => {
    setHasSaved(true);
    setIsDeleteDraft(false);
  };

  const handleManageBudget = campaign => {
    setHasSaved(false);
    setCurrentCampaign(campaign);
    setIsEditBudget(true);
  };

  const handleCloseBudget = () => {
    setIsEditBudget(false);
    setCurrentCampaign(null);
  };

  const handlePauseActive = (campaign) => {
    return usePatch(`/campaigns/${campaign.id}/`, {
      active: !campaign.active
    }).then((res) => {
      setCampaigns(campaigns.map(c => {
        if (c.id !== campaign.id) {
          return {
            ...c,
            status: getStatus(c.active, c.draft)
          };
        }

        return {
          ...c,
          active: res.data.active,
          status: getStatus(res.data.active, c.draft)
        };
      }));
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = campaigns.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleOpenMenu = (event, rowId) => {
    setCurrentAnchorEl(event.currentTarget);
    setTargetMenu(null);
    setTargetMenu(rowId);
  };

  const handleCloseMenu = () => {
    setCurrentAnchorEl(null)
    setTargetMenu(null);
    // setIsCampaign(false);
  };

  const campaignPauseActivateRender = (status) => {
    if (status === Statuses.ACTIVE) {
      return (
        <>
          <PauseCircleOutlineOutlinedIcon
            fontSize="small"
            className={classes.menuIcon}
          /> Pause Campaign
        </>
      );
    }

    return (
      <>
        <PlayCircleOutlineIcon
          fontSize="small"
          className={classes.menuIcon}
        />

        Activate Campaign
      </>
    );
  }

  useEffect(() => {
    if (embedUrl) {
      handleQsEmbed(embedUrl);
    }
  }, [embedUrl]);

  const handleQsEmbed = async (embedUrl) => {
    // https://www.npmjs.com/package/amazon-quicksight-embedding-sdk#step-2-configure-embedding
    const quicksightOptions = {
      url: embedUrl,
      container: "#quicksightEmbedContainer",
      height: "AutoFit"
    };

    if (document.getElementById("quicksightEmbedContainer").firstChild) {
      document.getElementById("quicksightEmbedContainer").firstChild.remove()
    }

    const dashboard = await embedDashboard(quicksightOptions);

    setQuicksightDashboard(dashboard);
    setIsLoading(false);
  };

  async function getDashboardData(url) {
    setIsLoading(true);

    const { id } = adContext;

    // TODO: use `useGet` hook when trailing slash enabled
    const response = await api
      .get(url, {
        'X-TVS-AdvertiserContext': `${id}`,
        'Authorization': `Bearer ${await oktaAuth.getAccessToken()}`
      });

    setEmbedUrl(response.data.embedUrl)
  }

  async function getAlerts(){
    setIsLoading(true)
    const alerts = await useGet('/alerts')
    setAlert(alerts.activeAlert !== null ? alerts : null)
  }

  function setQuicksightFilter(newValue) {
    setCampaignId(newValue);
    quicksightParams.campaignId = newValue;
    setQuicksightParams(quicksightParams)
    quicksightDashboard.setParameters(quicksightParams)
  }

  const formatEndDate = (endDate) => {
    if (Date.parse(endDate)) {
      return moment(endDate).format('l')
    }
    return 'No End Date'
  };

  const sortedCampaigns = stableSort(
    campaigns,
    getComparator(order, orderBy)
  );

  return (
    <AppHeader
      theme={adContext.theme}
      history={props.history}
    >
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          {
            alert !== null && (
              <Grid item xs={12}>
                <AlertBox
                  {...alert.activeAlert}
                  isAlertOpen={isAlertOpen}
                  closeAlert={setAlertOpen}
                />
              </Grid>
            )
          }
          <Grid item xs={12}>
            <Paper
              className={clsx(classes.paper, 'background-img-inline-example')}
            >
              <Container className={classes.container}>
                <InputLabel id="campaign-select-label">Campaign</InputLabel>
                <Select
                  labelId="campaign-select-label"
                  id="campaign-select"
                  value={campaignId}
                  onChange={(event) => setQuicksightFilter(event.target.value)}
                >
                  <MenuItem value={QUICKSIGHT_ALL}>All</MenuItem>
                  {campaigns.map((campaign) => (
                    <MenuItem key={campaign.id} value={campaign.id}>
                      {campaign.name}
                    </MenuItem>
                  ))}
                </Select>
              </Container>

              {isLoading &&
                <Box
                  className={classes.loader}
                  display="flex"
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress color="secondary" />
                </Box>}

              <Fade in={!isLoading} timeout={1500}>
                <div id="quicksightEmbedContainer" />
              </Fade>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Box
        border={1}
        borderColor="grey.300"
        p={6}
        pt={4}
        m={4}
        borderRadius={20}
      >
        <Grid
          container
          xs={12}
          justify="space-between"
        >
          <Grid
            item
            direction="column"
            justify="space-between"
            disableGutters
          >
            <Typography variant='h6' component='div'>
              Active Campaigns
            </Typography>
          </Grid>

          <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="status-multiple-checkbox-label">Status</InputLabel>
              <Select
                multiple
                color="secondary"
                id="status-multiple-checkbox"
                labelId="status-multiple-checkbox-label"
                label="Status"
                value={statuses}
                onChange={handleStatusesChange}
                renderValue={(selected) => {
                  const values = selected.map(item => StatusNames[item]).join(', ');
                  const text = selected.length === StatusList.length ? 'All' : values;

                  return `Showing [${text}] status`;
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250
                    }
                  }
                }}
              >
                <MenuItem value="all">
                  <Checkbox
                    checked={statuses.length === StatusList.length}
                    indeterminate={statuses.length > 0 && statuses.length < StatusList.length}
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {StatusList.map(({ name, value }) => (
                  <MenuItem key={value} value={value}>
                    <Checkbox checked={statuses.includes(value)} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <TableContainer>
          <Table>
            <SortableTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onSelectAll={handleSelectAllClick}
            />

            <TableBody>
              {sortedCampaigns
                .filter((campaign) => statuses.includes(campaign.status))
                .map((campaign, index) => {
                  const {
                    id,
                    status,
                    name,
                    stats_cache: stats,
                    start_date,
                    end_date,
                    daily_budget,
                    experiment_type,
                  } = campaign;
                  const isItemSelected = isSelected(id);
                  const labelId = `active-campaigns-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, id)}
                      role="checkbox"
                      tabIndex={-1}
                      key={`${campaign.id}-${index}`}
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          size="small"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell>{campaign.id}</TableCell>
                      <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" padding="none">
                        <Link
                          className={classes.link}
                          to={{
                            pathname: status === Statuses.DRAFT
                              ? `/campaign-edit/${campaign.id}`
                              : `/campaigns/${campaign.id}/adgroups`,
                            state: { campaign }
                          }}
                        >
                          {name}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">{moment(start_date).format('l')}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{formatEndDate(end_date)}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{`$${daily_budget}`}</TableCell>
                      <TableCell className={clsx(classes.tableCell, classes.stats)} align="left">{stats != null ? formatToUSD(stats.total_spend) : '-'}</TableCell>
                      <TableCell className={clsx(classes.tableCell, classes.stats)} align="left">{stats != null ? formatToUSD(stats.budget_remaining) : '-'}</TableCell>
                      <TableCell className={clsx(classes.tableCell, classes.stats)} align="left">
                        {/* TODO Fix hardcoded hyphen */}
                        {/* {stats != null ? stats.action_count : '-'} */}
                        -
                      </TableCell>
                      <TableCell className={clsx(classes.tableCell, classes.stats)} align="left">
                        {/* TODO Fix hardcoded hyphen */}
                        {/* {(stats && stats.cost_per_visit !== null) ? formatToUSD(stats.cost_per_visit) : '-'} */}
                        -
                      </TableCell>
                      <TableCell className={classes.height} align="left">
                        {status === Statuses.DRAFT && (
                          <Chip
                            label="Draft"
                            className={classes.draft}
                          />
                        )}
                        {status === Statuses.ACTIVE && (
                          <Chip
                            label="Active"
                            className={classes.active}
                          />
                        )}
                        {status === Statuses.PAUSED && (
                          <Chip
                            label="Paused"
                            className={classes.paused}
                          />
                        )}
                      </TableCell>
                      {status === Statuses.DRAFT ?
                        <TableCell className={clsx(classes.font, classes.height)} align="right">
                          <Button
                            className={classes.manageBtn}
                            disableRipple
                            endIcon={<ExpandMoreIcon />}
                            onClick={(event) => handleOpenMenu(event, campaign.id)}
                          >
                            Continue
                            <Divider
                              className={classes.divider}
                              flexItem
                              orientation="vertical"
                            />
                          </Button>

                          <Menu
                            anchorEl={currentAnchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            open={targetMenu === id}
                            onClick={handleCloseMenu}
                          >
                            <MenuItem
                              onClick={() => handleDraftCampaign(id)}
                            >
                              <CreateOutlinedIcon
                                fontSize="small"
                                className={classes.menuIcon}
                              />

                              Edit
                            </MenuItem>

                            <MenuItem
                              onClick={() => handleDeleteDraft(campaign)}
                            >
                              <DeleteIcon
                                fontSize="small"
                                className={classes.menuIcon}
                              />

                              Delete
                            </MenuItem>

                          </Menu>
                        </TableCell> :
                        <TableCell className={clsx(classes.font, classes.height)} align="right">
                          <Button
                            className={classes.manageBtn}
                            disableRipple
                            endIcon={<ExpandMoreIcon />}
                            onClick={(event) => handleOpenMenu(event, id)}
                          >
                            Manage
                            <Divider
                              className={classes.divider}
                              flexItem
                              orientation="vertical"
                            />
                          </Button>

                          <Menu
                            anchorEl={currentAnchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            open={targetMenu === id}
                            onClick={handleCloseMenu}
                          >
                            {!experiment_type &&
                              <MenuItem
                                onClick={() => handleManageCampaign(campaign)}
                              >
                                <CreateOutlinedIcon
                                  fontSize="small"
                                  className={classes.menuIcon}
                                />

                                Manage Campaign
                              </MenuItem>}

                            <MenuItem
                              onClick={() => handleDuplicateCampaign(campaign)}
                            >
                              <FileCopyOutlinedIcon
                                fontSize="small"
                                className={classes.menuIcon}
                              />

                              Duplicate Campaign
                            </MenuItem>

                            {!experiment_type &&
                              <MenuItem
                                onClick={() => handleManageBudget(campaign)}
                              >
                                <AttachMoneyIcon
                                  fontSize="small"
                                  className={classes.menuIcon}
                                />

                                Edit Budget
                              </MenuItem>}

                            {/* <MenuItem onClick={handleCloseMenu}>
                            <QueueOutlinedIcon className={classes.menuIcon} /> Duplicate...
                          </MenuItem> */}

                            <MenuItem onClick={() => handlePauseActive(campaign)}>
                              {campaignPauseActivateRender(status)}
                            </MenuItem>

                            {/* <MenuItem onClick={handleCloseMenu}>
                            <ArchiveOutlinedIcon className={classes.menuIcon} /> Archive Campaign
                          </MenuItem> */}
                          </Menu>
                        </TableCell>}

                    </TableRow>
                  );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {isEditBudget &&
          <ManageBudget
            isModal
            isOpen={isEditBudget}
            currentCampaign={currentCampaign}
            onClose={handleCloseBudget}
            setHasSaved={setHasSaved}
          />}

        {isCampaign &&
          <ModalOverlay
            className={classes.modal}
            isOpen={isCampaign}
            onClose={() => {
              setIsCampaign(false);
              setIsDupeCampaign(false);
            }}
          >
            <Suspense fallback={<LoadingSpinner />}>
              <ManageCampaign
                showTestIncrementality={
                  adContext.theme === Themes.DEFAULT
                  || (
                    adContext.theme === Themes.NBCU
                    && !!currentCampaign.experiment_type
                  )
                }
                showType={adContext.theme === Themes.DEFAULT}
                isDupeCampaign={isDupeCampaign}
                setHasSaved={handleSaveCampaign}
                setIsCampaign={setIsCampaign}
                setIsDupeCampaign={setIsDupeCampaign}
                currentCampaign={currentCampaign}
              />
            </Suspense>
          </ModalOverlay>}

        {isDeleteDraft &&
          <DeleteDraftCampaign
            isModal
            isOpen={isDeleteDraft}
            campaign={currentCampaign}
            onClose={handleCloseDeleteDraft}
            setHasSaved={setHasSaved}
          />}

        {isPageLoading &&
          <Box width="100%" height="100%" className={classes.pageLoader}>
            <CircularProgress color="secondary" />
          </Box>}

      </Box>
    </AppHeader>
  );
}

CampaignsPage.propTypes = {
  history: PropTypes.object,
};

export default CampaignsPage;
