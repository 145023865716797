import { useState } from 'react';

import { useAPI } from './api';
import { useGeo } from './geo';

export const useAdGroups = () => {
  const { useGetAll, usePatch } = useAPI();
  const { formatGeo, formatGeoForClient } = useGeo();

  const [adGroups, setAdGroups] = useState([]);
  const [isAdGroup, setIsAdGroup] = useState(false);
  const [currentAdGroup, setCurrentAdGroup] = useState(null);

  const getAdGroups = async (campaignUrl, campaignStep) => {
    let draftAdGroups;

    try {
      await useGetAll('/lineitems', [], total => {
        const filtered = total.filter(l => l.campaign === campaignUrl);

        draftAdGroups = filtered.map(l => {
          const parsed = JSON.parse(l.targeting);

          return {
            ...l,
            targeting: {
              ...parsed,
              geo: formatGeoForClient(parsed.geo),
            },
          };
        });

        if (draftAdGroups && draftAdGroups.length > 0) {
          if (draftAdGroups.length === 1) {
            if (['LaunchCampaign', 'TrackingSetup'].includes(campaignStep)) {
              setAdGroups(draftAdGroups);
            } else {
              setAdGroups([]);
            }
          } else {
            if (['LaunchCampaign', 'TrackingSetup'].includes(campaignStep)) {
              setAdGroups(draftAdGroups);
            } else {
              setAdGroups([...draftAdGroups.slice(0, -1)]);
            }
          }
        }
      });

      return draftAdGroups;
    } catch (error) {
      console.error('Error getting ad groups', error);
      return error;
    }
  };

  const getDisplays = async (campaignUrl) => {
    let draftDisplays;

    await useGetAll('/static_display_lineitems', [], total => {
      const filtered = total.filter(l => l.campaign === campaignUrl);

      draftDisplays = filtered;
    });

    return draftDisplays;
  };

  const saveAdGroups = (adGroups, campaign) => {
    const {
      amount,
      display,
      isDisplay,
      url,
    } = campaign;

    if (adGroups && adGroups.length > 0) {
      const formattedBudget = !isDisplay
        ? amount
        : (amount - display.displayBudget).toFixed(2);

      let totalBudget = formattedBudget;
      const adGroupBudget = (formattedBudget / adGroups.length).toFixed(2);

      // Store requests as promises in array
      const adGroupRequests = adGroups.map((group, index) => {
        const { targeting } = group;

        totalBudget = totalBudget - adGroupBudget;

        // Divide by number of lineitems and add excess to last one
        const adGroupDaily =
          index !== adGroups.length - 1
            ? adGroupBudget
            : Math.max(adGroupBudget, totalBudget);

        const targetingGeo = Array.isArray(targeting.geo)
          ? formatGeo(targeting.geo)
          : targeting.geo;

        const data = {
          ...group,
          daily_budget: adGroupDaily,
          targeting: JSON.stringify({
            ...targeting,
            geo: targetingGeo,
          }),
          campaign: url,
          draft: false,
        };

        return usePatch(`/lineitems/${group.id}`, data);
      });

      return Promise.all(adGroupRequests)
        .then((...responses) => {
          return responses.map(g => {
            console.log('Respone from /lineitems', g);
            return g;
          });
        })
        .catch(error => console.error(error));
    }
  };

  return {
    adGroups,
    isAdGroup,
    currentAdGroup,
    setCurrentAdGroup,
    setIsAdGroup,
    setAdGroups,
    getAdGroups,
    getDisplays,
    saveAdGroups,
  };
};
