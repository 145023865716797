import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import styled from '@emotion/styled'

const StyledButton = styled(Button)`
  opacity: 0.5;
  border-radius: 4px;
  background-color: #eeeeee;
`

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    marginBottom: 43,
  },
  title: {
    flex: '1 1 60%',
    fontSize: '2rem'
  },
}))

const TableToolbar = props => {
  const classes = useToolbarStyles()
  const {
    setIsEnterCard
  } = props
  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h3" id="tableTitle">
        Payment Methods
      </Typography>

      <div>
        <Tooltip title="Add">
          <StyledButton
            variant="contained"
            startIcon={<AddIcon />}
            disableElevation
            aria-label="add"
            onClick={setIsEnterCard}
          >
            ADD PAYMENT METHOD
          </StyledButton>
        </Tooltip>
      </div>
    </Toolbar>
  )
}

TableToolbar.propTypes = {
  setIsEnterCard: PropTypes.func.isRequired,
}

export default TableToolbar
