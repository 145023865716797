import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const SCRIPT_URL = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
const DEFAULT_SCRIPT_ID = 'cf-turnstile-script';
const DEFAULT_ONLOAD_NAME = 'onloadTurnstileCallback';
const DEFAULT_CONTAINER_ID = 'cf-turnstile';

const isScriptInjected = (scriptId) => !!document.querySelector(`#${scriptId}`);

const injectTurnstileScript = ({
   render,
   onLoadCallbackName,
   onLoad,
   scriptOptions: { nonce = '', defer = true, async = true, id = '', appendTo } = {}
 }) => {
  const scriptId = id || DEFAULT_SCRIPT_ID;

  // Script has already been injected, just call onLoad and does nothing else
  if (isScriptInjected(scriptId)) {
    onLoad();
    return;
  }

  // Generate the js script
  const js = document.createElement('script');
  js.id = scriptId;

  const params = {
    render: render === 'explicit' ? render : '',
    onload: render === 'explicit' ? onLoadCallbackName : ''
  }
  const searchParams = new URLSearchParams(params);
  js.src = `${SCRIPT_URL}?${searchParams}`;

  if (nonce) {
    js.nonce = nonce;
  }

  js.defer = !!defer;
  js.async = !!async;
  js.onload = onLoad;

  // Append it to the body|head
  const elementToInjectScript = appendTo === 'body' ? document.body : document.getElementsByTagName('head')[0];

  elementToInjectScript.appendChild(js);
}

const Turnstile = forwardRef((props, ref) => {
  const {
    scriptOptions,
    options,
    siteKey,
    onSuccess,
    onExpire,
    onError,
    id,
    autoResetOnExpire = true,
    ...divProps
  } = props;
  const config = options || {};

  const [widgetId, setWidgetId] = useState();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const containerRef = useRef(null);
  const firstRendered = useRef(false);

  const containerId = id || DEFAULT_CONTAINER_ID;
  const onLoadCallbackName = scriptOptions && scriptOptions.onLoadCallbackName ? scriptOptions.onLoadCallbackName : DEFAULT_ONLOAD_NAME;
  const scriptOptionsJson = JSON.stringify(scriptOptions);
  const configJson = JSON.stringify(config);

  useImperativeHandle(
    ref,
    () => {
      if (typeof window === 'undefined') return;

      const { turnstile } = window;
      return {
        getResponse() {
          if (!window.turnstile || !window.turnstile.getResponse || !widgetId) {
            console.warn('Turnstile has not been loaded');
            return;
          }

          return turnstile.getResponse(widgetId);
        },
        reset() {
          if (!window.turnstile || !window.turnstile.reset || !widgetId) {
            console.warn('Turnstile has not been loaded');
            return;
          }

          return turnstile.reset(widgetId);
        },
        remove() {
          if (!window.turnstile || !window.turnstile.remove || !widgetId) {
            console.warn('Turnstile has not been loaded');
            return;
          }

          window.turnstile.remove(widgetId);
          setWidgetId('');
        },
        render() {
          if (!window.turnstile || !window.turnstile.render) {
            console.warn('Turnstile has not been loaded');
            return;
          }

          if (!containerRef.current) {
            console.warn('Container has not rendered');
            return;
          }

          if (widgetId) {
            console.warn('Widget already rendered');
            return widgetId;
          }

          const id = window.turnstile.render(containerRef.current, renderConfig);
          setWidgetId(id);
          return id;
        }
      }
    },
    [scriptLoaded, typeof window, widgetId]
  );

  const renderConfig = {
    action: config.action,
    cData: config.cData,
    theme: config.theme || 'auto',
    sitekey: siteKey,
    tabindex: config.tabIndex,
    callback: onSuccess,
    'expired-callback': onExpire,
    'error-callback': onError,
    size: config.size || 'normal',
    'response-field': config.responseField,
    'response-field-name': config.responseFieldName,
    retry: config.retry || 'auto',
    'retry-interval': config.retryInterval || 8000
  };

  const onLoadScript = () => {
    setScriptLoaded(true)
  };

  const onLoadScriptError = () => {
    console.error('Error loading turnstile script')
  };

  /** define onload function and inject turnstile script */
  useEffect(() => {
    if (!siteKey) {
      console.warn('sitekey was not provided');
      return;
    }

    // define onLoad function
    window[onLoadCallbackName] = () => {
      if (!firstRendered.current) {
        const id = window.turnstile.render(containerRef.current, renderConfig);
        setWidgetId(id);
        firstRendered.current = true;
      }
    }

    injectTurnstileScript({
      render: 'explicit',
      onLoadCallbackName,
      scriptOptions,
      onLoad: onLoadScript,
      onError: onLoadScriptError
    })

    if (autoResetOnExpire) {
      // expire time it's documented as 300 seconds but can happen in around 290 seconds.
      const timerId = setInterval(() => window.turnstile.reset(), 290 * 1000);
      return () => clearInterval(timerId);
    }
  }, [configJson, scriptOptionsJson])

  useEffect(
    function rerenderWidget() {
      if (containerRef.current && window.turnstile) {
        const { turnstile } = window;
        turnstile.remove(widgetId);
        const id = turnstile.render(containerRef.current, renderConfig);
        setWidgetId(id);
        firstRendered.current = true;
      }
    },
    [configJson, siteKey]
  )

  return <div ref={containerRef} id={containerId} {...divProps} />
})

Turnstile.propTypes = {
  scriptOptions: PropTypes.object,
  options: PropTypes.object,
  siteKey: PropTypes.string,
  onSuccess: PropTypes.func,
  onExpire: PropTypes.func,
  onError: PropTypes.func,
  id: PropTypes.string,
  autoResetOnExpire: PropTypes.bool,
};

Turnstile.displayName = 'Turnstile';

export default Turnstile;
