import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@material-ui/core';

import GeoTargeting from './GeoTargeting';
import UploadGeo from './UploadGeo';

const AdGroupGeo = ({
  formatGeo,
  formatGeoName,
  geo,
  geoFilters,
  geoUrl,
  includeUS,
  setIncludeUS,
  setGeo,
  setGeoFilters,
  overlayShown,
  showOverlay
}) => {
  return (
    <Box
      style={{ backgroundColor: overlayShown ? '#262f3c' : '' }}
      mt={2}
      width="100%"
    >
      <Container disableGutters maxWidth={false}>
        <GeoTargeting
          overlayShown={overlayShown}
          showOverlay={showOverlay}
          isEditing
          formatGeo={formatGeo}
          formatGeoName={formatGeoName}
          geoResults={geo}
          geoFilters={geoFilters}
          geoUrl={geoUrl}
          targetEntireUS={includeUS}
          setGeoResults={setGeo}
          setGeoFilters={setGeoFilters}
          setTargetEntireUS={setIncludeUS}
        />
      </Container>
        {overlayShown && (
          <UploadGeo
            // className={classes.uploadGeo}
            style={{
              position: 'absolute',
              background: 'rgba(38, 47, 60, 0.97)',
              padding: '30px 60px',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1,
            }}
            showOverlay={showOverlay}
            setGeoResults={setGeo}
            targetEntireUS={includeUS}
            setTargetEntireUS={setIncludeUS}
          />
        )}
    </Box>
  );
};

AdGroupGeo.propTypes = {
  formatGeo: PropTypes.func,
  formatGeoName: PropTypes.func,
  geo: PropTypes.array,
  geoFilters: PropTypes.object,
  geoUrl: PropTypes.func,
  includeUS: PropTypes.bool,
  setIncludeUS: PropTypes.func,
  setGeo: PropTypes.func,
  setGeoFilters: PropTypes.func,
  overlayShown: PropTypes.bool,
  showOverlay: PropTypes.func
};

export default AdGroupGeo;
