import React, { Fragment, useContext, useState, useEffect } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useOktaAuth } from '@okta/okta-react';
import { Link, useHistory } from 'react-router-dom';
import {
  TextField,
  Grid,
  Box,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  makeStyles,
  Checkbox,
  Typography,
} from '@material-ui/core';

import AdvertiserContext from './AdvertiserContext';
import AsyncButton from './AsyncButton';
import { useAPI } from './hooks/api';
import { useLoader } from './hooks/loader';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginRight: theme.spacing(1),
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    boxShadow: "none",
    "& .tvs-MuiButton-root": {
      border: "none",
    },
  },
  activeButton: {
    fontSize: 18,
    backgroundColor: "#c0e7d8",
    color: "#3f4951",
    fontWeight: "normal",
    boxShadow: "none !important",
    paddingTop: 13,
    paddingBottom: 13,
    "&:hover": {
      backgroundColor: "#0fbf84",
      cursor: "unset",
    },
    "& .tvs-MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    }
  },
  inactiveButton: {
    fontSize: 18,
    backgroundColor: "#ffffff",
    color: "#3f4951",
    fontWeight: "normal",
    paddingTop: 13,
    paddingBottom: 13,
    "&:hover": {
      backgroundColor: "#0fbf84",
    }
  },
  formButton: {
    marginTop: 10,
  },
  header: {
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(2),
  },
  signInLink: {
    textAlign: "center",
    width: "100%",
    color: "#9aa0a6",
    marginTop: 20,
  },
  passwordRequirements: {
    width: "100%",
    color: "#9aa0a6",
    marginTop: 20,
  },
  input: {
    marginTop: theme.spacing(2),
  },
  warningMessage: {
    marginTop: 4,
  },
  terms: {
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  activateAccount: {
    backgroundColor: '#0fbf84'
  },
}));

const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,}$/;

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Email address is invalid').required('Email is required'),
  password: yup.string().required('Password is required').matches(passwordRegExp,
    'Password is not valid'
  ),
  agreeToThings: yup.boolean().isTrue('In order to user the tvScientific Platform you must agree to the Terms and Conditions')
});

export default function AccountModal(props) {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const { usePost } = useAPI();
  const { isLoading, setIsLoading } = useLoader();

  // Set State
  const [email, setEmail] = useState('');
  const [agreeToThings, setAgreeToThings] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [userUrlAndToken, setUserUrlAndToken] = useState();

  props.handleNavSkipSaveStatus('');

  // Pre-populate inputs with data from query params
  useEffect(() => {
    if (props.account != null) {
      const {
        first_name,
        last_name,
        email,
        advertiser_name,
        company,
        advertiser_url,
        advertiser_sf_id,
        agency_sf_id,
      } = props.account;

      if (first_name) {
        setFirstName(first_name);
      }

      if (last_name) {
        setLastName(last_name);
      }

      if (email) {
        setEmail(email);
      }

      if (advertiser_name) {
        adContext.updateAdvertiser({ name: advertiser_name });
      }

      if (company) {
        setCompanyName(company);

        adContext.updateAdvertiser({ company });

        if (props.isAdvertiser) {
          props.setCompanyName(company);
        }
      }

      if (advertiser_url) {
        adContext.updateAdvertiser({ domain: advertiser_url });
      }

      if (advertiser_sf_id) {
        adContext.updateAdvertiser({ advertiser_sf_id });
      }

      if (agency_sf_id) {
        adContext.updateAdvertiser({ agency_sf_id });
      }
    }
  }, [props.account]);

  useEffect(() => {
    console.log("run use effect")
    console.log(userUrlAndToken)

    if (
      userUrlAndToken !== undefined &&
      userUrlAndToken.userUrl !== undefined &&
      userUrlAndToken.token !== undefined
    ) {
      props.setStage('CampaignPreLoad')
    }
  }, [userUrlAndToken])

  const handleCompanyName = event => {
    const { value } = event.target;

    setCompanyName(value);

    adContext.updateAdvertiser({ name: value });

    if (props.isAdvertiser) {
      props.setCompanyName(value);
    }
  };

  // TODO: createAccount() needs refactoring
  // needs sanity checks and updated to es6
  // Create Account Post
  const createAccount = async () => {
    setIsLoading(true);
    setWarnings([]);

    try {
      await schema.validate({
        firstName,
        lastName,
        email,
        password,
        agreeToThings
      }, { abortEarly: false })

      console.log('WARNING:', warnings);
      // TODO: Company Name should be sent along in this call. Not sure where it should be stored on the BE.
      const dataObj = {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        user_type: `${process.env.API_URL}/v1/user_types/1/`,
      };

      // Need to add to local storage because of MFA redirects
      const userType = props.isAdvertiser ? 'advertiser' : 'agency';

      localStorage.setItem('userType', userType);
      localStorage.setItem('company', companyName);

      await adContext.updateAdvertiser({
        name: companyName,
        userType,
      });

      if (!companyName || companyName === '') {
        setWarnings(prev => ([...prev, 'Company Name is required']))
        setIsLoading(false);
        return;
      }

      return usePost('/users', dataObj)
        .then(function (response) {
          // handle success

          // Store owner in advertiser context
          if (response && response.data && response.data.url) {
            adContext.updateAdvertiser({ owner: response.data.url });
          }

          if (response.statusText === 'Created') {
            return oktaAuth.signInWithCredentials({
              username: email,
              password,
            })
              .then(async function (transaction) {
                // Handles redirect to login, since user hasn't verified MFA
                if (transaction.status === 'MFA_REQUIRED') {
                  return history.push('/advertiser-setup', [
                    companyName,
                    userType,
                  ]);
                }

                if (transaction.status === 'SUCCESS') {
                  // get token
                  await oktaAuth.token.getWithoutPrompt({
                    responseType: ['token', 'id_token'], // or array of types
                    sessionToken: transaction.sessionToken // optional if the user has an existing Okta session
                  })
                    .then(async function (res) {
                      // Set token
                      const tokens = res.tokens;

                      await oktaAuth.tokenManager.setTokens(tokens);

                      setUserUrlAndToken({
                        userUrl: response.data.url,
                        token: tokens.accessToken.value
                      });

                      setIsLoading(false);

                      // Resolve to successful token
                      return Promise.resolve(res);
                    })
                    .catch(function (err) {
                      console.log(err)
                      return err;
                      // handle OAuthError or AuthSdkError (AuthSdkError will be thrown if app is in OAuthCallback state)
                    });
                }

                return transaction;
              })
              .catch(function (err) {
                console.error('Error in Creating Okta User', err);
                setIsLoading(false);
                return err;
              });
          }

          // if creating a user was not successful
          // reject the response
          return Promise.reject(response);
        })
        .catch(function (err) {
          // handle error

          setIsLoading(false);
          // TODO: Clean up responses from OKTA
          if (err.response) {
            // client received an error response (5xx, 4xx)
            console.warn('Error response:', err.response);

            if (err.response.data) {
              if (err.response.data.email) {
                setWarnings(prev => ([...prev, err.response.data.email]));
                return false;
              }

              if (err.response.data.error) {
                if (err.response.data.error.indexOf('password') > -1) {
                  setWarnings(prev =>
                    ([...prev, 'Pick a stronger password']));

                  return false;
                }

                setWarnings(prev => ([...prev, err.response.data.error]));
                return false
              }
            }
          } else if (err.request) {
            // client never received a response, or request never left
            console.log(err.request);
          } else {
            // anything else
            console.log(err)
          }

          return Promise.reject(err);
        });
    } catch (err) {
      setWarnings(err.errors)
      setIsLoading(false);
      return false;
    }
  }

  console.log(
    {
      "Company Name": props.companyName,
      "isAdvertiser": props.isAdvertiser,
      email,
      agreeToThings,
      firstName,
      lastName,
      password,
    }
  );

  return (
    <Fragment>
      <Grid container direction="column" justify="flex-start">
        <Box>
          <Grid container spacing={2}>
            <Grid item xs>
              <ButtonGroup
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                className={classes.buttonGroup}
              >
                <Button
                  className={
                    props.isAdvertiser
                      ? classes.activeButton
                      : classes.inactiveButton
                  }
                  color="secondary"
                  size="large"
                  fullWidth
                  onClick={() => props.setIsAdvertiser(true)}
                >
                  {props.isAdvertiser ? <CheckIcon color="secondary" /> : ''}
                  I&#39;m an Advertiser
                </Button>

                <Button
                  className={
                    props.isAdvertiser
                      ? classes.inactiveButton
                      : classes.activeButton
                  }
                  color="secondary"
                  size="large"
                  fullWidth
                  onClick={() => props.setIsAdvertiser(false)}
                >
                  {props.isAdvertiser ? '' : <CheckIcon color="secondary" />}
                  I&#39;m an Agency
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.input}>
            <Grid item xs>
              <TextField
                fullWidth
                autoFocus
                id="firstName"
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={event => setFirstName(event.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                id="lastName"
                label="Last Name"
                variant="outlined"
                value={lastName}
                onChange={event => setLastName(event.target.value)}
              />
            </Grid>
          </Grid>

          <TextField
            fullWidth
            className={classes.input}
            id="companyName"
            label="Company Name"
            variant="outlined"
            value={companyName}
            onChange={handleCompanyName}
          />

          <TextField
            fullWidth
            className={classes.input}
            id="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={() => setEmail(event.target.value)}
          />

          <FormControl variant="outlined" fullWidth className={classes.input}>
            <InputLabel
              htmlFor="outlined-adornment-password">
              Password
            </InputLabel>

            <OutlinedInput
              id="outlined-adornment-password"
              type={isPasswordVisible ? 'text' : 'password'}
              value={password}
              onChange={() => setPassword(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    onMouseDown={() =>
                      setIsPasswordVisible(!isPasswordVisible)
                    }
                    edge="end"
                  >
                    {isPasswordVisible ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={75}
            />

            <Box className={classes.passwordRequirements}>
              *Password must contain 8 characters minimum, 1 uppercase letter,
              1 lowercase letter and 1 number.
            </Box>
          </FormControl>

          <Box key="agreeToThings" className={classes.input}>
            <label className={classes.terms} htmlFor="terms-conditions-input">
              <Checkbox
                color="secondary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                checked={agreeToThings}
                onChange={() => setAgreeToThings(!agreeToThings)}
                id="terms-conditions-input"
                name="terms-conditions-input"
              />

              <span>I have read and accept the&nbsp;</span>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.tvscientific.com/platform-master-agreement-demand/"
              >
                Terms &amp; Conditions
              </a>
            </label>
          </Box>

          <Grid container spacing={2} className={classes.warningMessage}>
            <Grid item xs={12}>
              {warnings.map(message => (
                <Typography variant="subtitle2" color="error" key={message}>
                  {message}
                </Typography>
              ))}
            </Grid>
          </Grid>

          <Grid container item xs={12} className={classes.formButton}>
            <AsyncButton
              classes={clsx('Button--medium', classes.activateAccount)}
              color="secondary"
              isLoading={isLoading}
              fullWidth
              onClick={() => {
                createAccount()
                  .then(res => {
                    if (res && res.status && res.status === 'SUCCESS') {
                      history.push('/advertiser-setup');
                    }
                  })
              }}
              size="medium"
              textButton="Activate Account"
              variant="contained"
            />

            <Box className={classes.signInLink}>
              Have an account? <Link to="/home">Sign In</Link>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Fragment>
  );
}

AccountModal.propTypes = {
  account: PropTypes.object,
  setAdvertiserUrl: PropTypes.func,
  setStage: PropTypes.func,
  setCompanyName: PropTypes.func,
  companyName: PropTypes.string,
  setIsAdvertiser: PropTypes.func,
  isAdvertiser: PropTypes.bool,
  theme: PropTypes.string,
  handleNavSkipSaveStatus: PropTypes.func
};
