import React, { Fragment, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { accountCategories } from './accountCategories';
import { useAdvertisers } from './hooks/advertisers';
import AdvertiserContext from './AdvertiserContext';
import AsyncButton from './AsyncButton';

const useStyles = makeStyles((theme) => ({
  formButton: {
    marginTop: 115,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formInput: {
    marginTop: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const categories = accountCategories.map((category, index) => (
  <MenuItem key={index} value={category.url}>{category.name}</MenuItem>
));

const errorMessages = {
  name: 'This advertiser already exists. Please contact your account owner or change your advertiser name.',
  domain: 'Please enter a valid website.',
  path: 'Websites cannot contain an additional path (ex: https://website.com/path)',
  secure: 'We currently only support secure domains. Please enter a valid https:// domain.',
  sf: 'Please enter Salesforce IDs',
}

const CreateNewAdvertiser = (props) => {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);
  const history = useHistory();

  const {
    formatAdvertiserUrl,
    validateAdvertiserUrl,
    verifyRootDomain,
  } = useAdvertisers();

  const initialAdContextName = () => adContext && adContext.name
    ? adContext.name
    : '';

  const [isLoading, setIsLoading] = useState(false);
  const [newName, setNewName] = useState('');
  const [adContextName, setAdContextName] = useState(initialAdContextName());
  const [website, setWebsite] = useState('');
  const [category, setCategory] = useState('');
  const [sfAdvertiserId, setSfAdvertiserId] = useState('');
  const [sfAgencyId, setSfAgencyId] = useState('');
  const [skip, setSkip] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [cachedValues, setCachedValues] = useState({
    adContextName,
    newName,
    website,
    category,
  });

  const handleCachedValues = (value, prop) => {
    setCachedValues(prev => ({
      ...prev,
      [prop]: value,
    }));
  };

  const handleAdContextName = name => {
    setAdContextName(name);
    handleCachedValues(name, 'adContextName');
    adContext.updateAdvertiser({ name });
  };

  const handleNewName = name => {
    setNewName(name);
    handleCachedValues(name, 'newName');
  };

  const handleWebsite = value => {
    setWebsite(value);
    handleCachedValues(value, 'website');

    if (props.setAdvertiserWebsite) {
      props.setAdvertiserWebsite(value);
    }
  };

  const handleCategory = value => {
    setCategory(value);
    handleCachedValues(value, 'category');

    if (props.setCompanyCategory) {
      props.setCompanyCategory(value);
    }
  };

  const handleSkip = checked => {
    setSkip(prev => !prev);

    if (checked) {
      setAdContextName('');
      setWebsite('');
      setCategory('');
    }

    if (!checked) {
      setAdContextName(cachedValues.adContextName);
      setWebsite(cachedValues.website);
      setCategory(cachedValues.category);
    }
  };

  const handleAdError = error => {
    if (error && error.response && error.response.data) {
      const errorData = error.response.data;

      if (errorData.name) {
        setErrorMessage(errorMessages.name);
        return error;
      }

      if (errorData.domain) {
        setErrorMessage(errorMessages.domain);
        return error;
      }
    }
  };

  // TODO: Needs refactor
  const handleSubmit = () => {
    setIsLoading(true);
    setErrorMessage(null);

    if (skip) {
      adContext.updateAdvertiser({ name: '' });
      return history.push('/home');
    }

    if (!verifyRootDomain(website)) {
      setIsLoading(false);
      setErrorMessage(errorMessages.path);
      return;
    }

    // TODO: Could use Yup npm package
    if (!validateAdvertiserUrl(website)) {
      setIsLoading(false);
      setErrorMessage(errorMessages.secure);
      return;
    }

    if (props.showSalesforce && (!sfAdvertiserId || !sfAgencyId)) {
      setIsLoading(false);
      setErrorMessage(errorMessages.sf);
      return;
    }

    if (!props.notFirst) {
      const firstAdvertiserData = {
        name: adContextName,
        domain: formatAdvertiserUrl(website),
        category,
        is_nbcu_tenant: adContext.is_nbcu_tenant,
      };

      return props.handleData(firstAdvertiserData)
        .then(response => {
          setIsLoading(false);

          if (
            response &&
            response.response &&
            [200, 201].indexOf(response.response.status) < 0
          ) {
            handleAdError(response);
            return Promise.reject(response);
          }

          props.setStep('StartCampaign');

          return response;
        })
        .catch(error => {
          setIsLoading(false);
          console.error('Error in create first advertiser:', error);
          console.error('Error response:', error.response);
          handleAdError(error);
        });
    }

    const dataObj = {
      name: newName,
      domain: formatAdvertiserUrl(website),
      category,
      is_nbcu_tenant: adContext.is_nbcu_tenant,
    };

    if (props.showSalesforce) {
      dataObj.advertiser_external_crm_id = sfAdvertiserId;
      dataObj.agency_external_crm_id = sfAgencyId;
    }

    // TODO: use createAdvertiser hook to do api calls
    // for both first and subsequent advertisers
    return props.createAdvertiser(dataObj)
      .then(response => {
        setIsLoading(false);

        if (
          response &&
          response.response &&
          [200, 201].indexOf(response.response.status) < 0
        ) {
          handleAdError(response);
          return Promise.reject(response);
        }
        props.onCancel();
        return history.push('/home');
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error in create new advertiser:', error);
        handleAdError(error);
      });
  };

  return (
    <Fragment>
      <Grid
        container
        direction="column"
        justify={props.notFirst ? 'space-between' : 'flex-start'}
        item
        spacing={2}
      >
        <Grid container item spacing={2}>
          {!props.notFirst && <Grid item xs={12} />}

          <Grid item xs={12}>
            <Typography
              className={classes.header}
              variant="h3"
              component="h3"
              paragraph
            >
              {props.notFirst
                ? 'Create a new advertiser'
                : 'Complete your advertiser profile'}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {props.notFirst &&
              <TextField
                fullWidth
                autoFocus
                disabled={skip}
                variant="outlined"
                label="Advertiser Name"
                value={newName}
                onChange={event => handleNewName(event.target.value)}
                InputLabelProps={{ shrink: true }}
                margin="dense"
              />
            }

            {!props.notFirst &&
              <TextField
                fullWidth
                autoFocus
                disabled={skip}
                variant="outlined"
                label="Advertiser Name"
                value={adContextName}
                onChange={event => handleAdContextName(event.target.value)}
                InputLabelProps={{ shrink: true }}
                margin="dense"
              />
            }
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={skip}
              variant="outlined"
              label="Advertiser Website"
              value={website}
              onChange={event => handleWebsite(event.target.value)}
              InputLabelProps={{ shrink: true }}
              margin="dense"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                disabled={skip}
                id="company-catagory"
                value={category}
                onChange={(event) => handleCategory(event.target.value)}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label="Select Advertiser Category"
                select
                margin="dense"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {categories}
              </TextField>
            </FormControl>
          </Grid>

          {props.showSalesforce &&
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Salesforce Advertiser ID"
                value={sfAdvertiserId}
                onChange={event => setSfAdvertiserId(event.target.value)}
                InputLabelProps={{ shrink: true }}
                margin="dense"
              />
            </Grid>}

          {props.showSalesforce &&
            <Grid item xs={12}>
              <Box mb={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Salesforce Agency ID"
                  value={sfAgencyId}
                  onChange={event => setSfAgencyId(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  margin="dense"
                />
              </Box>
            </Grid>}

          {!props.notFirst &&
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={skip}
                    onChange={event => handleSkip(event.target.checked)}
                    size="small"
                  />
                }
                label={
                  <Typography variant="body2">
                    Skip for now and add later
                  </Typography>
                }
              />
            </Grid>}

          {errorMessage &&
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="error">
                {errorMessage}
              </Typography>
            </Grid>}
        </Grid>

        <Grid
          container
          item
          alignItems="center"
          justify="flex-end"
          spacing={4}
        >
          {props.notFirst &&
            <Grid item>
              <Button
                disabled={isLoading}
                color="secondary"
                size="medium"
                onClick={() => {
                  props.onCancel();
                }}
              >
                  Cancel
              </Button>
            </Grid>
          }

          <Grid item>
            <AsyncButton
              className="Button--medium"
              color="secondary"
              size="medium"
              textButton={props.notFirst ? 'Submit' : 'Next'}
              loadingButton="Submitting..."
              variant="contained"
              isLoading={isLoading}
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

CreateNewAdvertiser.propTypes = {
  advertiserName: PropTypes.string,
  setAdvertiserName: PropTypes.func,
  createAdvertiser: PropTypes.func,
  handleData: PropTypes.func,
  setStep: PropTypes.func,
  setCompanyCategory: PropTypes.func,
  companyCategory: PropTypes.string,
  advertiserWebsite: PropTypes.string,
  setAdvertiserWebsite: PropTypes.func,
  showSalesforce: PropTypes.bool,
  notFirst: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default CreateNewAdvertiser;
