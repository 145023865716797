import { Statuses } from './statuses';

export const getStatus = (active, draft) => {
  if (draft) {
    return Statuses.DRAFT;
  }

  if (active === true) {
    return Statuses.ACTIVE;
  }

  return Statuses.PAUSED;
}
