import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useLoader } from './hooks/loader';

const useStyles = makeStyles(({ spacing }) => ({
  bar: {
    borderRadius: 5,
    height: 10,
  },
  meta: {
    marginTop: spacing(1),
  },
  name: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const FileProgress = ({
  duration,
  index,
  fileId,
  fileSize,
  formatFileName,
  handleRemoveFile,
  field,
  name,
  progressBars,
  resolution,
  control,
}) => {
  const classes = useStyles();
  const { isLoading } = useLoader();

  const renderMegaBytes = bytes => {
    return `${(parseInt(bytes, 10) / 1000000).toFixed(2)} MB`;
  };

  return (
    <Box my={4}>
      <Grid container alignItems="flex-start" justify="space-between" spacing={4}>
        <Grid container item>
          <Grid
            container
            item
            alignItems="center"
            justify="space-between"
            xs={12}
          >
            <Grid item xs={5}>
              <p className={clsx(classes.meta, classes.name)}>
                <strong>{name}</strong>
              </p>
            </Grid>

            <Grid item xs={2}>
              <p className={classes.meta}>{renderMegaBytes(fileSize)}</p>
            </Grid>

            <Grid item xs={1}>
              {duration ? (
                <p className={classes.meta}>
                  {duration !== 'Calculating...' ? (
                    `${duration}${`${duration}`.slice(-1) === 's' ? '' : 's'}`
                  ) : duration}
                </p>
              ) : (
                <p className={classes.meta}>
                  n/a
                </p>
              )}
            </Grid>

            <Grid item xs={2}>
              <p className={classes.meta}>{resolution}</p>
            </Grid>

            <Grid item xs={1}>
              {fileId ? (
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => handleRemoveFile(fileId)}
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : (
                <IconButton
                  disabled
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <LinearProgress
              className={classes.bar}
              variant="determinate"
              value={progressBars[formatFileName(name)] || 100}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={5}>
            <Controller
              control={control}
              name={`${field}.${index}.name`}
              defaultValue=""
              render={({ field: { value, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  color="secondary"
                  label="File Title"
                  variant="outlined"
                  fullWidth
                  value={value || ''}
                  error={error !== undefined}
                />
              )}
            />
          </Grid>


          <Grid item xs={3}>
            <Controller
              control={control}
              name={`${field}.${index}.weighting`}
              rules={{
                max: {
                  value: 100,
                  message: 'The field value must not exceed 100',
                },
                min: {
                  value: 0,
                  message: 'The field value must not be less than 0',
                },
                pattern: {
                  value: /^\d+$/,
                  message: 'The field must contain only numbers',
                },
              }}
              defaultValue=""
              render={({ field: { value, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  color="secondary"
                  label="Weighting"
                  variant="outlined"
                  fullWidth
                  error={error !== undefined}
                  disabled={isLoading}
                  value={value || ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    inputProps: { min: 0 }
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              control={control}
              name={`${field}.${index}.click_url`}
              defaultValue=""
              rules={{
                pattern: {
                  value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/i,
                  message: 'The Click URL is not configured correctly'
                }
              }}
              render={({ field: { value, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  color="secondary"
                  label="Click URL (optional)"
                  variant="outlined"
                  fullWidth
                  error={error !== undefined}
                  value={value || ''}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

FileProgress.propTypes = {
  duration: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  fileId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  fileSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  index: PropTypes.number,
  formatFileName: PropTypes.func,
  handleRemoveFile: PropTypes.func,
  metadata: PropTypes.object,
  name: PropTypes.string,
  field: PropTypes.string,
  progressBars: PropTypes.object,
  resolution: PropTypes.string,
  control: PropTypes.object.isRequired,
};

export default FileProgress;
