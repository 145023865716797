const GEO_TARGETING = 'GeoTargeting';
const DEMO_TARGETING = 'DemoTargeting';
const TARGETING_SEGMENTS = 'TargetingSegments';
const INVENTORY = 'Inventory';
const BUNDLES = 'Bundles';
const GENRES = 'Genres';
const PRIVATE = 'Private';
const NAME_AD_GROUP = 'NameAdGroup';
const CREATIVE_UPLOAD = 'CreativeUpload';
const DISPLAY_UPLOAD = 'DisplayUpload';
const TRACKING_SETUP = 'TrackingSetup';
const INSTALL_TRACKING_CODE = 'InstallTrackingCode';
const TRACKING_EVENTS = 'TrackingEvents';
const CONFIGURE_TRACKING_EVENTS = 'ConfigureTrackingEvents';
const LAUNCH_CAMPAIGN = 'LaunchCampaign';
const POST_LAUNCH = 'PostLaunch';
const NAME_YOUR_CAMPAIGN = 'NameYourCampaign';

export const SetupWizardSteps = {
  GEO_TARGETING,
  DEMO_TARGETING,
  TARGETING_SEGMENTS,
  INVENTORY,
  BUNDLES,
  GENRES,
  PRIVATE,
  NAME_AD_GROUP,
  CREATIVE_UPLOAD,
  DISPLAY_UPLOAD,
  TRACKING_SETUP,
  INSTALL_TRACKING_CODE,
  TRACKING_EVENTS,
  CONFIGURE_TRACKING_EVENTS,
  LAUNCH_CAMPAIGN,
  POST_LAUNCH,
  NAME_YOUR_CAMPAIGN,
};

export const SetupWizardStepList = [
  GEO_TARGETING,
  DEMO_TARGETING,
  TARGETING_SEGMENTS,
  INVENTORY,
  BUNDLES,
  GENRES,
  PRIVATE,
  NAME_AD_GROUP,
  CREATIVE_UPLOAD,
  DISPLAY_UPLOAD,
  TRACKING_SETUP,
  INSTALL_TRACKING_CODE,
  TRACKING_EVENTS,
  CONFIGURE_TRACKING_EVENTS,
  LAUNCH_CAMPAIGN,
  POST_LAUNCH,
  NAME_YOUR_CAMPAIGN,
];
