/* eslint-disable no-debugger */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  withStyles,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useSnackbar } from 'notistack';

import AdvertiserContext from './AdvertiserContext';
import { getSuggestions } from './GeoAutoSuggest';
import AsyncButton from './AsyncButton';
import Title from './Title';
import { useAPI } from './hooks/api';
import { useGeo } from './hooks/geo';
import { useCopy } from './hooks';
import { useLoader } from './hooks/loader';
import { dmaData } from './util';
import { Themes } from '../constants';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    height: '100%',
  },
  backBtn: {
    color: `rgba(0, 0, 0, 0.1)`,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#e0e0e0',
  },
  filter: {
    cursor: 'pointer',
  },
  filterIconWrap: {
    width: 16,
    ['& img']: {
      width: '100%',
    },
  },
  filterImg: {
    transition: 'all',
  },
  filterLabel: {
    marginLeft: theme.spacing(1),
  },
  locations: {
    backgroundColor: `#e5e7eb`,
    padding: `0 1.125rem`,
    width: `100%`,
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: `1.625rem`,
  },
  result: {
    marginBottom: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  switch: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },

  background: {
    backgroundColor: '#262f3c',
    height: '100%',
  },
  title: {
    color: theme.palette.text.overlay,
  },
  input: {
    color: theme.palette.text.overlay,
  },
  saveBtn: {
    paddingLeft: 50,
    paddingRight: 50,
    fontSize: '1.05rem',

    '&.tvs-Mui-disabled': {
      color: '#979BA1',
      background: '#2672A1',
    },
  },
  cancelBtn: {
    color: '#1dafff'
  },
  '.tvs-Mui-disabled': {
    backgroundColor: 'red'
  },
}));

const StyledTextField = withStyles(theme => ({
  root: {
    marginTop: 30,
    '& label.tvs-Mui-focused': {
      color: theme.palette.text.overlay,
    },
    '& label': {
      color: theme.palette.text.overlay,
    },
    '& .tvs-MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.border.overlay,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.border.overlay,
      },
      '&.tvs-Mui-focused fieldset': {
        borderColor: theme.palette.border.overlay,
      },
      '& label.tvs-Mui-focused': {
        color: theme.palette.text.overlay,
      },
    },
  },
}))(TextField);

const Copies = {
  [Themes.DEFAULT]: {
    HEAD: 'Upload Geo-targeting'
  },
  [Themes.NBCU]: {
    HEAD: 'Upload Geotargeting'
  },
};

const UploadGeo = props => {
  const {
    setGeoResults,
    targetEntireUS,
    setTargetEntireUS,
    showOverlay,
    ...rest
  } = props;

  const adContext = useContext(AdvertiserContext);
  const classes = useStyles({
    theme: adContext.theme
  });
  const theme = useTheme();
  const { geoUrl } = useGeo();
  const { useGet } = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoader();
  const Copy = useCopy(Copies);

  const [bulkZipString, setBulkZipString] = useState('');
  const [bulkCityString, setBulkCityString] = useState('');
  const [bulkStateString, setBulkStateString] = useState('');
  const [bulkDMAString, setBulkDMAString] = useState('');
  const [searchType, setSearchType] = useState('zip');
  const [label, setLabel] = useState('Zip Codes');
  const [include, setInclude] = useState(true);

  const hasData = bulkZipString || bulkCityString || bulkStateString || bulkDMAString;

  const handleAlignment = (event, val) => {
    setLabel(event.target.innerText);
    setSearchType(val);
  };

  const handleInclude = () => {
    setInclude(current => !current);
  };

  const handleSubmit = suggestions => {
    setGeoResults(prev => {
      const tempData = [...suggestions, ...prev];
      const dmas = _.chain(tempData).filter('code').uniqBy('code');
      const others = _.chain(tempData).filter('id').uniqBy('id');
      const results = [...dmas, ...others];

      return [...results];
    });

    return;
  };

  const getZipCodes = async () => {
    const promises = [];
    let zipcodes = bulkZipString
      .replace(/\n/g, ',')
      .split(',')
      .map(el => el.trim());
    let failedZips = [];

    // make sure zipcodes are valid
    zipcodes = _.map(zipcodes, code => {
      if (`${code}`.length === 5) {
        return code;
      }
      failedZips.push(code);
    });

    try {
      _.map(zipcodes, code => {
        if (!isNaN(code)) {
          const url = geoUrl(code);
          promises.push(useGet(url, true));
        }
      });
      const responses = await Promise.all(promises);
      let features = [];
      _.map(responses, response => {
        if (response && response.features.length === 0) {
          failedZips.push(response.query);
          failedZips = _.flatten(failedZips);
        }
        if (response && response.features) {
          features.push(response.features);
        }
      });

      // Display error if not found
      if (failedZips.length > 0) {
        enqueueSnackbar(`${failedZips.join(',')} zipcodes were not found.`, {
          variant: 'warning',
        });
      }

      features = _.map(features, i => {
        return _.filter(i, x => x !== undefined);
      });

      features = _.filter(features, x => x.length !== 0);

      features = _.flatten(features);

      features = _.forEach(features, feature => {
        if (!include) {
          feature.blacklist = true;
        }
      });
      return features;
    } catch (error) {
      console.error(error);
    }
  };

  const getCities = async () => {
    let errors = [];

    const promises = [];
    const cities = bulkCityString.replace(/\n/g, ',').split(',');

    try {
      _.map(cities, code => {
        const url = geoUrl(code, cities);
        promises.push(useGet(url, true));
      });
      const responses = await Promise.all(promises);
      let features = [];
      _.map(responses, response => {
        if (response && response.features.length === 0) {
          errors.push(response.query);
          errors = _.flatten(errors);
        }
        if (response && response.features) {
          features.push(response.features);
        }
      });

      // Display error if not found
      if (errors.length > 0) {
        enqueueSnackbar(`${errors.join(',')} cities were not found.`, {
          variant: 'warning',
        });
      }

      features = _.map(features, i => {
        return _.filter(i, x => x !== undefined);
      });

      features = _.filter(features, x => x.length !== 0);
      let results = [];

      _.map(features, feature => {
        results.push(_.head(feature));
      });

      results = _.forEach(results, feature => {
        if (!include) {
          feature.blacklist = true;
        }
      });
      return results;
    } catch (error) {
      console.error(error);
    }
  };

  const getDMAs = async () => {
    const dmas = bulkDMAString.replace(/\n/g, ',').split(',');
    const errors = [];

    try {
      let responses = _.map(dmas, code => {
        return getSuggestions([...dmaData], code);
      });

      _.map(responses, (res, i) => {
        if (res.length === 0) {
          errors.push(dmas[i]);
        }
      });

      // Display error if not found
      if (errors.length > 0) {
        enqueueSnackbar(`${errors.join(',')} DMAs were not found.`, {
          variant: 'warning',
        });
      }

      let results = [];

      responses = _.map(responses, i => {
        return _.filter(i, x => x !== undefined);
      });

      responses = _.filter(responses, x => x.length !== 0);

      _.map(responses, feature => {
        results.push(_.head(feature));
      });

      results = _.forEach(results, feature => {
        if (!include) {
          feature.blacklist = true;
        }
      });
      return results;
    } catch (error) {
      console.error(error);
    }
  };

  const getStates = async () => {
    let errors = [];
    const promises = [];
    const cities = bulkStateString.replace(/\n/g, ',').split(',');

    try {
      _.map(cities, code => {
        const url = geoUrl(code, cities);
        promises.push(useGet(url, true));
      });
      const responses = await Promise.all(promises);
      let features = [];
      _.map(responses, response => {
        if (response && response.features.length === 0) {
          errors.push(response.query);
          errors = _.flatten(errors);
        }
        if (response && response.features) {
          features.push(response.features);
        }
      });
      let results = [];

      features = _.map(features, featureArr => {
        return _.map(featureArr, i => {
          if (i.place_type[0] === 'region') {
            return i;
          }
        });
      });

      // Display error if not found
      if (errors.length > 0) {
        enqueueSnackbar(`${errors.join(',')} states were not found.`, {
          variant: 'warning',
        });
      }

      features = _.map(features, i => {
        return _.filter(i, x => x !== undefined);
      });

      features = _.filter(features, x => x.length !== 0);

      _.map(features, feature => {
        results.push(_.head(feature));
      });

      results = _.forEach(results, feature => {
        if (!include) {
          feature.blacklist = true;
        }
      });
      return results;
    } catch (error) {
      console.error(error);
    }
  };

  const search = async () => {
    setIsLoading(true);
    const promises = [];

    if (bulkZipString) {
      promises.push(getZipCodes());
    }
    if (bulkCityString) {
      promises.push(getCities());
    }

    if (bulkStateString) {
      promises.push(getStates());
    }
    if (bulkDMAString) {
      promises.push(getDMAs());
    }

    const promiseResults = await Promise.all(promises);

    const results = _.flatten(promiseResults);

    handleSubmit(results);

    setBulkDMAString('');
    setBulkZipString('');
    setBulkStateString('');
    setBulkCityString('');

    setIsLoading(false);
    showOverlay(false);

    if (targetEntireUS) {
      setTargetEntireUS(false);
    }
  };

  const StyledFormControlLabel = withStyles(theme => ({
    root: {
      marginLeft: 10,
      color: theme.palette.text.overlay,
    },
  }))(FormControlLabel);

  const geoInput = () => {
    let input = (
      <StyledTextField
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
        color="primary"
        fullWidth
        id="bulkGeo"
        label={label}
        multiline
        placeholder={`Paste ${label} separated by comma`}
        rows={6}
        variant="outlined"
        value={bulkZipString}
        onChange={event => setBulkZipString(event.target.value)}
      />
    );

    switch (searchType) {
      case 'zip':
        input = (
          <StyledTextField
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            color="primary"
            fullWidth
            id="bulkGeo"
            label={label}
            multiline
            placeholder={`Paste ${label} separate by comma or line breaks`}
            rows={6}
            variant="outlined"
            value={bulkZipString}
            onChange={event => setBulkZipString(event.target.value)}
          />
        );
        break;
      case 'city':
        input = (
          <StyledTextField
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            color="primary"
            fullWidth
            id="bulkGeo"
            label={label}
            multiline
            placeholder={`Paste ${label} separated by comma`}
            rows={6}
            variant="outlined"
            value={bulkCityString}
            onChange={event => setBulkCityString(event.target.value)}
          />
        );
        break;
      case 'state':
        input = (
          <StyledTextField
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            color="primary"
            fullWidth
            id="bulkGeo"
            label={label}
            multiline
            placeholder={`Paste ${label} separated by comma`}
            rows={6}
            variant="outlined"
            value={bulkStateString}
            onChange={event => setBulkStateString(event.target.value)}
          />
        );
        break;
      case 'dma':
        input = (
          <StyledTextField
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            color="primary"
            fullWidth
            id="bulkGeo"
            label={label}
            multiline
            placeholder={`Paste ${label} separated by comma`}
            rows={6}
            variant="outlined"
            value={bulkDMAString}
            onChange={event => setBulkDMAString(event.target.value)}
          />
        );
        break;
      default:
        input = (
          <StyledTextField
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            color="primary"
            fullWidth
            id="bulkGeo"
            label={label}
            multiline
            placeholder={`Paste ${label} separated by comma`}
            rows={6}
            variant="outlined"
            value={bulkZipString}
            onChange={event => setBulkZipString(event.target.value)}
          />
        );
        break;
    }

    return input;
  };

  return (
    <Grid {...rest}>
      <Title className={classes.title}>{Copy.HEAD}</Title>
      <Divider className={classes.divider} />

      <ToggleButtonGroup
        value={searchType}
        onChange={handleAlignment}
        exclusive
      >
        <ToggleButton value="zip" >
          Zip Codes
        </ToggleButton>
        <ToggleButton value="city">
          Cities & States
        </ToggleButton>
        <ToggleButton value="dma">
          DMAs
        </ToggleButton>
        <ToggleButton value="state" >
          States
        </ToggleButton>
      </ToggleButtonGroup>

      <span style={{ color: theme.palette.text.overlay, fontSize: '0.75rem', marginLeft: 30 }}>
        Exclude
      </span>
      <StyledFormControlLabel
        className={classes.switch}
        label="Include"
        control={
          <Switch
            checked={include}
            onChange={handleInclude}
            color="secondary"
            name="include"
            size="small"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        }
      />

      {geoInput()}

      <Grid
        container
        direction="row"
        justify="flex-end"
        style={{ marginTop: 35 }}
      >
        <Grid item xs={1}>
          <Button
            onClick={() => showOverlay(false)}
            className={classes.cancelBtn}
            color="secondary"
            variant="text"
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <AsyncButton
            color="secondary"
            isLoading={isLoading}
            fullWidth
            onClick={() => search()}
            textButton="Save"
            variant="contained"
            className={classes.saveBtn}
            disabled={!hasData}
            disableElevation
            size="large"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

UploadGeo.propTypes = {
  showOverlay: PropTypes.func,
  setGeoResults: PropTypes.func,
  targetEntireUS: PropTypes.bool,
  setTargetEntireUS: PropTypes.func,
};

export default UploadGeo;
