import React from 'react';
import PropTypes from 'prop-types';
import {
  useForm,
  useFieldArray,
  Controller,
  FormProvider,
} from 'react-hook-form';
import {
  Box,
  Divider,
  Grid, InputAdornment,
  makeStyles,
  TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ModalWrapper from './ModalWrapper';
import AlertBox from './AlertBox';
import Title from './Title';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    height: 'auto',
  },
  panelHeader: {
    backgroundColor: '#e5e7eb',
  },
  header: {

  },
  close: {
    paddingLeft: 10,
    width: '40px',
  },
  row: {
    paddingTop: '30px',
  },
  relative: {
    position: 'relative',
  },
  label: {
    position: 'absolute',
    top: '-10px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
  },
  divider: {
    margin: '20px 0 20px',
  },
}));

const EditWeighting = ({
  data,
  isOpen,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();

  const formMethods = useForm({
    defaultValues: {
      creatives: data
    },
    mode: 'onBlur'
  });

  const { formState, control, handleSubmit } = formMethods;
  const { isSubmitting, errors } = formState;

  const { fields } = useFieldArray({
    name: "creatives",
    keyName: 'internalId',
    control,
  });

  return (
    <FormProvider {...formMethods}>
      <ModalWrapper
        fullWidth
        maxWidth="lg"
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isSubmitting}
        onSubmit={handleSubmit(onSubmit)}
        isDisabled={formState.isDirty && !formState.isValid}
        submit="Update Weighting"
        hasSubmit
        aria-labelledby="edit-ad-group-dialog"
      >
        <Box className={classes.container}>
          <Grid
            container
            item
            display="flex"
            direction="column"
            justify="space-between"
            px={0}
            pb={22}
          >
            <Grid
              container
              item
              className={classes.header}
              justify="space-between"
            >
              <Grid item xs={11}>
                <Title>
                  Edit Creative Weighting
                </Title>

                <Divider className={classes.divider} />

                {errors.creatives && errors.creatives.length > 0 && (
                  <AlertBox
                    {...alert.activeAlert}
                    isAlertOpen
                    type="MAJOR"
                    message={errors.creatives.filter(weighting => weighting)[0].weighting.message}
                  />
                )}

                {fields.map((field, index) => (
                  <Box key={field.id} flexGrow={1} className={classes.row}>
                    <Grid
                      container
                      item
                      alignItems="center"
                      justify="space-between"
                      xs={12}
                      spacing={3}
                    >
                      <Grid item xs={2}>
                        <Controller
                          control={control}
                          name={`creatives.${index}.weighting`}
                          rules={{
                            required: {
                              value: true,
                              message: 'The field must not be empty',
                            },
                            max: {
                              value: 100,
                              message: 'The field value must not exceed 100',
                            },
                            min: {
                              value: 0,
                              message: 'The field value must not be less than 0',
                            },
                            pattern: {
                              value: /^\d+$/,
                              message: 'The field must contain only numbers',
                            },
                          }}
                          defaultValue=""
                          render={({ field: { value, ...field }, fieldState: { error } }) => (
                            <TextField
                              {...field}
                              color="secondary"
                              label="Weighting"
                              variant="outlined"
                              fullWidth
                              error={error !== undefined}
                              value={value || ''}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">%</InputAdornment>
                                ),
                                inputProps: { min: 0 }
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={4} className={classes.relative}>
                        {index === 0 && <p className={classes.label}>Creative Title</p>}
                        <p>{field.name}</p>
                      </Grid>

                      <Grid item xs={2} className={classes.relative}>
                        {index === 0 && <p className={classes.label}>File Size</p>}
                        <p>{field.file_size_bytes}</p>
                      </Grid>

                      <Grid item xs={2} className={classes.relative}>
                        {index === 0 && <p className={classes.label}>Duration</p>}
                        <p>{field.duration}</p>
                      </Grid>

                      <Grid item xs={2} className={classes.relative}>
                        {index === 0 && <p className={classes.label}>Resolution</p>}
                        <p>{field.resolution}</p>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Grid>

              <Grid item xs={1}>
                <CloseIcon className={classes.close} onClick={onClose}/>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ModalWrapper>
    </FormProvider>
  );
};

EditWeighting.propTypes = {
  data: PropTypes.array,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default EditWeighting;
