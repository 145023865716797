import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Typography,
  CircularProgress,
  Checkbox,
  Chip,
  Box,
  Tabs,
  Tab
} from '@material-ui/core';

import AdvertiserContext from '../AdvertiserContext';
import AppHeader from '../AppHeader';
import { useAPI } from '../hooks/api';
import { formatToUSD } from '../util';
import UserContext from '../../providers/UserContext';

const useStyles = makeStyles(({ spacing, typography }) => ({
  link: {
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: '700',
  },
  active: {
    color: '#0fbf84',
    background: 'rgba(122, 220, 187, 0.55)',
    // fontSize: '0.625rem',
  },
  paused: {
    color: '#ff0000',
    background: 'rgba(255, 0, 0, 0.31)',
    // fontSize: '0.625rem',
  },
  scheduled: {
    color: '#1dafff',
    background: 'rgba(29, 175, 255, 0.24)',
  },
  menuIcon: {
    marginRight: 12,
  },
  spinner: {
    height: 100,
    width: 100,
    margin: '0 auto',
    textAlign: 'center',
  },
  spinnerWrap: {
    marginTop: spacing(12),
    width: '100%',
    textAlign: 'center',
  },
  stats: {
    fontWeight: '700',
    // fontSize: '0.75rem',
    // minHeight: 81,
    // height: 81,
  },
  table: {
    // maxHeight: 600,
  },
  height: {
    // fontSize: '0.75rem',
    // minHeight: 81,
    // height: 81,
  },
  tabs: {
    borderBottom: `1px solid #e0e0e0`,
    width: `100%`,
    position: `relative`,
  },
  tableCell: {
    // fontSize: '0.75rem',
    minWidth: 120,
    // minHeight: 81,
    // height: 81,
  },
  tableHeader: {
    ...typography.h5,
    color: 'rgba(114, 115, 131, 0.8)',
    // fontSize: '0.75rem',
    minWidth: 120,
    // minHeight: 81,
    // height: 81,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    color: 'rgba(114, 115, 131, 0.8)',
    fontSize: 12,
    // lineHeight: 'normal',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

const headCells = [
  { id: 'id', label: 'ID', disablePadding: false },
  { id: 'creativeName', label: 'Creative', disablePadding: false },
  { id: 'adGroupName', label: 'Ad Group', disablePadding: false },
  { id: 'startDate', label: 'Media Upload Date', disablePadding: false },
  { id: 'spend', label: 'Spend', disablePadding: false },
  // { id: 'budgetRemaining', label: 'Budget remaining', disablePadding: false },
  { id: 'visits', label: 'Visits', disablePadding: false },
  { id: 'costPerVisit', label: 'Cost per visit', disablePadding: false },
  { id: 'status', label: 'Status', disablePadding: false },
];

function CreativeTableHead({ onSelectAllClick }) {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all creatives' }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CreativeTableHead.propTypes = {
  onSelectAllClick: PropTypes.func.isRequired,
};

const CreativeIndexPage = ({
  location,
  history,
}) => {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);
  const user = useContext(UserContext);
  const { useGetAll } = useAPI();

  const [isLoading, setIsLoading] = useState(false);
  const [creatives, setCreatives] = useState([]);
  const [displays, setDisplays] = useState([]);
  const [selected, setSelected] = useState([]);
  const [tab, setTab] = useState(0);

  const { adGroupId, campaignId } = useParams();

  useEffect(() => {
    if (!user.is_tvsci_employee && adContext.cost_model === 'CPA') {
      history.push("/reports");
    }
  }, [user, adContext]);

  useEffect(() => {
    const nextTab = location.pathname === '/displays' ? 1 : 0;

    setTab(nextTab);
  }, [location]);

  useEffect(() => {
    if (adContext.id !== null) {
      getData();
    }
  }, [adContext]);

  async function getData() {
    setIsLoading(true);

    try {
      await useGetAll('/creatives/', [], total => {
        setCreatives(total);
      });

      await useGetAll('/static_display_creatives/', [], total => {
        setDisplays(total);
      });

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSetTab = (event, newTab) => {
    setTab(newTab);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const entity = tab === 0 ? creatives : displays;
      const newSelecteds = entity.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const creativeStatusIconRender = (status) => {
    if (status === true) {
      return (
        <Chip
          label="Active"
          className={classes.active}
        />
      );
    }

    return <Chip label="Paused" className={classes.paused} />;
  };

  const formatEndDate = (endDate) => {
    if (Date.parse(endDate)) {
      return moment(endDate).format('l')
    }
    return 'No End Date'
  }

  return (
    <AppHeader history={history}>
      <Box m={4} mb={2}>
        <Typography>
          <strong><Link className={classes.link} to={`/campaigns/`}>Campaigns</Link></strong> &gt; <strong><Link className={classes.link} to={`/campaigns/${campaignId}/adgroups/${adGroupId}`}>Ad Groups</Link></strong> &gt; Creative
        </Typography>
      </Box>
      <Box border={1} borderColor="grey.300" p={6} pt={4} m={4} borderRadius={20}>
        <Toolbar disableGutters>
          <Typography variant="h6" component="div">Creative</Typography>
        </Toolbar>

        <Box mb={4} width="100%">
          <Tabs
            className={classes.tabs}
            onChange={handleSetTab}
            value={tab}
          >
            <Tab label="Creatives" />
            <Tab label="Displays" />
          </Tabs>
        </Box>

        {tab === 0 && (
          <TableContainer className={classes.table}>
            <Table stickyHeader>
              <CreativeTableHead onSelectAllClick={handleSelectAllClick} />

              {!isLoading && (
                <TableBody>
                  {creatives.map((creative, index) => {
                    const isItemSelected = isSelected(creative.id);
                    const labelId = `creative-table-checkbox-${index}`;
                    const { stats_caches_by_lineitem_id: stats_caches } = creative;
                    const stats = stats_caches[adGroupId];

                    // Get the campaign Id of the creative to be used in the link below
                    let campaignId = 'na'

                    if (
                      creative.lineitem_set[0] &&
                      creative.lineitem_set[0].campaign
                    ) {
                      campaignId = creative.lineitem_set[0].campaign.split('/campaigns/')[1].slice(0, -1)
                    }

                    // Get the lineitme Id of the creative to be used in the link below
                    let adGroupId = 'na'
                    if (creative.lineitem_set[0] && creative.lineitem_set[0].url) {
                      adGroupId = creative.lineitem_set[0].url.split('/lineitems/')[1].slice(0, -1)
                    }
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, creative.id)}
                        role="checkbox"
                        tabIndex={-1}
                        key={`${creative.id}-${index}`}
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                      >
                        <TableCell className={classes.height} padding="checkbox">
                          <Checkbox
                            size="small"
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>

                        <TableCell>{creative.id}</TableCell>

                        <TableCell
                          className={classes.tableCell}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <Link className={classes.link} to={`/campaigns/${campaignId}/adgroups/${adGroupId}/creative/${creative.id}`}>{creative.name}</Link>
                        </TableCell>

                        <TableCell className={classes.tableCell} align="left">{creative.lineitem_set[0] && creative.lineitem_set[0].name ? <Link className={classes.link} to={`/campaigns/${campaignId}/adgroups`}>{creative.lineitem_set[0].name}</Link> : "-"}</TableCell>
                        <TableCell className={classes.tableCell} align="left">{moment(creative.media_upload_date).format('l')}</TableCell>
                        <TableCell className={classes.stat} align="left">{stats != null ? formatToUSD(stats.total_spend) : '-'}</TableCell>
                        <TableCell className={classes.stat} align="left">{stats != null ? stats.visit_count : '-'}</TableCell>
                        <TableCell className={classes.tableCell} align="left">{stats != null ? formatToUSD(stats.total_spend / stats.visit_count) : '-'}</TableCell>
                        <TableCell className={classes.stat} align="left">{creativeStatusIconRender(creative.active)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}

        {tab === 1 && (
          <TableContainer className={classes.table}>
            <Table stickyHeader>
              <CreativeTableHead onSelectAllClick={handleSelectAllClick} />

              {!isLoading && (
                <TableBody>
                  {displays.map((display, index) => {
                    const isItemSelected = isSelected(display.id);
                    const labelId = `creative-table-checkbox-${index}`;
                    const {
                      stats_caches_by_lineitem_id: stats_caches = {},
                      staticdisplaylineitem_set = [],
                    } = display;
                    const stats = stats_caches[adGroupId];
                    const staticDisplay = staticdisplaylineitem_set[0] || {};

                    // Get the campaign Id of the creative to be used in the link below
                    let campaignId = 'na'

                    if (staticDisplay.campaign) {
                      campaignId = staticDisplay.campaign.split('/campaigns/')[1].slice(0, -1)
                    }

                    // Get the lineitme Id of the creative to be used in the link below
                    let adGroupId = 'na'
                    if (staticDisplay.url) {
                      adGroupId = staticDisplay.url.split('/static_display_lineitems/')[1].slice(0, -1)
                    }
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, display.id)}
                        role="checkbox"
                        tabIndex={-1}
                        key={`${display.id}-${index}`}
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                      >
                        <TableCell className={classes.height} padding="checkbox">
                          <Checkbox
                            size="small"
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>

                        <TableCell>{display.id}</TableCell>

                        <TableCell
                          className={classes.tableCell}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <Link className={classes.link} to={`/campaigns/${campaignId}/adgroups/${adGroupId}/displays/${display.id}`}>{display.name}</Link>
                        </TableCell>

                        <TableCell className={classes.tableCell} align="left">{staticDisplay.name ? <Link className={classes.link} to={`/campaigns/${campaignId}/adgroups`}>{staticDisplay.name}</Link> : "-"}</TableCell>
                        <TableCell className={classes.tableCell} align="left">{moment(staticDisplay.start_date).format('l')}</TableCell>
                        <TableCell className={classes.tableCell} align="left">{formatEndDate(staticDisplay.end_date)}</TableCell>
                        <TableCell className={classes.stat} align="left">{stats != null ? formatToUSD(stats.total_spend) : '-'}</TableCell>
                        <TableCell className={classes.stat} align="left">{stats != null ? stats.visit_count : '-'}</TableCell>
                        <TableCell className={classes.tableCell} align="left">{stats != null ? formatToUSD(stats.total_spend / stats.visit_count) : '-'}</TableCell>
                        <TableCell className={classes.stat} align="left">{creativeStatusIconRender(display.active)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}

        {isLoading &&
          <div className={classes.spinnerWrap}>
            <div className={classes.spinner}>
              <CircularProgress />
            </div>
          </div>}
      </Box>
    </AppHeader>
  );
}

CreativeIndexPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default CreativeIndexPage;
