import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import debounce from 'lodash/debounce';
import deburr from 'lodash/deburr';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useSnackbar } from 'notistack';
import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  makeStyles,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

import { useAPI } from './hooks/api';
import { useLoader } from './hooks/loader';
import { dmaData } from './util';

const styles = makeStyles(theme => ({
  container: {
    position: "relative",
    marginTop: "16px"
  },
  progress: {
    maxWidth: 45,
    maxHeight: 45,
    display: 'flex',
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  matchQuery: {
    fontWeight: 300,
    color: `#9aa0a6`,
    letterSpacing: 0.15,
  },
  matchRemaining: {
    fontWeight: 700,
    letterSpacing: 0.15,
  },
  divider: {
    height: theme.spacing(2)
  },
  menuItem: {
    whiteSpace: 'normal',
  }
}));

export const getSuggestions = (suggestions, value) => {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
      const keep =
        count < 5 &&
        suggestion.text.slice(0, inputLength).toLowerCase() === inputValue;

      if (keep) {
        count += 1;
      }

      return keep;
    });
};

const getSuggestionValue = ({ place_name, text }) => place_name || text;

/////////////////////////////
// GEOAUTOSUGGEST COMPONENT
/////////////////////////////
const GeoAutoSuggest = ({
  inputValue,
  placeholder,
  label,
  margin,
  handler,
  handleSubmitClick,
  onSearch,
  setSearchResults
}) => {
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();

  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState(inputValue);

  const { useGet } = useAPI();
  const { isLoading, setIsLoading } = useLoader();

  const handleSearch = (value) => {
    return onSearch(value)
      .then((response) => {
        let results = [];
        let dmaResults = getSuggestions([...dmaData], value);

        // Only grab the first 2 suggestions from DMA
        if (dmaResults && dmaResults.length > 1) {
          if (dmaResults[1]) {
            dmaResults = [dmaResults[0], dmaResults[1]];
          }
        }

        if (response) {
          results = [...dmaResults, ...response];
        }

        setSuggestions(getSuggestions([...results], value));
        setSearchResults(getSuggestions([...results], value));
      });
  };

  const handleDebouncedSearch = useCallback(debounce(handleSearch, 400), []);

  // Event handlers
  const handleSuggestionsFetchRequested = ({ value }) => {
    handleDebouncedSearch(value);
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = () => (event, { newValue }) => {
    setValue(newValue);
  };

  const handleValidSuggestion = async suggestion => {
    setIsLoading(true);

    if (suggestion) {
      if (
        suggestion.code ||
        suggestion.place_type &&
        suggestion.place_type.length > 0 &&
        !suggestion.place_type.includes('place')
      ) {
        setIsLoading(false);
        handler(suggestion);
        setValue('');
        return;
      }
    }

    const isValid = await useGet(`/check-city/${suggestion.place_name}`);

    if (isValid === true) {
      handler(suggestion);
      setValue('');
    } else {
      enqueueSnackbar('Selected location not supported', {
        autoHideDuration: 3000,
        preventDuplicate: true,
        variant: 'warning',
      });
    }

    setIsLoading(false);
  };

  const onSuggestionSelected = (event, { suggestion, method }) => {
    if (method === 'enter' || method === 'click') {
      handleValidSuggestion(suggestion);
    }
  };

  // Render functions
  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const name = suggestion.place_name
      ? suggestion.place_name
      : suggestion.text;

    const matches = match(name, query);
    const parts = parse(name, matches);

    return (
      <MenuItem selected={isHighlighted} component="div" className={classes.menuItem}>
        <div>
          {parts.map((part, index) =>
            part.highlight ? (
              <span className={classes.matchQuery} key={String(index)}>
                {part.place_name ? part.place_name : part.text}
              </span>
            ) : (
              <strong id={`geo-suggestion-match-${index}`} className={classes.matchRemaining} key={String(index)}>
                {part.place_name ? part.place_name : part.text}
              </strong>
            )
          )}
        </div>
      </MenuItem>
    );
  };

  const renderInputComponent = inputProps => {
    const { classes, inputRef = () => { }, ref, ...other } = inputProps;

    return (
      <TextField
        id="geo-text-input"
        fullWidth
        color="secondary"
        aria-describedby="standard-weight-helper-text"
        inputProps={{
          'aria-label': 'weight',
        }}
        variant="outlined"
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input
          },
          endAdornment: (
            <IconButton
              type="submit"
              aria-label="search"
              onClick={(e) => { handleSubmitClick(e, value), setValue(''); }}
            >
              {isLoading ? (
                <Box className={classes.progress}>
                  <CircularProgress color="secondary" size={30} />
                </Box>
              ) : (
                <SendIcon />
              )}
            </IconButton>
          )
        }}
        InputLabelProps={{
          shrink: true,
        }}
        {...other}
      />
    );
  };

  const autosuggestProps = {
    suggestions,
    renderInputComponent,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
    onSuggestionSelected,
  };

  return (
    <div className={classes.root}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          autoComplete: 'true',
          classes,
          margin,
          onChange: handleChange(),
          placeholder,
          label,
          value,
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    </div>
  );
};

GeoAutoSuggest.propTypes = {
  inputValue: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  handler: PropTypes.func.isRequired,
  handleSubmitClick: PropTypes.func,
  onSearch: PropTypes.func,
  setSearchResults: PropTypes.func
};

GeoAutoSuggest.defaultProps = {
  inputValue: "",
  label: "",
  margin: "normal",
};

export default GeoAutoSuggest;
